import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";

import MediaSection from "./MediaSection";
import useMediaQuery from "@mui/material/useMediaQuery";
import { MEDIA_LIST } from "src/apiUrl";
import axios from "axios";
import Iconify from "src/components/Iconify";
import Image from "src/components/Image";
import { capitalCase } from "change-case";
import MediaViewer from "./mediaViewer/MediaViewer";


export default function MediaModal({ addingMedia, media = "", viewOnly = false }) {
  const [openModal, setOpenModal] = useState(false);
  const [mediaData, setMediaData] = useState({});

  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const getMedia = async () => {
    try {
      const response = await axios.get(`${MEDIA_LIST}${media}/`, config);
      console.log("response", response);
      setMediaData(response.data);
    } catch (error) { }
  };
  useEffect(() => {
    console.log("mediaData", mediaData, media);
    if (media) {
      getMedia();
    }
  }, [media]);
  const handleClose = () => {
    setOpenModal(false);
  };
  const addingMedia2 = (p) => {
    setOpenModal(false);
    addingMedia(p);
    setMediaData(p);
  };
  // console.log("mediaData", mediaData, media);
  const MediaView = () => <><Stack direction={'column'}>
    <MediaViewer
      file={mediaData}
      action={<>
        {mediaData.file_type == "image" && (
          <Image
            key={mediaData.id}
            src={mediaData.media_file || mediaData.url}
            // onClick={() => setOpenModal(true)}
            sx={{
              width: 104,
              height: 104,
              borderRadius: "8px",
              cursor: "pointer",
            }}
          />)}
        <Typography
          variant="subtitle2"
          sx={{
            // color: "info.main",

            "&:hover": {
              cursor: "pointer",
              color: "primary.main",
            },
          }}
        >
          {mediaData.name} {'   '}
        </Typography>
      </>
      } />
    <Typography sx={{ color: 'text.secondary', fontSize: 10 }}>
      Media Type- {` ${capitalCase(mediaData?.file_type || '')} `}
    </Typography>

  </Stack>

    {/* {mediaData.file_type == "video" && (<Iconify icon='lets-icons:video' />)}
    {mediaData.file_type == "document" && (<Iconify icon='ic:twotone-perm-media' />)} */}
  </>


  if (viewOnly) {
    return (
      <>
        <MediaView />
      </>
    )
  }


  return (
    <>
      {
        media ? (
          <>
            <MediaView />
            <Stack direction={'row'} sx={{ mt: 1 }} spacing={3}>
              <Button variant="contained" startIcon={<Iconify icon='ic:twotone-perm-media' />} onClick={() => setOpenModal(true)}>Change Media</Button>
              <Button variant="outlined" color="error" onClick={() => addingMedia2({})} >Remove</Button>
            </Stack>
          </>
        ) : (
          <Button sx={{ mt: 1 }} variant="outlined" startIcon={<Iconify icon='ic:twotone-perm-media' />} onClick={() => setOpenModal(true)}>Choose Media</Button>
        )
      }

      <MediaModallist
        openModal={openModal}
        handleClose={handleClose}
        addingMedia={addingMedia2}
      />
    </>
  );
}

const MediaModallist = ({ openModal, handleClose, addingMedia }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const selectedMedia = (p) => {
    console.log("media", p);
    addingMedia(p);
  };

  return (
    <>
      <Dialog open={openModal} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogContent>
          <MediaSection selectedMedia={(p) => selectedMedia(p)} />
        </DialogContent>
      </Dialog>
    </>
  );
};
