import { createSlice } from '@reduxjs/toolkit';
import { eduleyApi } from 'src/api/apiConfig';
// import omit from "lodash/omit";
import { ASSIGNMENT_TRACK, QUIZ_TRACK, QUIZ_TRACK_MARK_SET, STUDENT_TRACK } from 'src/apiUrl';
// utils
import axios from '../../utils/axios';
// import axios from "axios";
//
// import { dispatch } from "../store";

// ----------------------------------------------------------------------
const initialState = {
  isLoading: false,
  error: null,
  quiz: {
    data: {},
    quizbyid: {},
    contentdetails: {},
    isLoading: false,
    error: null
  },

  assignment: {
    data: {},
    isLoading: false,
    error: null
  },
  student: {
    data: {},
    isLoading: false,
    error: null
  }
};

const slice = createSlice({
  name: 'track',
  initialState,
  reducers: {
    // START LOADING
    startLoadingQuiz(state) {
      state.quiz.isLoading = true;
    },
    startLoadingAssignment(state) {
      state.assignment.isLoading = true;
    },
    startLoadingStudent(state) {
      state.student.isLoading = true;
    },
    stopLoadingAssignment(state) {
      state.assignment.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setQuiz(state, action) {
      state.quiz.data = action.payload;
      state.quiz.isLoading = false;
      state.quiz.error = null;
    },
    setQuizbyId(state, action) {
      state.quiz.quizbyid = { ...action.payload.data };
      state.quiz.isLoading = false;
    },
    setAssignment(state, action) {
      state.assignment.data = action.payload;
      state.assignment.isLoading = false;
      state.assignment.error = null;
    },
    setStudent(state, action) {
      state.student.data = action.payload;
      state.student.isLoading = false;
      state.student.error = null;
    }
  }
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------
// export const c_update = { changeset };
const accessToken = localStorage.getItem('accessToken');

const config = {
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`
  }
};

const getStatus = (status) => {
  console.log('status', status);
  switch (status?.status) {
    case 401:
      window.location.href = '/auth/login';
      return 'Unauthorized';
    default:
      return '';
  }
};
export const TrackQuizList = (data) => async (dispatch) => {
  dispatch(actions.startLoadingQuiz());
  try {
    const res = await eduleyApi.get(`${QUIZ_TRACK}${data}`);
    console.log('res', res);
    dispatch(actions.setQuiz(res?.data));
  } catch (error) {
    getStatus(error?.response);
    dispatch(actions.hasError(error?.response?.data));
  }
};
export const updateQuiz = (data) => async (dispatch) => {
  try {
    const res = await axios.patch(`${QUIZ_TRACK}${data.id}/`, data, config);
    return res;
  } catch (error) {
    getStatus(error.response);
    return error.response;
  }
};
export const TrackQuizbyId = (id) => async (dispatch) => {
  dispatch(actions.startLoadingQuiz());
  try {
    const res = await eduleyApi.get(`${QUIZ_TRACK}${id}/`);
    // const res2 = await axios.get(`${COURSE_QUIZ}${content}/`, config);
    dispatch(actions.setQuizbyId({ data: res.data }));
  } catch (error) {
    getStatus(error.response);

    dispatch(actions.hasError(error.response));
  }
};
export const TrackQuizbyIdfornull = (id) => async (dispatch) => {
  dispatch(actions.setQuizbyId({ data: null }));
};

export const assignmentTrackingList = (data) => async (dispatch) => {
  dispatch(actions.startLoadingAssignment());
  try {
    const res = await eduleyApi.get(`${ASSIGNMENT_TRACK}${data}`);
    dispatch(actions.setAssignment(res.data));
  } catch (error) {
    getStatus(error.response);

    dispatch(actions.hasError(error?.response?.data));
  }
};

export const assignmentTrackingListPost = (formData, successSubmition, failureSubmission) => async (dispatch) => {
  dispatch(actions.startLoadingAssignment());
  try {
    const res = await eduleyApi.post(`${ASSIGNMENT_TRACK}`, formData);
    // dispatch(actions.setAssignment(res.data));
    if (res.data) {
      successSubmition();
      dispatch(actions.stopLoadingAssignment());
    }
  } catch (error) {
    getStatus(error?.response);
    failureSubmission(error?.data?.data?.non_field_errors[0]);
    dispatch(actions.stopLoadingAssignment());
  }
};

export const updateAssignment = (data) => async (dispatch) => {
  try {
    const res = await axios.patch(`${ASSIGNMENT_TRACK}${data.id}/`, data, config);
    return res;
  } catch (error) {
    getStatus(error.response);
    return error.response;
  }
};
export const TrackStudentList = (data) => async (dispatch) => {
  dispatch(actions.startLoadingStudent());
  try {
    const res = await eduleyApi.get(`${STUDENT_TRACK}${data}`);
    dispatch(actions.setStudent(res.data));
  } catch (error) {
    getStatus(error.response);
    dispatch(actions.hasError(error.response.data));
  }
};

export const QuizmarkObtain = (id, data) => async (dispatch) => {
  try {
    const res = await eduleyApi.patch(`${QUIZ_TRACK_MARK_SET}${id}/`, data);

    return 'success';
  } catch (error) {
    // getStatus(error.response);
    return error.response;
    // dispatch(actions.hasError(error.response.data));
  }
};

export const updateAssignmentbyId = (data) => async (dispatch) => {
  try {
    const res = await eduleyApi.patch(`${ASSIGNMENT_TRACK}${data.id ? data.id : data.get('id')}/`, data);
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
};
