import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);
const DAYS_ORDER = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const formatRecurrenceDays = (days) => {
  if (!days || days.length === 0) return '';

  const sortedDays = [...days].sort((a, b) => DAYS_ORDER.indexOf(a) - DAYS_ORDER.indexOf(b));

  if (sortedDays.length === 1) return sortedDays[0];

  if (sortedDays.length === 2) return sortedDays.join(' and ');

  const allButLast = sortedDays.slice(0, -1).join(', ');
  const last = sortedDays[sortedDays.length - 1];

  return `${allButLast}, and ${last}`;
};

export const sortDays = (days) => {
  return days.sort((a, b) => DAYS_ORDER.indexOf(a.day) - DAYS_ORDER.indexOf(b.day));
};

export function calculateDuration(startTime, endTime) {
  const start = dayjs(startTime, 'HH:mm');
  const end = dayjs(endTime, 'HH:mm');
  const diff = end.diff(start);
  if (diff < 0) {
    return null;
  }

  const duration = dayjs.duration(diff);
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();

  // Handle NaN values
  if (isNaN(hours) || isNaN(minutes)) {
    return null;
  }

  // Build the duration string
  const hourText = hours > 0 ? `${hours} hour${hours !== 1 ? 's' : ''}` : '';
  const minuteText = minutes > 0 ? `${minutes} minute${minutes !== 1 ? 's' : ''}` : '';

  return [hourText, minuteText].filter(Boolean).join(' ');
}

export const formatAttendance = (attendanceRecurrenceDays) => {
  const result = attendanceRecurrenceDays.map((item) => {
    if (!item.start_time || !item.end_time) return '';

    return `${item.day}: ${formatTime(item.start_time)} to ${formatTime(item.end_time)} - ${calculateDuration(item.start_time, item.end_time)}`;
  });

  return result;
};

export const formatTime = (time) => {
  if (!time) return '';
  return dayjs(time, 'HH:mm').format('h:mm A');
};

export const isValidTime = (time) => dayjs(time, 'HH:mm', true).isValid();
