import {
  Box,
  Button,
  Divider,
  Drawer,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';
import { useDispatch } from 'react-redux';
import { createcourse, editcourse, updateStudentDocument } from 'src/redux/slices/courseupdate';
import ConfirmDialog from 'src/components/confirm-dialog/ConfirmDialog';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';
import useAuth from 'src/hooks/useAuth';
import FeeDiscount from './FeeDiscount';
import FeeBreakup from './FeeBreakup';
import useFeeStructure from './useFeeStructure';
import Label from 'src/components/Label';
import { fCurrency, fNumber } from 'src/utils/formatNumber';
import { formatNumber } from 'src/utils/common';
import DiscountPreview from './DiscountPreview';

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2
  }
}));

export default function FeeStructureDrawer({ open, onClose, onSubmit, accessibleRoles, ...other }) {
  const dispatch = useDispatch();
  const { instituteinfo } = useAuth();
  const theme = useTheme();
  const [openCaution, setOpenCaution] = useState(false);
  const { getCoursePriceBreakup, onUpdateData, feeData } = useFeeStructure();

  useEffect(() => {
    if (feeData?.has_price_breakup) {
      getCoursePriceBreakup(feeData?.id);
    }
  }, [feeData]);

  async function onTogglePriceBreakup(e) {
    try {
      setOpenCaution(false);

      await onUpdateData({ has_price_breakup: e });
      if (e) {
        await getCoursePriceBreakup();
      }
    } catch (e) {}
    if (feeData?.id) {
      await onUpdateData({
        ...feeData,
        has_price_breakup: e
      });
    }
  }

  function onOpenCaution(e) {
    if (!e.target.checked) {
      setOpenCaution(true);
    } else {
      onTogglePriceBreakup(true);
    }
  }

  function onCloseCaution() {
    setOpenCaution(false);
  }

  function onOkay() {
    onTogglePriceBreakup(false);
    onCloseCaution();
  }

  return (
    <>
      <Drawer
        open={open}
        onClose={onClose}
        anchor="right"
        BackdropProps={{
          invisible: true
        }}
        PaperProps={{
          sx: { width: 600 }
        }}
        {...other}
      >
        <Stack direction="row" alignItems="center" spacing={2} sx={{ p: 2.5 }}>
          <IconButton onClick={onClose}>
            <Iconify icon="solar:arrow-left-line-duotone" height={24} width={24} />
          </IconButton>

          <Typography variant="h6"> Configure Fee Structure </Typography>
        </Stack>
        <FeeBreakup price={feeData?.discounted_price || feeData?.price} enableFeeStructure={feeData?.has_price_breakup} onClose={onClose} />
        {/* Add Form here  */}
        <ConfirmDialog
          title="Are you sure you want to disable fee structure?"
          content="Disabling fee structure will reset and will remove any custom added fee component."
          action={
            <Button onClick={onOkay} color="error" variant="contained">
              Yes, Disable
            </Button>
          }
          onClose={onCloseCaution}
          open={openCaution}
        />
      </Drawer>
    </>
  );
}
