import isString from 'lodash/isString';
import PropTypes from 'prop-types';
// @mui
import { Box, Button, Link, Typography } from '@mui/material';
//
import { getClass } from 'src/sections/@dashboard/cohort/component/attendanceComponents/SpecialEvents';
import Breadcrumbs from './Breadcrumbs';
import Label from './Label';

// ----------------------------------------------------------------------

HeaderBreadcrumbs.propTypes = {
  links: PropTypes.array,
  action: PropTypes.node,
  heading: PropTypes.string.isRequired,
  moreLink: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sx: PropTypes.object
};

const getLabel = (eventType) => {
  switch (eventType) {
    case 'holiday':
      return <Label color="error">Holiday</Label>;
    case 'cancelled':
      return <Label color="error">Cancelled</Label>;
    default:
      return <Label color="warning">{getClass(eventType)}</Label>;
  }
};

export default function HeaderBreadcrumbs({
  links,
  action,
  data = {},
  heading,
  subHeading,
  description,
  moreLink = '' || [],
  sx,
  handleOpen,
  ...other
}) {
  const eventName = getClass(data?.event?.event);
  return (
    <Box sx={{ mb: 5, ...sx }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" gutterBottom color="grey.800">
            {heading}
          </Typography>
          {subHeading && (
            <Typography
              variant="subtitle1"
              color="text.secondary"
              gutterBottom
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1
              }}
            >
              {subHeading} {eventName ? getLabel(data?.event?.event) : ''}
            </Typography>
          )}
          {description && (
            <Typography variant="body1" color="text.secondary" gutterBottom>
              {description}
            </Typography>
          )}
          <Breadcrumbs links={links} {...other} />
        </Box>

        {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
        {heading === 'Quota Tracking' && (
          <Button variant="outlined" color="primary" onClick={() => handleOpen()}>
            Add Quota Submission
          </Button>
        )}
      </Box>

      <Box sx={{ mt: 2 }}>
        {isString(moreLink) ? (
          <Link href={moreLink} target="_blank" rel="noopener" variant="body2">
            {moreLink}
          </Link>
        ) : (
          moreLink.map((href) => (
            <Link noWrap key={href} href={href} variant="body2" target="_blank" rel="noopener" sx={{ display: 'table' }}>
              {href}
            </Link>
          ))
        )}
      </Box>
    </Box>
  );
}
