import { alpha, Divider, Stack, styled, Typography, useTheme } from '@mui/material'
import React from 'react'
import Markdown from 'src/components/Markdown';
import { fDate } from 'src/utils/formatTime';

const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.body2,
    width: 220,
    fontSize: 13,
    flexShrink: 0,
    color: theme.palette.text.secondary,
    mr: 2
}));
const QuizView = ({ data }) => {
    const theme = useTheme()

    return (
        <Stack direction="column" sx={{ width: '100%' }} spacing={2}>
            <Stack direction="row" sx={{ mt: 2 }} justifyContent={'center'}>
                <Typography variant="h5" textAlign={'center'} sx={{ width: '100%', color: alpha(theme.palette.primary.main, 0.6) }}>{data?.name}</Typography>
            </Stack>
            <Divider />
            <LabelStyle sx={{ mt: 2 }}>Description</LabelStyle>
            <div style={{ width: "100%" }}>
                <Markdown children={data?.description} />
            </div>
            <Divider />
            <Stack direction="row" alignItems={"center"}>
                <LabelStyle >Time Allotment </LabelStyle>
                <Typography variant="h5" sx={{ color: theme.palette.primary.main, fontWeight: 400 }}>
                    {data?.time_allotment}
                </Typography>

            </Stack>
            <Divider />
            <Stack direction="row" alignItems={"center"}>
                <LabelStyle >Limit no. of attempts ?</LabelStyle>
                <Typography variant="h5" sx={{ color: theme.palette.primary.main, fontWeight: 400 }}>
                    {data?.limited_attempt ? `Yes (${data?.attempts_allowed || 0} times)` : `No`}
                </Typography>
            </Stack>
            <Divider />
            <Stack direction="row" alignItems={"center"}>
                <LabelStyle > Quiz has Multiple Choice Questions ?</LabelStyle>
                <Stack>

                    <Typography variant="h5" sx={{ color: theme.palette.primary.main, fontWeight: 400 }}>
                        {data?.quizHasMCQ ? `Yes ` : `No`}
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{ mt: 2, fontSize: 12 }}
                        color="text.secondary"
                    >
                        {data?.quizHasMCQ
                            ? "Quiz has MCQ and subjective both."
                            : "Quiz has only subjective."}
                    </Typography>
                </Stack>
            </Stack>
            {data?.quizHasMCQ && <>
                <Divider />
                <Stack direction="row" alignItems={"center"}>
                    <LabelStyle >Display Questions</LabelStyle>
                    <Typography variant="h5" sx={{ color: theme.palette.primary.main, fontWeight: 400 }}>
                        {`${data?.display_questions || 0} Questions`}
                    </Typography>
                </Stack>
                <Divider />
                <Stack direction="row" alignItems={"center"}>
                    <LabelStyle >Mark per MCQ</LabelStyle>
                    <Typography variant="h5" sx={{ color: theme.palette.primary.main, fontWeight: 400 }}>
                        {`${data?.marks_per_question || 0} Marks`}
                    </Typography>
                </Stack>
            </>
            }
            <Divider />
            <Stack direction="row" alignItems={"center"}>
                <LabelStyle >Total Marks</LabelStyle>
                <Typography variant="h5" sx={{ color: theme.palette.primary.main, fontWeight: 400 }}>
                    {data?.total_marks} Marks
                </Typography>
            </Stack>
            <Divider />
            <Stack direction="row" alignItems={"center"}>
                <LabelStyle >Passing Marks</LabelStyle>
                <Typography variant="h5" sx={{ color: theme.palette.primary.main, fontWeight: 400 }}>
                    {data?.passing_marks} Marks
                </Typography>
            </Stack>
            {data?.limited_attempt && <>
                <Divider />
                <Stack direction="row" alignItems={"center"}>
                    <LabelStyle >Max score % after attempts are
                        exhausted</LabelStyle>
                    <Typography variant="h5" sx={{ color: theme.palette.primary.main, fontWeight: 400 }}>
                        {data?.max_score_after_attempts_exhausted}
                    </Typography>
                </Stack></>}
            <Divider />
            <Stack direction="row" alignItems={"center"}>
                <LabelStyle >Weightage Group in Grade</LabelStyle>
                <Typography variant="h5" sx={{ color: theme.palette.primary.main, fontWeight: 400 }}>
                    {data?.transcript_weightage?.name}
                </Typography>
            </Stack>
            <Divider />
            {/* Weightage Group in Grade
             */}
            <Stack direction="row" alignItems={"center"}>
                <LabelStyle >Is Gated</LabelStyle>
                <Stack direction={'column'}>
                    <Typography variant="h5" sx={{ color: theme.palette.primary.main, fontWeight: 400 }}>
                        {!!data?.can_proceed_without_marking ? "Yes" : "No"}
                    </Typography>
                    <Typography
                        variant='body2'
                        sx={{
                            mt: 2,
                            fontSize: 12,
                            textDecoration: data?.can_skip ? "line-through" : "",
                        }}
                        color='text.secondary'
                    >
                        Can student proceed to next content without instructor marking the assignment .
                    </Typography>
                </Stack>
            </Stack>
            <Divider />
            <Stack direction="row" alignItems={"center"}>
                <LabelStyle >Start Date</LabelStyle>
                <Typography variant="h5" sx={{ color: theme.palette.primary.main, fontWeight: 400 }}>
                    {fDate(data?.start_datetime)}
                </Typography>
            </Stack>
            <Divider />

            <Stack direction="row" alignItems={"center"}>
                <LabelStyle >Can Skip</LabelStyle>
                <Typography variant="h5" sx={{ color: theme.palette.primary.main, fontWeight: 400 }}>
                    {data?.can_skip ? "Yes" : "No"}
                </Typography>
            </Stack>
            <Divider />
            <Typography variant="h5" textAlign={'center'} sx={{ width: '100%', color: alpha(theme.palette.primary.main, 0.6) }}>Question List</Typography>

            {
                data?.questions?.map((a, index) => <Stack direction="row" sx={{ width: '100%' }} spacing={2}>
                    <Typography variant="h7" sx={{ color: theme.palette.primary.main, fontWeight: 400 }}>{index + 1}.</Typography>
                    <Typography variant="h7" sx={{ color: theme.palette.primary.main, fontWeight: 400 }}>{a.name}</Typography>

                </Stack>)
            }
        </Stack>
    )
}

export default QuizView