import { Paper, Popover, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import Iconify from 'src/components/Iconify';
import useAuth from 'src/hooks/useAuth';
import { fCurrency } from 'src/utils/formatNumber';
import useFeeStructure from './useFeeStructure';

function calculateTaxPrice(data) {
  if (!data.amount || !data.tax_percentage) return '--';

  const amount = parseFloat(data.amount);
  const taxPercentage = parseFloat(data.tax_percentage);

  const taxPrice = (taxPercentage / 100) * amount;

  return taxPrice?.toFixed(2);
}

export default function FeePopover({ data, totalAmount, children }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { instituteinfo } = useAuth();
  const { hasDiscount, discountValue, feeData } = useFeeStructure();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const sum = data?.reduce((accumulator, currentValue) => {
    return accumulator + +currentValue.calculated_amount;
  }, 0);

  return (
    <Stack spacing={2} direction="row" alignItems="center">
      {children}
      {data && (
        <Iconify
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          icon="solar:info-square-bold-duotone"
          height={24}
          width={24}
          color="primary.main"
        />
      )}

      {data && (
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none'
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Fee Component</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Tax Description</TableCell>
                  <TableCell>Tax Percentage</TableCell>
                  <TableCell>Tax Amount</TableCell>
                  <TableCell>Discount</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>
                      {`${instituteinfo?.default_currency?.prefix || ''}${` ${fCurrency(row.amount)} `}${
                        instituteinfo?.default_currency?.suffix || ''
                      }`}
                    </TableCell>

                    <TableCell>{row.tax_description ? row.tax_description : '--'}</TableCell>
                    <TableCell>{row.tax_percentage ? `${row.tax_percentage}%` : '--'}</TableCell>
                    <TableCell>{calculateTaxPrice(row) || '--'}</TableCell>

                    <TableCell>
                      {hasDiscount && row.description == 'Tuition Fee'
                        ? `${`${instituteinfo?.default_currency?.prefix || ''}${` ${feeData?.discounted_price} `}${
                            instituteinfo?.default_currency?.suffix || ''
                          }`}`
                        : '--'}
                    </TableCell>
                    <TableCell>{`${instituteinfo?.default_currency?.prefix || ''}${` ${row.calculated_amount} `}${
                      instituteinfo?.default_currency?.suffix || ''
                    }`}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Stack sx={{ p: 2 }} alignItems="flex-end">
            <Typography variant="h6" color="text.secondary">
              {`${instituteinfo?.default_currency?.prefix || ''}${sum || totalAmount}${instituteinfo?.default_currency?.suffix || ''}`}
            </Typography>
          </Stack>
        </Popover>
      )}
    </Stack>
  );
}
