import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Iconify from 'src/components/Iconify';
import useFeeStructure from './useFeeStructure';
import { LoadingButton } from '@mui/lab';
import { Loading } from './FeeContext';
import useAuth from 'src/hooks/useAuth';
import DiscountForm from './DiscountForm';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { fCurrency } from 'src/utils/formatNumber';

export default function FeeDiscount() {
  const [showDiscount, setShowDiscount] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    discountValue,
    onChangeDiscountValue,
    discountUnit,
    onChangeDiscountUnit,
    onSubmitDiscount,
    discountLoading,
    hasDiscount,
    setHasDiscount,
    discountData,
    price,
    feeData
  } = useFeeStructure();
  const { enqueueSnackbar } = useSnackbar();
  const { instituteinfo } = useAuth();

  useEffect(() => {
    if (hasDiscount) {
      setShowDiscount(true);
    } else {
      setShowDiscount(false);
    }
  }, [hasDiscount]);

  const enableDiscount = () => {
    setShowDiscount(true);
  };
  const disableDiscount = async () => {
    if (hasDiscount) {
      try {
        setLoading('remove');
        await onSubmitDiscount(true, 'Discount removed successfully!');
        onChangeDiscountValue('');
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }
    setShowDiscount(false);
    onChangeDiscountValue('');
  };

  const applyDiscount = async () => {
    console.log('Discoutn Value:', discountValue);
    if (Number(discountValue) >= feeData?.tuition_fee && discountUnit == 'amount') {
      return enqueueSnackbar(
        `Discount cannot be greater than or equal to tuition fee: ${`${instituteinfo?.default_currency?.prefix || ''}${` ${fCurrency(
          feeData?.tuition_fee
        )} `}${instituteinfo?.default_currency?.suffix || ''}`}`,
        { variant: 'error' }
      );
    }
    setLoading('apply');
    try {
      await onSubmitDiscount();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <Stack
      sx={{
        // minHeight: 150,
        border: (theme) => `2px dashed ${theme.palette.grey[300]}`,
        borderRadius: 1,
        position: 'relative',
        py: 2
      }}
    >
      {!showDiscount && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1
          }}
        >
          <Button startIcon={<Iconify icon="hugeicons:discount" color="success.main" />} onClick={enableDiscount}>
            Add Discount
          </Button>
        </Box>
      )}

      {showDiscount && (
        <Stack sx={{ p: 2, flex: 1 }} justifyContent="space-between" flexDirection="column">
          <Stack direction="row" sx={{ pb: 2 }}>
            <DiscountForm />
          </Stack>

          <Stack direction="row" justifyContent="space-between" alignItems="flex-end" spacing={2}>
            <LoadingButton size="small" variant="soft" loading={loading == 'remove'} color="error" fullWidth onClick={disableDiscount}>
              Remove Discount
            </LoadingButton>
            {(Number(discountData?.value) == 0 || isEmpty(discountData)) && (
              <LoadingButton
                size="small"
                variant="soft"
                color="info"
                disabled={
                  (discountUnit == 'percentage' && discountValue > 100) || (discountUnit == 'percentage' && discountValue == 0) || !discountValue
                }
                fullWidth
                loading={loading == 'apply'}
                onClick={applyDiscount}
              >
                Apply Discount
              </LoadingButton>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
