import { Stack, Typography, useTheme, alpha } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { eduleyApi } from 'src/api/apiConfig';
import { COURSE_CUSTOM_TRANSCRIPT, SCOREWEIGHTAGE, STUDENT_DOCUMENT } from 'src/apiUrl';
import Avatar from 'src/components/Avatar';

import Iconify from 'src/components/Iconify';
import Label from 'src/components/Label';

import { useDispatch, useSelector } from 'src/redux/store';
import StudentDocumentDrawer from './StudentDocumentDrawer';
import { getEnrollmentDocuments } from 'src/redux/slices/configuration';

const StudentDocument = ({ accessibleRoles }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [openDetails, setOpenDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);

  const { addcourse } = useSelector((state) => state.courseupdate);
  const { enrollmentDocument } = useSelector((state) => state.configuration);
  const courseInfo = addcourse?.firstform;

  useEffect(() => {
    getEnrollmentDocumentList();
    getStudentDocumentList();
  }, []);

  function getEnrollmentDocumentList() {
    dispatch(getEnrollmentDocuments());
  }

  async function getStudentDocumentList() {
    try {
      setLoading(true);
      setError(false);
      const response = await eduleyApi.get(`${STUDENT_DOCUMENT}${courseInfo?.id}/`);
      setLoading(false);
      setData(response.data);
    } catch (error) {
      setLoading(false);
      setError(error.data.data);
    }
  }

  const handleChange = () => {
    setOpenDetails(!openDetails);
  };

  return (
    <>
      <Stack
        spacing={2}
        direction="row"
        sx={{
          width: 1,
          position: 'relative',
          border: (theme) => `1px solid ${theme.palette.divider}`,
          my: 2,
          borderRadius: 1,
          backgroundColor: '#fff',
          cursor: 'pointer'
        }}
        onClick={handleChange}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 2, flex: 1 }}>
          <Avatar
            sx={{
              backgroundColor: alpha(theme.palette.primary.light, 0.1),
              color: 'primary.main'
            }}
          >
            <Iconify icon={'ph:file-doc-light'} />
          </Avatar>

          <Stack sx={{ flex: 1 }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} sx={{ pb: 1, flexGrow: 1 }}>
              <Typography variant="h6">Student Documents</Typography>

              <Label
                variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                color={courseInfo?.enable_student_document ? 'success' : 'default'}
                sx={{
                  letterSpacing: 1,
                  fontSize: 12
                }}
              >
                {courseInfo?.enable_student_document ? 'Enabled' : 'Disabled'}
              </Label>
            </Stack>
            <Typography variant="caption" color="text.secondary">
              Mandatory student documents to be collected for the program enrollment
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <StudentDocumentDrawer
        open={openDetails}
        list={enrollmentDocument?.data}
        getList={getEnrollmentDocumentList}
        getDocumentList={getStudentDocumentList}
        studentDocuments={data}
        onClose={handleChange}
        courseInfo={courseInfo}
        accessibleRoles={accessibleRoles}
      />
    </>
  );
};

export default StudentDocument;
