import { useState, useRef, useEffect } from "react";
// @mui
import {
  OutlinedInput,
  Paper,
  Button,
  ClickAwayListener,
  InputAdornment,
  IconButton,
} from "@mui/material";
// redux
import { useDispatch } from "../../../redux/store";
import { createModule } from "../../../redux/slices/courseupdate";
// components
import Iconify from "../../../components/Iconify";

// ----------------------------------------------------------------------

export default function ModuleAdd({ id, index }) {
  const nameRef = useRef(null);

  const dispatch = useDispatch();

  const [name, setName] = useState("");

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      if (nameRef.current) {
        nameRef.current.focus();
      }
    }
  }, [open]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const handleCreateColumn = async () => {
    console.log("handleCreateColumn");
    try {
      if (name) {
        dispatch(createModule({ name, id, index }));
        setName("");
      }
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      handleCreateColumn();
    }
  };

  return (
    <Paper sx={{ minWidth: 280, width: "100%" }}>
      {!open && (
        <Button
          fullWidth
          size="large"
          color="inherit"
          variant="outlined"
          startIcon={<Iconify icon={"eva:plus-fill"} width={20} height={20} />}
          onClick={handleOpen}
        >
          Add Section
        </Button>
      )}

      {open && (
        <ClickAwayListener onClickAway={handleCreateColumn}>
          <OutlinedInput
            fullWidth
            placeholder="New Section"
            inputRef={nameRef}
            value={name}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleCreateColumn}
                  onMouseDown={handleCreateColumn}
                  edge="end"
                  size="large"
                  color={name ? "primary" : 'default'}
                >
                  <Iconify
                    icon={"eva:checkmark-circle-2-outline"}
                    width={30}
                    height={30}
                  />
                </IconButton>
              </InputAdornment>
            }
            onChange={handleChangeName}
            onKeyUp={handleKeyUp}
            sx={{ typography: "h6", mt: 1 }}
          />
        </ClickAwayListener>
      )}
    </Paper>
  );
}
