import PropTypes from 'prop-types';

import { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
// @mui
import { Paper, Typography, Box, Checkbox, Stack, Button, useTheme, IconButton } from '@mui/material';
// components
// import Image from '../../../components/Image';
import Iconify from '../../../components/Iconify';
//
import ModuleLessonDetails from './ModuleLessonDetails';
import { useDispatch, useSelector } from '../../../redux/store';
import { getCourse, updateLesson } from '../../../redux/slices/courseupdate';
// ----------------------------------------------------------------------
import Label from 'src/components/Label';
ModuleLessonCard.propTypes = {
  card: PropTypes.object,
  index: PropTypes.number,
  onDeleteTask: PropTypes.func
};

export default function ModuleLessonCard({ card, onDeleteTask, index, data: mdata }) {
  const theme = useTheme();
  const { name, content_type: type, custom_weightage = '' } = card;
  const dispatch = useDispatch();
  // const {addcourse}=useSelector()
  const { addcourse } = useSelector((state) => state.courseupdate);
  const [openDetails, setOpenDetails] = useState(false);

  // const [completed, setCompleted] = useState(card.completed);

  const handleOpenDetails = () => {
    setOpenDetails(true);
  };

  const handleCloseDetails = () => {
    setOpenDetails(false);
  };
  const saveLesson = (p) => {
    handleCloseDetails();
    dispatch(getCourse(addcourse.firstform.id));
    dispatch(updateLesson(p));
  };
  const handleChangeComplete = (event) => {
    // setCompleted(event.target.checked);
  };

  return (
    <Draggable draggableId={card.id} index={index}>
      {(provided) => (
        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          <Paper
            sx={{
              px: 2,
              width: 1,
              position: 'relative',
              boxShadow: (theme) => theme.customShadows.z1,
              '&:hover': {
                boxShadow: (theme) => theme.customShadows.z16
              }
              // ...(attachments.length > 0 && {
              //   pt: 2,
              // }),
            }}
          >
            <Box
              onClick={handleOpenDetails}
              sx={{
                cursor: 'pointer'
                // display: "flex",
                // flexDirection: "row",
                // justifyContent: "space-between",
              }}
            >
              <Stack
                direction={'column'}
                sx={{
                  pb: 2,
                  // pl: 5,
                  pl: 5,
                  pt: 2,
                  mr: 4
                }}
                // spacing={2}
              >
                <Stack direction={'row'}>
                  <ContentIcon theme={theme} type={type} />
                  <Typography noWrap variant="body" color="textPrimary">
                    {index + 1}. {name}
                  </Typography>
                </Stack>
                <Stack direction={'row'} sx={{ ml: 1 }} spacing={2}>
                  {custom_weightage && (
                    <>
                      <Typography variant="subtitle2" color="textSecondary">
                        Custom Weightage Group Assigned :
                      </Typography>
                      <Typography variant="subtitle2" color="textPrimary">
                        <b>{custom_weightage}</b>
                      </Typography>
                    </>
                  )}
                </Stack>
              </Stack>
            </Box>
          </Paper>

          {openDetails && (
            <ModuleLessonDetails
              courseid={addcourse.firstform.id}
              card={card}
              isOpen={openDetails}
              onClose={handleCloseDetails}
              onDeleteTask={() => onDeleteTask(card)}
              saveLesson={(p) => saveLesson(p)}
              mdata={mdata}
            />
          )}
        </div>
      )}
    </Draggable>
  );
}

const ContentIcon = ({ type, theme }) => {
  return (
    <IconButton
      variant="outlined"
      color="primary"
      // size="small"
      aria-label="edit"
      size="medium"
      sx={{
        mt: -1
      }}
    >
      <Iconify
        icon={
          type === 'lesson'
            ? 'ic:outline-play-lesson'
            : type === 'quiz'
            ? 'emojione-monotone:letter-q'
            : type === 'homework'
            ? 'material-symbols:home-work-outline'
            : 'ic:outline-assignment'
        }
        color={theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.primary.light}
        size={35}
      />
    </IconButton>
  );
};
