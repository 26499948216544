import { createSlice } from '@reduxjs/toolkit';
import { eduleyApi } from 'src/api/apiConfig';
// import omit from "lodash/omit";
import {
  COURSE_DOC_ASSIGNMENT,
  COURSE_PRACTICAL_ASSIGNMENT,
  COURSE_ZOOM_ASSIGNMENT,
  COURSE_LESSON,
  COURSE_QUIZ,
  COURSE_QUIZ_QUESTION
} from 'src/apiUrl';
// utils
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  openwithoutsave: false,
  quiz: {
    data: {},
    error: null,
    isLoading: false
  },
  lesson: {
    data: {},
    error: null,
    isLoading: false
  },
  docAssignment: {
    data: {},
    error: null,
    isLoading: false
  },
  homework: {
    data: {},
    error: null,
    isLoading: false
  },
  zoomAssignment: {
    data: {},
    error: null,
    isLoading: false
  },
  practicalAssignment: {
    data: {},
    error: null,
    isLoading: false
  }
};

const slice = createSlice({
  name: 'lesson',
  initialState,
  reducers: {
    // START LOADING
    startLoadingQuiz(state) {
      state.quiz.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    hasErrorQuiz(state, action) {
      state.quiz.isLoading = false;
      state.quiz.error = action.payload;
    },

    setQuiz(state, action) {
      console.log('setQuiz', action.payload);
      state.quiz.data = action.payload;
      state.quiz.isLoading = false;
      state.quiz.error = null;
    },
    openwithoutsave(state, action) {
      state.openwithoutsave = true;
    },
    closewithoutsave(state, action) {
      state.openwithoutsave = false;
    },
    setLecture(state, action) {
      state.lesson.data = action.payload;
      state.lesson.isLoading = false;
      state.lesson.error = null;
    },
    // HAS ERROR
    hasErrorLecture(state, action) {
      state.lesson.isLoading = false;
      state.lesson.error = action.payload;
    },
    setLecturenull(state) {
      state.lesson.data = {};
      state.lesson.isLoading = false;
    },
    startLoadingLecture(state) {
      state.lesson.isLoading = true;
    },
    startLoadingDocAssignment(state) {
      state.docAssignment.isLoading = true;
    },
    startLoadingHomework(state) {
      state.docAssignment.isLoading = true;
    },
    hasErrorDocument(state, action) {
      state.docAssignment.isLoading = false;
      state.docAssignment.error = action.payload;
    },

    setDocAssignment(state, action) {
      state.docAssignment.data = action.payload;
      state.docAssignment.isLoading = false;
      state.docAssignment.error = null;
    },
    setDocAssignmentNull(state, action) {
      state.docAssignment.data = null;
      state.docAssignment.error = null;
    },
    setHomework(state, action) {
      state.docAssignment.data = action.payload;
      state.docAssignment.isLoading = false;
      state.docAssignment.error = null;
    },
    startLoadingZoomAssignment(state) {
      state.zoomAssignment.isLoading = true;
    },
    hasErrorZoom(state, action) {
      state.zoomAssignment.isLoading = false;
      state.zoomAssignment.error = action.payload;
    },
    setZoomAssignment(state, action) {
      state.zoomAssignment.data = action.payload;
      state.zoomAssignment.isLoading = false;
      state.zoomAssignment.error = null;
    },
    startLoadingPracticalAssignment(state) {
      state.practicalAssignment.isLoading = true;
    },
    hasErrorPractical(state, action) {
      state.practicalAssignment.isLoading = false;
      state.practicalAssignment.error = action.payload;
    },
    setPracticalAssignment(state, action) {
      state.practicalAssignment.data = action.payload;
      state.practicalAssignment.isLoading = false;
      state.practicalAssignment.error = null;
    }
  }
});

// Reducer
export default slice.reducer;

export const { openwithoutsave, closewithoutsave, setDocAssignment } = slice.actions;
export const { actions } = slice;

// ----------------------------------------------------------------------
// export const c_update = { changeset };
const accessToken = localStorage.getItem('accessToken');

export const getQuiz = (id) => async (dispatch) => {
  dispatch(actions.startLoadingQuiz());
  try {
    const res = await eduleyApi.get(`${COURSE_QUIZ}${id}`);
    dispatch(actions.hasError(null));
    dispatch(actions.setQuiz(res.data));
  } catch (error) {
    dispatch(actions.hasError(error?.data?.data));
  }
};

export const addQuiz = (data, section) => async (dispatch) => {
  // console.log("addQuiz--", data);
  let d = {
    ...data,
    limited_attempt: data.limited_attempt ? data.limited_attempt : false,
    attempts_allowed: data.attempts_allowed ? data.attempts_allowed : null
  };
  dispatch(actions.startLoadingQuiz());
  try {
    const res = await eduleyApi.post(`${COURSE_QUIZ}`, d);
    // dispatch(actions.hasError(null));
    dispatch(actions.setQuiz(res.data));
    console.log(res.data);

    // console.log(r)
    return { res: 'success', data: res.data };
  } catch (error) {
    console.log(error?.data?.data);
    dispatch(actions.hasErrorQuiz(error?.data?.data));
    return 'error';
  }
};

export const updateQuiz = (data, id) => async (dispatch) => {
  dispatch(actions.startLoadingQuiz());
  let d = {
    ...data,
    limited_attempt: data.limited_attempt ? data.limited_attempt : false,
    attempts_allowed: data.attempts_allowed ? data.attempts_allowed : null
  };
  // console.log("updateQuiz--", d, id);
  try {
    const res = await eduleyApi.patch(`${COURSE_QUIZ}${id}/`, d);
    dispatch(actions.hasError(null));
    dispatch(actions.setQuiz(res.data));
    return 'success';
  } catch (error) {
    dispatch(actions.hasErrorQuiz(error?.data?.data));
    return 'error';
  }
};
export const deleteQuizquestion = (id) => async (dispatch) => {
  try {
    const res = await eduleyApi.delete(`${COURSE_QUIZ_QUESTION}${id}/`);
    return 'success';
  } catch (error) {
    return 'error';
  }
};

export const setQuiznull = () => (dispatch) => {
  // dispatch(actions.startLoading());
  console.log('setQuiznull');
  dispatch(actions.setQuiz({}));
};
export const getLecture = (id) => async (dispatch) => {
  console.log('getLecture', id);
  dispatch(actions.startLoadingLecture());
  try {
    const res = await eduleyApi.get(`${COURSE_LESSON}${id}/`);
    // dispatch(actions.hasError(null));
    console.log('getLecture', res.data);
    dispatch(actions.setLecture(res.data));
  } catch (error) {
    dispatch(actions.hasError(error));
  }
};

export const setLecturenull = () => async (dispatch) => {
  console.log('setLecturenull');
  dispatch(actions.setLecture({}));
};

export const addLecture = (data, section) => async (dispatch) => {
  dispatch(actions.startLoadingLecture());
  try {
    const res = await eduleyApi.post(`${COURSE_LESSON}`, data);
    dispatch(actions.setLecture(res.data));

    return { res: 'success', data: res.data };
  } catch (error) {
    dispatch(actions.hasErrorLecture(error?.data?.data));
    return 'error';
  }
};
export const updateLecture = (data, id) => async (dispatch) => {
  dispatch(actions.startLoadingLecture());
  try {
    const res = await eduleyApi.patch(`${COURSE_LESSON}${id}/`, data);
    dispatch(actions.hasError(null));
    dispatch(actions.setLecture(res.data));
    return 'success';
  } catch (error) {
    dispatch(actions.hasError(error?.data?.data));
    return 'error';
  }
};
///document
export const getDocAssignment = (id) => async (dispatch) => {
  dispatch(actions.startLoadingDocAssignment());
  dispatch(actions.setDocAssignmentNull());
  try {
    const res = await eduleyApi.get(`${COURSE_DOC_ASSIGNMENT}${id}`);
    dispatch(actions.hasError(null));
    dispatch(actions.setDocAssignment(res.data));
    return 'success'
  } catch (error) {
    dispatch(actions.hasErrorDocument(error?.data?.data));
    return 'error'
  }
};

export const addDocAssignment = (data, section) => async (dispatch) => {
  dispatch(actions.startLoadingDocAssignment());
  console.log('payload--', data);

  try {
    const res = await eduleyApi.post(`${COURSE_DOC_ASSIGNMENT}`, data);
    console.log('response--', res);

    dispatch(actions.hasError(null));
    dispatch(actions.setDocAssignment(res.data));
    return { res: 'success', data: res.data };
  } catch (error) {
    dispatch(actions.hasErrorDocument(error?.data?.data));
    return 'error';
  }
};
export const updateDocAssignment = (data, id) => async (dispatch) => {
  dispatch(actions.startLoadingDocAssignment());
  console.log('payload--', data);
  try {
    const res = await eduleyApi.patch(`${COURSE_DOC_ASSIGNMENT}${id}/`, data);
    console.log('response---', res);

    dispatch(actions.hasError(null));
    dispatch(actions.setDocAssignment(res.data));
    return 'success';
  } catch (error) {
    dispatch(actions.hasErrorDocument(error?.data?.data));
    return 'error';
  }
};

///zoom

export const getZoomAssignment = (id) => async (dispatch) => {
  dispatch(actions.startLoadingZoomAssignment());
  try {
    const res = await eduleyApi.get(`${COURSE_ZOOM_ASSIGNMENT}${id}/`);
    dispatch(actions.hasError(null));
    dispatch(actions.setZoomAssignment(res.data));
    return 'success';
  } catch (error) {
    dispatch(actions.hasErrorZoom(error?.data?.data));
    return 'error';
  }
};
export const addZoomAssignment = (data) => async (dispatch) => {
  dispatch(actions.startLoadingZoomAssignment());
  try {
    const res = await eduleyApi.post(`${COURSE_ZOOM_ASSIGNMENT}`, data);
    dispatch(actions.hasError(null));
    dispatch(actions.setZoomAssignment(res.data));
    return 'success';
  } catch (error) {
    dispatch(actions.hasErrorZoom(error?.data?.data));
    return 'error';
  }
};
export const updateZoomAssignment = (data, id) => async (dispatch) => {
  dispatch(actions.startLoadingZoomAssignment());
  try {
    const res = await eduleyApi.patch(`${COURSE_ZOOM_ASSIGNMENT}${id}/`, data);
    dispatch(actions.hasError(null));
    dispatch(actions.setZoomAssignment(res.data));
    return 'success';
  } catch (error) {
    dispatch(actions.hasErrorZoom(error?.data?.data));
    return 'error';
  }
};
export const getPracticalAssignment = (id) => async (dispatch) => {
  dispatch(actions.startLoadingPracticalAssignment());
  try {
    const res = await eduleyApi.get(`${COURSE_PRACTICAL_ASSIGNMENT}${id}/`);
    dispatch(actions.hasError(null));
    dispatch(actions.setPracticalAssignment(res.data));
  } catch (error) {
    dispatch(actions.hasErrorPractical(error?.data?.data));
  }
};
export const addPracticalAssignment = (data) => async (dispatch) => {
  dispatch(actions.startLoadingPracticalAssignment());
  try {
    const res = await eduleyApi.post(`${COURSE_PRACTICAL_ASSIGNMENT}`, data);
    dispatch(actions.hasError(null));
    dispatch(actions.setPracticalAssignment(res.data));
    return 'success';
  } catch (error) {
    dispatch(actions.hasErrorPractical(error?.data?.data));
    return 'error';
  }
};
export const updatePracticalAssignment = (data, id) => async (dispatch) => {
  dispatch(actions.startLoadingPracticalAssignment());
  try {
    const res = await eduleyApi.patch(`${COURSE_PRACTICAL_ASSIGNMENT}${id}/`, data);
    dispatch(actions.hasError(null));
    dispatch(actions.setPracticalAssignment(res.data));
    return 'success';
  } catch (error) {
    dispatch(actions.hasErrorPractical(error?.data?.data));
    return 'error';
  }
};
