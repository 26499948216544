import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import IconifyButton from "src/components/IconifyButton";
import MediaModal from "src/sections/media/MediaModal";
import MediaModal2 from "src/sections/media/MediaModal2";
const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 150,
  fontSize: 13,
  flexShrink: 0,
  color: theme.palette.text.secondary,
}));
const SubjectiveQuestionList = ({ data, handleUpdate }) => {
  const [openModal, setOpenModal] = React.useState(false);

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const handleAddQuestion = (question) => {
    let temp = [...data, question];
    handleModal();
    handleUpdate(temp);
  };
  const handleUpdateQuestion = (question, index) => {
    let temp = [...data];
    temp[index] = question;
    handleUpdate(temp);
  };

  const handleDelete = (index) => {
    let temp = [...data];
    // Delete element at the specified index
    temp.splice(index, 1);
    handleUpdate(temp);
  };

  const Question = ({ item, index, handleDelete, handleUpdate }) => {
    const [editModal, setEditModal] = React.useState(false);

    const handleEditModal = () => {
      setEditModal(!editModal);
    };

    return (
      <>
        <Stack direction="row" sx={{ mt: -1 }} justifyContent="flex-end">
          <IconifyButton
            icon="ant-design:edit-outlined"
            onClick={handleEditModal}
          />
          <IconifyButton
            icon="eva:trash-2-outline"
            color="error"
            onClick={() => handleDelete(index)}
          />
        </Stack>

        <Stack direction="row" sx={{ mt: -1 }} justifyContent="space-between">
          <LabelStyle sx={{ mt: 0, width: 100 }}>
            {index + 1}. Question
          </LabelStyle>
          <LabelStyle sx={{ mt: 0, flexGrow: 2 }}>{item?.name}</LabelStyle>
          <LabelStyle sx={{ whiteSpace: "pre-line", pl: 2, width: 100 }}>
            Mark: <b>{item?.maximum_marks}</b>
          </LabelStyle>
        </Stack>
        {editModal && (
          <AddNewQuestion
            handleSave={handleUpdate}
            data={item}
            handleCancel={handleEditModal}
          />
        )}
        <Divider />
      </>
    );
  };
  return (
    <>
      {data?.map((item, index) => (
        <Question
          item={item}
          index={index}
          key={index}
          handleDelete={handleDelete}
          handleUpdate={(v) => handleUpdateQuestion(v, index)}
        />
      ))}
      <Stack direction={"row"} justifyContent={"center"}>
        <Button onClick={handleModal} variant="contained">
          Add New Question
        </Button>
      </Stack>
      {openModal && (
        <AddNewQuestion
          handleSave={handleAddQuestion}
          handleCancel={handleModal}
        />
      )}
    </>
  );
};

export default SubjectiveQuestionList;

const AddNewQuestion = ({
  handleSave,
  handleCancel,
  data = { name: "", attachment: {}, maximum_marks: "" },
}) => {
  const [question, setQuestion] = React.useState(data?.name);
  const [maximum_mark, setMaximum_mark] = React.useState(data?.maximum_marks);
  const [media, setMedia] = useState(data?.attachment);
  const [errors, setErrors] = useState({});

  const addingMedia = (media) => {
    setMedia(media);
  };
  const handleQuestion = (e) => {
    setQuestion(e.target.value);
  };
  const handleSubmit = () => {
    if (question?.length > 500) {
      setErrors({ ...errors, maximum_marks: "Maximum 500 characters" });
      return;
    }
    if (question?.length < 1) {
      setErrors({ ...errors, question: "Question is required" });
      return;
    }
    if (maximum_mark <= 0) {
      setErrors({ ...errors, maximum_marks: "Maximum marks is required" });
      return;
    }
    let temp = {
      ...data,
      name: question,
      attachment: media,
      maximum_marks: maximum_mark,
    };
    handleSave(temp);
  };
  const handleChageMarks = (e) => {
    setMaximum_mark(e.target.value);
  };
  console.log("object", media);
  return (
    <>
      <Dialog open={true} fullWidth maxWidth="sm">
        <DialogTitle>Add New Question</DialogTitle>
        <DialogContent>
          <Stack direction="row">
            <LabelStyle sx={{ mt: 1 }}>Question</LabelStyle>
            <Stack direction="column" sx={{ flexGrow: 1 }}>
              <TextField
                fullWidth
                multiline
                rows={3}
                error={question?.length >= 500 ? true : errors.question}
                helperText={
                  question?.length >= 500
                    ? "Maximum 500 characters"
                    : errors.question
                }
                size="small"
                placeholder="Question"
                inputProps={{ maxLength: 500 }}
                value={question}
                onChange={handleQuestion}
                sx={{ typography: "body2", mt: 1 }}
              />
            </Stack>
          </Stack>
          <Stack direction="row" sx={{ mt: 3 }}>
            <LabelStyle sx={{ mt: 1 }}>Enter Maximum Marks</LabelStyle>

            <TextField
              fullWidth
              size="small"
              placeholder="Enter Marks"
              value={maximum_mark}
              type="number"
              onChange={handleChageMarks}
              error={errors.maximum_marks}
              helperText={
                errors.maximum_marks || "Enter Marks for this question"
              }
            />
          </Stack>
          <Stack direction="row" sx={{ mt: 2, mb: 2 }}>
            <LabelStyle sx={{ mt: 1 }}>Attachment</LabelStyle>
            <Stack direction="column">
              <MediaModal2
                media={media?.id || null}
                file_type={"image"}
                addingMedia={addingMedia}
                removingMedia={addingMedia}
              />
              <Typography variant="" sx={{ mt: 1, fontSize: 12, ml: 1 }}>
                Select only image
              </Typography>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
