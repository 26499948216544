import { alpha, Divider, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'

const DisableCustomScoreView = ({ data, disableCustomScore }) => {
    const theme = useTheme()
    return (
        <Stack direction={'column'} alignItems={'center'} justifyContent={'center'}>
            {!!disableCustomScore ? <Typography variant='h6' color={alpha(theme.palette.primary.main, 0.8)}>
                Disable custom score
            </Typography> :
                <>
                    <Typography variant='h5' color={alpha(theme.palette.primary.main, 0.8)}>
                        Custom score
                    </Typography>
                    <Stack direction={'column'} sx={{ width: { xs: '100%', md: '70%' }, mt: 1, p: 3, border: `1px solid ${theme.palette.primary.main}`, borderRadius: 2 }} alignItems={'center'} justifyContent={'center'} spacing={3}>
                        {data?.map((a, index) => (<Stack key={index} sx={{ width: '100%' }} direction={'column'}><Stack direction={'row'} justifyContent={'space-between'} spacing={1}>
                            <Typography key={index} variant='h6' color={'text.secondary'}>
                                {a?.institute_transcript_weightage?.name}
                            </Typography>
                            <Typography key={index} variant='h6' color={alpha(theme.palette.primary.main, 0.8)}>
                                {a?.weightage}
                            </Typography>
                        </Stack>
                            <Typography variant='body' color={'text.secondary'} sx={{ fontSize: 12 }}>{a?.note}</Typography>
                            <Divider />
                        </Stack>
                        ))}
                    </Stack>


                </>}
            {/* {JSON.stringify(data[0])} */}
        </Stack>
    )
}

export default DisableCustomScoreView