import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useTheme, styled } from "@mui/material/styles";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
// import Iconify from "src/components/Iconify";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  RHFSwitch,
  RHFEditor,
  FormProvider,
  RHFTextField,
  RHFUploadSingleFile,
} from "../../../components/hook-form";

// import { DialogAnimate } from "src/components/animate";
import {
  Box,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
  DialogActions,
  alpha,
  Dialog,
  Divider,
  Grid,
  Autocomplete,
  Chip,
  Card,
  Skeleton,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import icon from "src/assets/images/header_icon.png";
import { useDispatch } from "src/redux/store";
import { eduleyApi } from "src/api/apiConfig";
import { INSTRUCTOR } from "src/apiUrl";
import MediaModal2 from "src/sections/media/MediaModal2";
import { QUALIFICATION_LIST } from "./constant";
import { createFilterOptions } from "@mui/material/Autocomplete";
// import { updateCourse } from "src/redux/slices/course";

const filter = createFilterOptions();
const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(16),
  height: theme.spacing(16),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
}));
const InstructorUpdateModal = ({ data, onclose, color = "primary", open }) => {
  //   console.log(data);
  const [value, setValue2] = React.useState(data);
  const [loading, setLoading] = React.useState(false);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  const handleClose = () => {
    // console.log("close");
    onclose();
  };
  const setCoverImage = (media) => {
    setValue2({ ...value, introductory_media: media.id });
  };

  const defaultValues = useMemo(
    () => ({
      designation: value?.designation?.split(",") || [],
      tags: value?.designation?.split(",") || [],
    }),
    [value?.designation]
  );
  useEffect(() => {
    getDetails();
  }, [])
  const methods = useForm({
    // resolver: yupResolver(NewBlogSchema),
    defaultValues,
  });


  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState
  } = methods;

  useEffect(() => {
    if (value?.designation) {
      reset(defaultValues);
    }
  }, [value?.designation]);

  const values = watch();
  console.log('getDetails', values)
  const handleSubmit2 = async () => {
    let v = { ...value, designation: values.tags.join(",") };
    console.log("submit");
    setLoading(true);
    try {
      const res = await eduleyApi.patch(`${INSTRUCTOR}${data.instructor_id}/`, v);

      onclose();
    } catch (error) {
      console.log("error", error);
      setError(error.data.data);
    }

    setLoading(false);
  };
  const getDetails = async () => {

    setFetching(true);
    try {
      const res = await eduleyApi.get(`${INSTRUCTOR}${data.instructor_id}/`,);
      console.log('getDetails', res.data)
      setValue2(res.data);
    } catch (error) {
      console.log("error", error);
      setError(error.data.data);
    }

    setFetching(false);
  };
  console.log(values);

  const onSubmit = async () => { };
  if (fetching) {
    return <Dialog open={open} size="md" fullWidth onClose={handleClose}>
      <DialogTitle>{value?.iap_user?.first_name}</DialogTitle>
      <DialogContent>
        <DialogContentText>{value.name}</DialogContentText>
        <Stack direction={"column"} spacing={2} justifyContent={"center"} fullWidth alignItems={"center"}>
          <Skeleton variant="circular" width={80} height={80} />
          <Skeleton variant="rectangular" width="100%" height={80} />
          <Skeleton variant="rectangular" width="100%" height={80} />
          <Skeleton variant="text" width="100%" height={40} />
        </Stack>
      </DialogContent>
    </Dialog>
  } else {


    return (
      <Dialog open={open} size="md" fullWidth onClose={handleClose}>
        <DialogTitle>{value?.iap_user?.first_name}</DialogTitle>
        <DialogContent>
          <DialogContentText>{value.name}</DialogContentText>
          <Box fullWidth sx={{ height: 180, pt: 2 }}>
            <IconWrapperStyle
              sx={{
                color: (theme) => theme.palette[color].dark,
                backgroundImage: (theme) =>
                  `linear-gradient(135deg, ${alpha(
                    theme.palette[color].dark,
                    0
                  )} 0%, ${alpha(theme.palette[color].dark, 0.24)} 100%)`,
              }}
            >
              {value?.iap_user?.profile_photo ? (
                <img
                  src={value?.iap_user?.profile_photo}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                  alt="icon"
                />
              ) : (
                <img src={icon} alt="icon" />
              )}
            </IconWrapperStyle>
          </Box>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="h7" sx={{ mb: 2 }}>
              Qualification
            </Typography>
            <Controller
              name="tags"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  defaultValue={values.tags}
                  multiple
                  error={error?.designation}
                  helperText={error?.designation}
                  freeSolo
                  onChange={(event, newValue) => {
                    field.onChange(newValue)
                  }}
                  filterOptions={(options, params) => {
                    const { inputValue } = params;
                    const filtered = filter(options, params);
                    const isExisting = options.some((option) => inputValue === option.title);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push(`${inputValue}`);
                    }
                    return filtered;
                  }}
                  options={QUALIFICATION_LIST.map((option) => option)}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        key={option}
                        size="small"
                        label={option}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField sx={{ mt: 1, mb: 2 }} {...params} />
                  )}
                />
              )}
            />
          </FormProvider>
          <Typography variant="h7">Description</Typography>
          <TextField
            //   type="number"
            fullWidth
            multiline
            autoFocus
            size="medium"
            margin="dense"
            error={error?.description}
            helperText={error?.description}
            rows={2}
            variant="outlined"
            value={value.description}
            onChange={(e) => setValue2({ ...value, description: e.target.value })}
            sx={{ mt: 1 }}
          />
          <Typography
            sx={{
              color: "text.secondary",
              fontStyle: "italic",
              fontSize: 15,
              textAlign: "right",
              mb: 2,
            }}
          >
            <b>{500 - (value?.description?.length || 0)} characters </b> left
          </Typography>
          <Typography variant="h7" sx={{ mb: 2 }}>
            Introduction Video
          </Typography>
          <br />
          <MediaModal2
            media={value.introductory_media}
            addingMedia={(rajeev) => setCoverImage(rajeev)}
            removingMedia={(rajeev) => setCoverImage(null)}
            file_type={"video"}
            public_private="public"
          />
          {/* <MediaModal
          addingMedia={(rajeev) => setCoverImage(rajeev)}
          media={value.introductory_media}
        /> */}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => handleClose()}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            onClick={handleSubmit2}
            loading={loading}
            disabled={loading}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
};

export default InstructorUpdateModal;
