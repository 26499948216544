import { alpha, Button, Card, CardContent, CardHeader, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'

import useAuth from "src/hooks/useAuth";
import MultipleErrorModal from "src/components/MulipleErrorModal";
import MediaModal2 from "src/sections/media/MediaModal2";
import { useDispatch, useSelector } from "src/redux/store";
import { useMemo } from 'react';
import MediaModal from 'src/sections/media/MediaModal';
import Iconify from 'src/components/Iconify';
import IconifyButton from 'src/components/IconifyButton';
import ClipboardCopy from 'src/components/ClipboadCopy';

const PublishView = () => {
    const theme = useTheme()
    const { instituteinfo: intituteInfo } = useAuth();
    const { addcourse } = useSelector(
        (state) => state.courseupdate
    );
    const {
        poster_image,
        intro_video_media,
        name, slug, status
    } = useMemo(() => addcourse?.firstform, [addcourse?.firstform]);
    console.log('addMedia', addcourse?.firstform, intituteInfo);
    const courseLink = `${window.location.origin?.replace('-admin', '')?.replace('preview', '')}/course/${slug}`
    return (
        <Card sx={{ p: 3 }}>
            <CardHeader
                title="Publish Program"
            // sx={{ textAlign: "center" }}
            />
            <CardContent>
                <Stack direction={'column'} spacing={3}>

                    <Typography variant="h4" textAlign={'center'} sx={{ width: '100%' }} color={`${alpha(theme.palette.primary.main, 0.8)}`} >
                        {name}
                    </Typography>
                    <Stack direction={'row'} spacing={2} justifyContent={'center'}>

                        <Typography variant="p" component={'a'} sx={{


                        }} href={courseLink} target={'_blank'} rel="noreferrer" textAlign={'center'} color={`${alpha(theme.palette.primary.main, 0.8)}`} >
                            {courseLink}
                        </Typography>
                        <ClipboardCopy text={courseLink} disabled={status != 'live'} />
                    </Stack>
                    {status != 'live' && <Typography variant="body" textAlign={'center'} sx={{ width: '100%' }} color={`${alpha(theme.palette.error.main, 0.8)}`} >Link is not active</Typography>}
                    <Stack direction={'row'} spacing={2} justifyContent={'center'}>

                        <Button variant="contained" startIcon={<Iconify icon={'ion:arrow-back'} />}>Go to Program Management </Button>
                    </Stack>


                </Stack>

            </CardContent>
        </Card>
    )
}

export default PublishView