import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import _ from 'lodash';
import { eduleyApi } from 'src/api/apiConfig';
import { SCOREWEIGHTAGE } from 'src/apiUrl';
import { useSelector } from 'src/redux/store';
import { TableLoader } from 'src/components/table';

const ContentWeightage = ({ data, handleSubmit, type }) => {
  const [Weightage, setWeightage] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const handleUpdate = () => {
    setOpenDialog(!openDialog);
  };
  const handleChange = (a) => {
    handleUpdate();
    handleSubmit(a);
  };

  return (
    <>
      <Stack direction={'row'} sx={{ mt: 1 }} spacing={2}>
        <TextField
          value={data?.name || (type == 'quiz' ? 'Quiz' : type == 'quota' ? 'Quota' : 'Homework')}
          helperText={
            type == 'quiz'
              ? 'This quiz belongs to this weightage group'
              : `This ${type == 'quota' ? 'quota' : 'homework'} belongs to this weightage group`
          }
          size="small"
          disabled
        />
        <Box>
          <Button variant="outlined" onClick={handleUpdate}>
            Change Weightage
          </Button>
        </Box>
      </Stack>
      {/* Dialog for updating custom score */}
      {openDialog && <UpdateWeightageDialog handleClose={handleUpdate} handleUpdate={handleChange} data={data} type={type} />}
    </>
  );
};

ContentWeightage.propTypes = {};

export default ContentWeightage;

const UpdateWeightageDialog = ({ handleUpdate, handleClose, data, type }) => {
  const [list, setList] = useState(null);
  const [list2, setList2] = useState(null);
  const [defaultData, setDefaultData] = useState(null);
  const { addcourse } = useSelector((state) => state.courseupdate);
  const [loading, setLoading] = useState([]);
  const [value, setValue] = useState(null);
  useEffect(() => {
    getListOfCustomScore();
  }, []);
  // useEffect(() => {
  //   if (!_.isEmpty(list)) {
  //     if (!_.isEmpty(data)) {
  //       // setValue(data.id);
  //     } else {
  //       // handleReset();
  //     }
  //   }
  // }, [list]);

  // ****************** get List of Custom score  *********//

  const getListOfCustomScore = async () => {
    setLoading(true);
    try {
      let res = await eduleyApi.get(`${SCOREWEIGHTAGE}?course=${addcourse.firstform.id}&transcript_type=calculated`);
      //   enqueueSnackbar(JSON.stringify(res));
      if (!_.isEmpty(res.data)) {
        let l = [...res.data];
        let currentType = type == 'quiz' ? 'quiz' : type == 'homework' ? 'homework' : 'quota';

        // removeing unnecesarry weightage

        let p = l?.filter((a) => a.key ? a?.key == currentType : a);

        // removing default data and current active weightage
        // from list of all weightages

        let t = p?.filter((a) => (!!data?.id ? a.id !== data?.id : a.key !== type));
        // get default data info
        let defaultInfo = l.find((a) => a.key == type);
        setDefaultData(defaultInfo);
        console.log('first', t);

        setList2(l);
        setList(t);
      }

      //   console.log("COURSE CUSTOM SCORE", res);
    } catch (error) { }
    setLoading(false);
  };
  const handleChange = (e) => {
    // console.log("first", e.target.value, defaultData?.id);
    if (e.target.value != defaultData?.id) {
      let p = [...list2];
      let t = p?.filter((a) => a.id !== defaultData?.id);
      // console.log("first", t);
      setList(t);
    }
    setValue((prev) => e.target.value);
  };
  const handleReset = () => {
    console.log('Listssss:', list2, type);
    let p = list2.find((a) => a.key == type);
    setList(list2);
    // console.log("first", p);
    setValue(p.id);
  };
  // console.log("first", data);
  const handleSubmit = () => {
    let p = list.find((a) => a.id == value);

    handleUpdate(p);
  };
  return (
    <>
      <Dialog open={true} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Change Weightage</DialogTitle>
        <DialogContent>
          {loading && <TableLoader loading={true} />}
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant="body2">Currect Weightage</Typography>
            <TextField
              sx={{
                my: 3
              }}
              // fullWidth
              disabled
              value={data?.name || (type == 'quiz' ? 'Quiz' : type == 'homework' ? 'Homework' : 'Quota')}
            // label="Weightage"
            />
          </Stack>
          <Divider sx={{ mb: 2 }} />
          <Typography variant="body2">
            You can change the weightage of this to a custom weightage. On change, the student grades will be calculated based on this custom
            weightage. Any change to weightage will only affect for the students who have not yet attempted this {type}
          </Typography>
          <Box
            sx={{
              mt: 3
            }}
          >
            <FormControl fullWidth>
              {/* <InputLabel shrink id="demo-simple-select-helper-label">
                Choose Custom score
              </InputLabel> */}
              <TextField
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper-label"
                value={value}
                select
                label="Choose Custom Score"
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="Select Custom Score"
                fullWidth
                onChange={handleChange}
              >
                <MenuItem value={null} disabled>
                  Select custom score
                </MenuItem>
                {list?.map((a, index) => (
                  <MenuItem value={a?.id}>{a.name}</MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReset} disabled={defaultData?.id == value || (!data?.id && !value)} variant="contained" color="secondary">
            Restore to Default
          </Button>{' '}
          <Button
            autoFocus
            onClick={handleClose}
            variant="outlined"
          // color="secondary"
          >
            Cancel
          </Button>
          <Button autoFocus onClick={handleSubmit} variant="contained" color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
