import { createSlice } from '@reduxjs/toolkit';

import {
  STUDENT_COURSE_ASSIGNMENT_REPORT_HIGHLIGHT,
  STUDENT_COURSE_QUIZ_REPORT_HIGHLIGHT,
  STUDENT_COURSE_REPORT,
  STUDENT_COURSE_REPORT_HIGHLIGHT,
  STUDENT_LIST
} from 'src/apiUrl';
// utils
//
import { eduleyApi } from 'src/api/apiConfig';
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  student: {},
  error: null,
  openUpdateCard: false,
  studentcourse: {
    data: {},
    stu_details: {},
    loading: false,
    coursehighlight: {},
    quizhighlight: {},
    assignmenthighlight: {}
  },
  currentStudent: {
    data: {},
    loading: false
  }
};

const slice = createSlice({
  name: 'student',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setStudentviewdatanull(state, action) {
      state.studentcourse = {
        data: {},
        stu_details: {},
        loading: false,
        coursehighlight: {},
        quizhighlight: {},
        assignmenthighlight: {}
      };
    },
    // GET PRODUCTS
    getStudentSuccess(state, action) {
      state.isLoading = false;
      const stu = action.payload;
      state.student = stu;
    },
    startCourseLoading(state) {
      state.studentcourse.loading = true;
    },
    getStudentcourseSuccess(state, action) {
      state.studentcourse.loading = false;
      const stu = action.payload;

      state.studentcourse.data = stu;
    },
    getStudentDetailsSuccess(state, action) {
      state.studentcourse.loading = false;
      const stu = action.payload;

      state.studentcourse.stu_details = stu;
    },
    getStudentcoursehighlightSuccess(state, action) {
      state.studentcourse.loading = false;
      const stu = action.payload;

      state.studentcourse.coursehighlight = stu;
    },
    handleUpdateStudent(state, action) {
      state.openUpdateCard = action.payload;
    },
    getStudentquizhighlightSuccess(state, action) {
      state.studentcourse.loading = false;
      const stu = action.payload;

      state.studentcourse.quizhighlight = stu;
    },
    getStudentassignmenthighlightSuccess(state, action) {
      state.studentcourse.loading = false;
      const stu = action.payload;
      state.studentcourse.assignmenthighlight = stu;
    },
    getCurrentStudentSuccess(state, action) {
      state.currentStudent.loading = false;
      const stu = action.payload;
      state.currentStudent.data = stu;
      state.openUpdateCard = false;
    },
    startCurrentStudentLoading(state) {
      state.currentStudent.loading = true;
    },
    setCurrentStudentDataNull(state, action) {
      state.currentStudent = {
        data: {},
        loading: false
      };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setCurrentStudentDataNull, handleUpdateStudent } = slice.actions;

// ----------------------------------------------------------------------

export function getStudentList(q) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await eduleyApi.get(`${STUDENT_LIST}${q}`);
      dispatch(slice.actions.getStudentSuccess(response.data));
    } catch (error) {
      // getStatus(error.response);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setClearallstudentcourseviewdata(q) {
  return () => {
    dispatch(slice.actions.setStudentviewdatanull());
  };
}
export function getStudentdetails(id) {
  return async () => {
    dispatch(slice.actions.startCourseLoading());
    try {
      const response = await eduleyApi.get(`${STUDENT_LIST}${id}/`);
      // console.log("student details---", response.data, params);
      dispatch(slice.actions.getStudentDetailsSuccess(response.data));
      dispatch(handleUpdateStudent(false));
      // setStudentData(response.data);
      // let p = response?.data?.subscriptions?.find((a) => a.id == params.course);
      // console.log(p);
      // setProgressData(p);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStudentCourse(p, q) {
  return async () => {
    dispatch(slice.actions.startCourseLoading());
    try {
      const response = await eduleyApi.get(`${STUDENT_COURSE_REPORT}${q}`);
      console.log(response, q);
      dispatch(slice.actions.getStudentcourseSuccess(response.data));
    } catch (error) {
      // getStatus(error.response);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStudentCourseHighlight(q) {
  return async () => {
    dispatch(slice.actions.startCourseLoading());
    try {
      const response = await eduleyApi.get(`${STUDENT_COURSE_REPORT_HIGHLIGHT}?subscription=${q}`);
      dispatch(slice.actions.getStudentcoursehighlightSuccess(response.data));
    } catch (error) {
      // getStatus(error.response);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getStudentCourseQuizHighlight(q) {
  return async () => {
    dispatch(slice.actions.startCourseLoading());
    try {
      const response = await eduleyApi.get(`${STUDENT_COURSE_QUIZ_REPORT_HIGHLIGHT}?subscription=${q}`);
      dispatch(slice.actions.getStudentquizhighlightSuccess(response.data));
    } catch (error) {
      // getStatus(error.response);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStudentCourseAssignmentHighlight(q) {
  return async () => {
    dispatch(slice.actions.startCourseLoading());
    try {
      const response = await eduleyApi.get(`${STUDENT_COURSE_ASSIGNMENT_REPORT_HIGHLIGHT}?subscription=${q}`);
      dispatch(slice.actions.getStudentassignmenthighlightSuccess(response.data));
    } catch (error) {
      // getStatus(error.response);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCurrentStudent(id) {
  return async () => {
    dispatch(slice.actions.startCurrentStudentLoading());
    try {
      const response = await eduleyApi.get(`${STUDENT_LIST}${id}/`);
      dispatch(slice.actions.getCurrentStudentSuccess(response.data));
      // dispatch(handleUpdateStudent(false));
    } catch (error) {
      // getStatus(error.response);
      dispatch(slice.actions.hasError(error));
    }
  };
}
