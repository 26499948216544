// FeeForm.js
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { alpha, Box, Button, Checkbox, FormControlLabel, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { isArray, isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';
import SvgIconStyle from 'src/components/SvgIconStyle';
import ConfirmDialog from 'src/components/confirm-dialog';
import { FormProvider } from 'src/components/hook-form';
import useAuth from 'src/hooks/useAuth';
import { formatNumber } from 'src/utils/common';
import { fCurrency } from 'src/utils/formatNumber';
import * as yup from 'yup';
import DiscountPreview from './DiscountPreview';
import FeePopover from './FeePopover';
import useFeeStructure from './useFeeStructure';

const FeeForm = ({ price, enableFeeStructure, onClose }) => {
  const { priceBreakup, updateCoursePriceBreakup, isCohort, feeData, hasDiscount, discountValue } = useFeeStructure();
  const { instituteinfo } = useAuth();
  const [dialogOpen, setDialogOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const feeComponentSchema = (discountValue, hasDiscount) =>
    yup.object().shape({
      description: yup.string().required('Fee name is required'),
      amount: yup
        .number()
        .required('Amount is required')
        .moreThan(0, 'Amount must be greater than 0')
        .when('description', {
          is: 'Tuition Fee',
          then: yup
            .number()

            .moreThan(0, 'Tuition Fee must be greater than 0')
            .test(
              'amount-check',
              `Amount must be greater than discount: ${`${instituteinfo?.default_currency?.prefix || ''}${` ${fCurrency(discountValue)} `}${
                instituteinfo?.default_currency?.suffix || ''
              }`}. Recommended way is to first remove discount than update the tuition fee.`,
              function (value) {
                const { hasDiscount, discountValue } = this.options.context;

                console.log('Checking AMount:', hasDiscount, value, discountValue);
                if (hasDiscount && Number(discountValue) >= value) {
                  return false;
                } else {
                  return true;
                }
                // return hasDiscount && value >= Number(discountValue);
              }
            )
        })
        .transform((value, originalValue) => {
          return originalValue === '' || originalValue === null || originalValue === undefined ? 0 : value;
        }),
      is_taxable: yup.boolean(),
      tax_description: yup
        .mixed()
        .nullable()
        .test('is-string-or-null', 'tax_description must be a string or null', (value) => value === null || typeof value === 'string')
        .when('is_taxable', {
          is: true,
          then: yup.string().required('Description is required.').typeError('Description must be a string'),
          otherwise: yup.number().nullable()
        }),
      tax_percentage: yup
        .mixed()
        .nullable()
        .test('is-number-or-string-number', 'tax_percentage must be a number or a string that can be converted to a number', (value) => {
          if (value === null) return true;
          if (typeof value === 'number') return true;
          if (typeof value === 'string') {
            const parsedValue = parseFloat(value);
            return !isNaN(parsedValue);
          }
          return false;
        })
        .transform((value, originalValue) => {
          if (originalValue === '' || originalValue === null) return null;
          return parseFloat(originalValue);
        })
        .when('is_taxable', {
          is: true,
          then: yup.number().required('Percentage is required.').moreThan(0, 'Percentage must be greater than 0'),
          otherwise: yup.number().nullable()
        })
        .transform((value, originalValue) => {
          return originalValue === '' || originalValue === null || originalValue === undefined ? 0 : value;
        })
    });

  const formSchema = (discountValue, hasDiscount) =>
    yup.object().shape({
      feeComponents: yup.array().of(feeComponentSchema(discountValue, hasDiscount))
    });

  const method = useForm({
    resolver: yupResolver(formSchema(discountValue, hasDiscount)),
    context: { discountValue, hasDiscount },
    defaultValues: {
      feeComponents: []
    },
    mode: 'onChange'
  });

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    trigger,
    formState: { errors, isSubmitting }
  } = method;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'feeComponents',
    keyName: 'customId'
  });

  const feeComponents = watch('feeComponents');

  // useEffect(() => {
  //   if (enableFeeStructure && fields.length === 0) {
  //     append({ description: '', amount: 0, is_taxable: false });
  //   }
  // }, [enableFeeStructure, append, fields.length]);

  useEffect(() => {
    if (isArray(priceBreakup) && !isEmpty(priceBreakup)) {
      setValue(
        'feeComponents',
        priceBreakup.map((item) => {
          return {
            ...item,
            amountHidden: item.calculated_amount
          };
        })
      );
    }
  }, [priceBreakup]);

  const calculateTax = (index, isTaxable) => {
    const feeComponent = getValues(`feeComponents.${index}`);
    console.log('Fee Component Tax:', feeComponent, feeComponent.is_taxable, isTaxable);
    if (!feeComponent.is_taxable) {
      setValue(`feeComponents.${index}.tax_description`, null);
      setValue(`feeComponents.${index}.tax_percentage`, null);
    }
    // else if(feeComponent?.description === 'Tuition Fee' && hasDiscount) {
    //   const taxAmount = (feeComponent.amountHidden * feeComponent.tax_percentage) / 100;
    //   console.log('Amount:', taxAmount);
    //   setValue(`feeComponents.${index}.calculated_amount`, formatNumber(Number(feeComponent.amountHidden) + taxAmount, 2));
    // }
    else if (feeComponent.is_taxable && Number(feeComponent.tax_percentage)) {
      const taxAmount = (feeComponent.amountHidden * feeComponent.tax_percentage) / 100;
      console.log('Amount:', taxAmount);
      setValue(`feeComponents.${index}.calculated_amount`, formatNumber(Number(feeComponent.amountHidden) + taxAmount, 2));
    } else if (feeComponent.tax_percentage == '') {
      setValue(`feeComponents.${index}.calculated_amount`, feeComponent.amountHidden);
    }
  };

  const onSubmit = async (data) => {
    console.log('Data Submit:', data);
    const totalAmount = data.feeComponents.reduce((acc, fee) => acc + fee.amount, 0);
    console.log('Total AMount:', totalAmount, price);
    try {
      await updateCoursePriceBreakup(data.feeComponents);
      setDialogOpen(false);
      onClose();
    } catch (err) {}
  };

  const sum = feeComponents.reduce((accumulator, currentValue) => {
    return accumulator + +currentValue.calculated_amount;
  }, 0);

  return (
    <Stack sx={{ position: 'relative', p: 2, height: '100%' }}>
      {feeComponents?.length > 0 && (
        <Scrollbar sx={{ bgcolor: 'background.neutral', p: 1.5, borderRadius: 1 }}>
          <FormProvider methods={method}>
            <Stack spacing={3}>
              {fields.map((item, index) => (
                <Stack
                  spacing={2}
                  sx={{
                    border: (theme) =>
                      `2px dashed ${
                        item.description == 'Tuition Fee' && hasDiscount ? alpha(theme.palette.success.main, 0.4) : theme.palette.grey[500]
                      }`,
                    p: 2,
                    bgcolor: 'white',
                    my: 0.5,
                    borderRadius: 1,
                    position: 'relative'
                  }}
                > 
                <Stack>
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr 1fr',
                      gap: 2
                    }}
                  >
                    <Controller
                      name={`feeComponents.${index}.description`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={item?.is_mandatory ? 'Fee Component*' : 'Fee Component'}
                          variant="outlined"
                          fullWidth
                          size="small"
                          error={!!errors.feeComponents?.[index]?.description}
                          helperText={errors.feeComponents?.[index]?.description?.message}
                          disabled={item?.is_mandatory}
                        />
                      )}
                    />
                   
                    <Controller
                      name={`feeComponents.${index}.amount`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            setValue(`feeComponents.${index}.amountHidden`, e.target.value);
                            setValue(`feeComponents.${index}.calculated_amount`, e.target.value);
                            // setValue(`feeComponents.${index}.tax_percentage`, null);
                          }}
                          label="Amount"
                          variant="outlined"
                          fullWidth
                          type="number"
                          size="small"
                          error={!!errors.feeComponents?.[index]?.amount}
                          helperText={errors.feeComponents?.[index]?.amount?.message}
                        />
                      )}
                    />
                    {
                      <Stack direction="row" alignItems="center" justifyContent="space-between">
                        {index !== 0 && index !== 1 && (
                          <Controller
                            name={`feeComponents.${index}.is_taxable`}
                            control={control}
                            render={({ field }) => (
                              <FormControlLabel
                                size="small"
                                control={
                                  <Checkbox
                                    {...field}
                                    checked={field.value}
                                    size="small"
                                    onChange={(e) => {
                                      field.onChange(e.target.checked);
                                      calculateTax(index, e.target.checked);
                                      if (!e.target.checked) {
                                        trigger();
                                      }
                                    }}
                                  />
                                }
                                label="Taxable"
                              />
                            )}
                          />
                        )}
                        {!item.is_mandatory && (
                          <Box
                            sx={{
                              position: 'absolute',
                              right: '-1%',
                              bottom: -20,
                              backgroundColor: 'white',
                              height: 40,
                              width: 40,
                              borderRadius: 50 / 2,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              zIndex: 999,
                              border: (theme) => `2px dashed ${theme.palette.grey[500]}`
                            }}
                          >
                            <IconButton
                              onClick={() => remove(index)}
                              sx={{
                                height: 30,
                                width: 30
                              }}
                            >
                              <Iconify icon="material-symbols-light:delete-outline" color="error.main" />
                            </IconButton>
                          </Box>
                        )}
                      </Stack>
                    }
                    
                  </Stack>
                  {item.description=="Tuition Fee" &&  <Typography variant="body1" paragraph>
                    This amount would be printed on the tax document of the student
                     </Typography>}
                  </Stack>
                  {feeComponents[index]?.is_taxable && index !== 0 && index !== 1 && (
                    <Stack
                      spacing={2}
                      direction="row"
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr 1fr',
                        // alignItems: 'center',
                        gap: 2
                      }}
                    >
                      <Controller
                        name={`feeComponents.${index}.tax_description`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Tax Name"
                            variant="outlined"
                            size="small"
                            fullWidth
                            error={!!errors.feeComponents?.[index]?.tax_description}
                            helperText={errors.feeComponents?.[index]?.tax_description?.message}
                          />
                        )}
                      />
                      <Controller
                        name={`feeComponents.${index}.tax_percentage`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            InputProps={{
                              endAdornment: <InputAdornment position="end"> %</InputAdornment>
                            }}
                            label="Tax Percent"
                            variant="outlined"
                            size="small"
                            fullWidth
                            type="number"
                            defaultValue=""
                            error={!!errors.feeComponents?.[index]?.tax_percentage}
                            helperText={errors.feeComponents?.[index]?.tax_percentage?.message}
                            onChange={(e) => {
                              console.log('EVENT:', e);
                              field.onChange(e);
                              calculateTax(index);
                            }}
                          />
                        )}
                      />
                      <Box />
                    </Stack>
                  )}
                  {item.description == 'Tuition Fee' && hasDiscount && (
                    <SvgIconStyle
                      src={`/icons/discount.svg`}
                      sx={{ width: 40, height: 40, position: 'absolute', bottom: -15, left: -15, color: (theme) => theme.palette.success.main }}
                    />
                  )}
                </Stack>
              ))}
            </Stack>
          </FormProvider>
        </Scrollbar>
      )}

      {
        <Stack
          // direction="row"
          // justifyContent="space-between"
          spacing={2}
          sx={{
            py: 2
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={2}
            sx={{
              py: 2
            }}
          >
            <Typography>Total</Typography>
            <Stack>
              <FeePopover data={feeComponents} totalAmount={sum}>
                <Typography variant="h5" color="text.secondary">
                  {`${instituteinfo?.default_currency?.prefix || ''}${fCurrency(sum)}${instituteinfo?.default_currency?.suffix || ''}`}
                </Typography>
              </FeePopover>
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Button
              fullWidth
              type="button"
              variant="soft"
              size="large"
              color="info"
              startIcon={<Iconify icon="ic:round-plus" />}
              onClick={() =>
                append({
                  description: '',
                  amount: 0,
                  calculated_amount: 0,
                  is_taxable: false,
                  tax_description: null,
                  tax_percentage: null,
                  course: !isCohort ? feeData?.id : feeData?.course,
                  cohort: isCohort ? feeData?.id : null
                })
              }
            >
              Add Fee Component
            </Button>
            <LoadingButton
              loading={isSubmitting}
              size="large"
              fullWidth
              variant="soft"
              onClick={async () => {
                const isValid = await trigger();
                if (!isValid) return;
                setDialogOpen(true);
              }}
              color="primary"
            >
              Save
            </LoadingButton>
          </Stack>
          {/* <FeeDiscount /> */}
          {!!feeData?.total_price && (
            <DiscountPreview
              feeComponents={feeComponents}
              totalAmount={sum}
              price={feeData?.total_price + feeData?.discounted_price}
              discountedPrice={feeData?.total_price}
            />
          )}
        </Stack>
      }
      {/* dialogue  */}
      <ConfirmDialog
        title="The payment plan should be updated manually."
        content='After updating the fee structure, the payment plan should be updated manually. Click "Yes, Proceed" to continue.'
        action={
          <Button onClick={handleSubmit(onSubmit)} color="error" variant="contained">
            Yes, Proceed
          </Button>
        }
        onClose={() => setDialogOpen(false)}
        open={dialogOpen}
      />
    </Stack>
  );
};

export default FeeForm;
