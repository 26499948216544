import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

const SpecialEventConfirmation = ({ open, setOpen, eventType, eventDate, courseName, eventName, confirmation, loading, isAttendanceNeeded }) => {
  const getConfirmationMessage = () => {
    switch (eventType) {
      case 'canceled':
        return `Are you sure you want to cancel the class on ${eventDate}?`;
      case 'holiday':
        return `Are you sure you want to mark ${eventDate} as a holiday for the course ${courseName} course?`;
      default:
        return `Are you sure you want to mark ${eventDate} as ${eventName} for the course ${courseName} course?`;
    }
  };

  const getTitle = () => {
    switch (eventType) {
      case 'canceled':
        return 'Confirm Class Cancellation';
      case 'holiday':
        return 'Confirm Holiday';
      case 'orientation':
        return 'Confirm Orientation Day';
      case 'graduation':
        return 'Confirm Graduation Day';
      case 'special_class':
        return 'Confirm Special Class';
      default:
        return 'Confirm Special Event';
    }
  };

  const getNoteMessage = () => {
    if (!isAttendanceNeeded) {
      return 'This day will not be counted in the attendance analytics.';
    }
    return 'This day will be counted in the attendance analytics.';
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          pb: 3
        }}
      >
        <Typography variant="h6" align="center">
          {getTitle()}
        </Typography>
        {/* <Close onClick={handleClose} style={{ cursor: 'pointer' }} /> */}
      </DialogTitle>
      <DialogContent
        sx={{
          paddingTop: '16px',
          paddingBottom: '16px',
          textAlign: 'center'
        }}
      >
        <Typography variant="body1" sx={{ marginBottom: '16px' }}>
          {getConfirmationMessage()}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          <strong>Note:</strong> {getNoteMessage()}
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          padding: '16px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Button onClick={handleClose} color="inherit" variant="outlined">
          Cancel
        </Button>
        <LoadingButton onClick={confirmation} color="primary" variant="contained" loading={loading}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SpecialEventConfirmation;
