import { useState, useEffect } from "react";
// @mui
import { Divider } from "@mui/material";
// components
import DocAssignment from "./assignment/DocAssignment";

export default function Assignments({
  data = {},
  handleUpdate,
  section,
  index, accessibleRoles
}) {
  const [assignmentType, setassignmentType] = useState({});
  const [da, setda] = useState(data);

  useEffect(() => {
    setda(data);
    // setassignmentType(ast);
  }, [data]);

  console.log("assignmentType--", assignmentType);
  const handleSubmit = (value) => {
    handleUpdate({ ...data, ...value });
  };

  return (
    <>
      <DocAssignment
        handleUpdate={handleSubmit}
        data={da}
        section={section}
        index={index}
        accessibleRoles={accessibleRoles}
      />
      <Divider />
    </>
  );
}
