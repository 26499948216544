import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import axios from 'axios';
import DefaulPreview from 'src/assets/images/DefaultPreview.png';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Card,
  Table,
  TableRow,
  Checkbox,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  DialogTitle,
  DialogActions,
  Button,
  Paper,
  Dialog,
  DialogContent
} from '@mui/material';
// redux
import { useDispatch, useSelector } from 'src/redux/store';
import { deleteDocument, deleteImage, deleteVideo, getMedialist } from 'src/redux/slices/media';
// utils

import useSettings from 'src/hooks/useSettings';
// components
import Page from 'src/components/Page';
import Label from 'src/components/Label';
// import Image from "src/components/Image";
import Scrollbar from 'src/components/Scrollbar';
// sections
import { ProductMoreMenu, ProductListHead, ParticularMediaToolBar } from './media-list';
import Iconify from 'src/components/Iconify';
// import { async } from "@firebase/util";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { DialogAnimate } from "src/components/animate";
import MediaViewer from "./mediaViewer/MediaViewer";
import { getPageLimit, setPageLimit } from "src/utils/pageLimitControl";
import RoleBasedGuard from "src/guards/RoleBasedGuard";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'Preview', label: 'Preview', alignRight: false },
  { id: 'name', label: 'Media Name', alignRight: false },
  { id: '', label: 'Media Permission', alignRight: false },
  { id: '', label: 'File Type', alignRight: false },
  // { id: "", label: "Media Link", alignRight: false },
  // { id: "more", label: "Option", alignRight: false },
  { id: '', alignRight: true }
];

// ----------------------------------------------------------------------

export default function ImageMedia({ selectedMedia, addmedia, public_private = '' }) {
  const { themeStretch } = useSettings();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { media, loading } = useSelector((state) => state.media);

  const [productList, setProductList] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(getPageLimit("medialist"));
  const [orderBy, setOrderBy] = useState("");
  const [orderBy2, setOrderBy2] = useState("id");
  const [mediatype, setMediatype] = useState("");
  const [mediaprivate, setMediaPrivate] = useState(public_private);
  const [openDelete, setOpenDelete] = useState({ data: {}, open: false });
  // console.log("medialist---", media);
  // console.log('file--', file_type)
  // deleting loader
  const [deleting, setDeleting] = useState(false);

  let query = '?';
  query += `limit=${rowsPerPage}`;
  query += `&offset=${page * rowsPerPage}`;
  query += `&search=${filterName}`;
  query += `&file_type=${'image'}`;
  query += `&media_type=${mediatype}`;
  query += `&ordering=${order == 'asc' ? '-' : ''}${orderBy2}`;
  query += `&is_private=${mediaprivate == 'public' ? false : mediaprivate == 'private' ? true : ''}`;
  query += '&';
  useEffect(() => {
    console.log('query', query);
    dispatch(getMedialist(query));
  }, [page, rowsPerPage, filterName, dispatch, orderBy, mediatype, setOpenDelete.open, orderBy2, order, mediaprivate]);

  useEffect(() => {
    console.log('media', media);
    // if (media?.results) {
    let m = media.results || [];
    setProductList(m);

    // }
  }, [media, media?.results]);

  const handleRequestSort = (property) => {
    setPage(0);
    console.log('property', property);
    setOrderBy(property);
  };
  const handlesortbyMPrivate = (rajeev) => {
    setPage(0);
    setMediaPrivate(rajeev);
  };
  const handleRequestSortheader = (property, q) => {
    const isAsc = orderBy2 === q && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setPage(0);
    setOrderBy2(q);
  };
  const handlesortbyMtype = (rajeev) => {
    setPage(0);
    setMediatype(rajeev);
  };

  const handleSelectAllClick = (checked) => {
    if (checked) {
      const selected = productList.map((n) => n.name);
      setSelected(selected);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    let a = parseInt(event.target.value, 10);
    setRowsPerPage(a);
    setPageLimit('medialist', a);
    setPage(0);
  };

  const handleFilterByName = (filterName) => {
    setPage(0);
    setFilterName(filterName);
  };

  // Vedio Thumbnail
  useEffect(() => {
    if (productList && productList.length > 0) {
      productList.forEach((v, i) => {
        var videoId, url;
        url = v.url;
        if (url && v.url != undefined) {
          if (url.match(/https?:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/)) {
            videoId = url.split('/')[3];
          } else if (url.match(/^vimeo.com\/channels\/[\d\w]+#[0-9]+/)) {
            videoId = url.split('#')[1];
          } else if (url.match(/vimeo.com\/groups\/[\d\w]+\/videos\/[0-9]+/)) {
            videoId = url.split('/')[4];
          } else if (url.match(/player.vimeo.com\/video\/[0-9]+/)) {
            videoId = url.split('/')[2];
          }

          getVimeoThumbnail(`https://vimeo.com/api/v2/video/${videoId}.json`).then((res) => {
            setThumbnail(new Map(thumbnail.set(url, res)));
          });
        }
      });
    }
  }, [productList]);
  const [thumbnail, setThumbnail] = useState(new Map());
  const handleDeleteProduct = async (rajeev) => {
    setDeleting(true);
    console.log(rajeev);
    let res = '';

    if (rajeev.file_type === 'image') {
      res = await dispatch(deleteImage(rajeev.id));
    }
    if (rajeev.file_type === 'video') {
      res = await dispatch(deleteVideo(rajeev.id));
    }
    if (rajeev.file_type === 'audio' || rajeev.file_type === 'document') {
      res = await dispatch(deleteDocument(rajeev.id));
    }

    if (res == 'success') {
      dispatch(getMedialist(query));
      setOpenDelete({ data: {}, open: false });
    } else {
      // console.log("image--", er);
      let er = res.response.data;
      enqueueSnackbar(er, { variant: 'error' });
      // console.log(res.response)
    }
    setDeleting(false);
  };

  const getVedioType = (url) => {
    var vedioType;
    if (url && url != undefined) {
      if (url.indexOf('youtube.com') > -1 || url.indexOf('youtu.be') > -1) {
        vedioType = 'youtube';
      } else if (url.indexOf('vimeo.com') > -1) {
        vedioType = 'vimeo';
      } else vedioType = 'others';
    }
    return vedioType;
  };
  const getVimeoThumbnail = async (apiUrl) => {
    let res = await axios({
      url: apiUrl,
      method: 'get',
      timeout: 8000,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return await res.data[0].thumbnail_medium;
    // setThumbnail(ress);
  };
  const getYouTubeThumbnail = (url) => {
    var matchResult, videoId;
    if (url && url != undefined) {
      if ((matchResult = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/))) {
        videoId = matchResult.pop();
      } else if ((matchResult = url.match(/youtu.be\/(.{11})/))) {
        videoId = matchResult.pop();
      }
      if (videoId) {
        return 'http://img.youtube.com/vi/' + videoId + '/0.jpg';
      } else {
        return DefaulPreview;
      }
    } else {
      return DefaulPreview;
    }
  };

  const getVedioThumbnail = (url) => {
    const vedioType = getVedioType(url);
    switch (vedioType) {
      case 'youtube':
        return getYouTubeThumbnail(url);
        break;
      case 'vimeo':
        return thumbnail.get(url);
        break;
      default:
        return DefaulPreview;
    }
  };
  //.........

  return (
    <RoleBasedGuard accessibleRoles={[1, 2]} noPermissionChildren={<></>}>
      <Card>
        <ParticularMediaToolBar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          // onDeleteProducts={() => handleDeleteProducts(selected)}
          addNewMedia={() => addmedia({})}
          sortMedia={(p) => handleRequestSort(p)}
          sortMediatype={(p) => handlesortbyMtype(p)}
          sortMediabyPrivate={(rajeev) => handlesortbyMPrivate(rajeev)}
          public_private={public_private}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 1000 }}>
            <Table>
              <ProductListHead
                order={order}
                orderBy={orderBy2}
                headLabel={TABLE_HEAD}
                rowCount={productList.length}
                numSelected={selected.length}
                onRequestSort={(p, q) => handleRequestSortheader(p, q)}
                onSelectAllClick={handleSelectAllClick}
              />

              <TableBody>
                {productList.map((row, ke) => {
                  const { id, name, cover, media_file = '', url, file_type, is_private } = row;

                  const isItemSelected = selected.indexOf(name) !== -1;

                  return (
                    <TableRow hover key={'ke' + id} tabIndex={-1} role="checkbox" selected={isItemSelected} aria-checked={isItemSelected}>
                      <TableCell padding="checkbox" onClick={() => selectedMedia(row)}>
                        {id}
                      </TableCell>
                      <TableCell align="" sx={{ ml: 1, mr: 1 }}>
                        <MediaViewer
                          file={row}
                          action={
                            file_type == 'video' ? (
                              <img
                                src={getVedioThumbnail(url)}
                                title={name}
                                style={{
                                  'max-height': '65px',
                                  'max-width': '65px'
                                }}
                              />
                            ) : file_type == 'image' ? (
                              <img
                                title={name}
                                className="thumbnail"
                                src={url || media_file}
                                style={{
                                  'max-height': '65px',
                                  'max-width': '65px'
                                }}
                              />
                            ) : (
                              <IconButton>
                                <Iconify icon={'et:document'} />
                              </IconButton>
                            )
                          }
                        />
                      </TableCell>
                      <TableCell onClick={() => selectedMedia(row)}>{name}</TableCell>
                      <TableCell onClick={() => selectedMedia(row)}>
                        <Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'} color={!is_private ? 'success' : 'info'}>
                          {is_private ? 'Restricted' : 'Public'}
                        </Label>
                      </TableCell>
                      <TableCell onClick={() => selectedMedia(row)}>
                        <Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'} color={'success'}>
                          {file_type ? sentenceCase(file_type) : ''}
                        </Label>
                      </TableCell>

                      <TableCell align="" sx={{ ml: 0, mr: 0 }}>
                        <ProductMoreMenu productName={name} onDelete={() => setOpenDelete({ data: row, open: true })} onEdit={() => addmedia(row)} />
                      </TableCell>
                    </TableRow>
                  );
                })}
                {media?.count == 0 && (
                  <TableRow style={{ height: 53 * 5 }}>
                    <TableCell align="center" colSpan={7} sx={{ py: 3, color: 'text.main' }}>
                      No Media Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={media?.count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, value) => setPage(value)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
      <Dialog fullWidth maxWidth="sm" open={openDelete.open} onClose={() => setOpenDelete({ data: {}, open: false })}>
        <DialogTitle sx={{ textAlign: 'center' }}>
          <Typography variant="h6">Delete Media</Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            textAlign: 'center',
            mt: 2
          }}
        >
          <Iconify width={140} height={140} color="error.main" icon="material-symbols:delete-outline" />
        </DialogContent>
        <DialogActions>
          <Box sx={{ flexGrow: 1 }} />

          <Button variant="outlined" color="inherit" onClick={() => setOpenDelete({ data: {}, open: false })}>
            Cancel
          </Button>

          <LoadingButton
            type="submit"
            variant="contained"
            color="error"
            loading={deleting}
            // loadingIndicator="Deleting..."
            onClick={() => handleDeleteProduct(openDelete.data)}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </RoleBasedGuard>
  );
}

// ----------------------------------------------------------------------
