import PropTypes from "prop-types";
import { useRef, useState, useEffect } from "react";
// @mui
import {
  Stack,
  OutlinedInput,
  MenuItem,
  IconButton,
  InputAdornment,
  DialogTitle,
  Typography,
  Box,
  Button,
  DialogActions,
  ClickAwayListener,
} from "@mui/material";
// components
import Iconify from "../../../components/Iconify";
import MenuPopover from "../../../components/MenuPopover";
import { DialogAnimate } from "src/components/animate";

// ----------------------------------------------------------------------

KanbanColumnToolBar.propTypes = {
  columnName: PropTypes.string,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default function KanbanColumnToolBar({
  columnName,
  onDelete,
  onUpdate,
  adddetail
}) {
  const renameRef = useRef(null);

  const [value, setValue] = useState(columnName);

  const [open, setOpen] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [rename, setRename] = useState(false);

  useEffect(() => {
    if (open) {
      if (renameRef.current) {
        renameRef.current.focus();
      }
    }
  }, [open]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);

  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleClickRename = () => {
    handleClose();
    setRename(true);
  };
  const handleClicktoadddetail = () => {
    handleClose();
    adddetail()
  };

  const handleChangeColumnName = (event) => {
    setRename(true)
    setValue(event.target.value);
  };

  const handleUpdateColumn = (event) => {
    // if (event.key === "Enter" && renameRef.current) {
    renameRef.current.blur();
    onUpdate(value);
    setRename(false);
    // }
  };
  const handleDelete = () => {
    onDelete();
    handleClose();
    setOpenModal(false);
  }
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        sx={{ pt: 0 }}
      >
        <ClickAwayListener onClickAway={handleUpdateColumn}>

          <OutlinedInput
            size="small"
            fullWidth
            placeholder="Section name"
            value={value}
            multiline
            onChange={handleChangeColumnName}
            // onKeyUp={handleUpdateColumn}
            inputRef={renameRef}
            endAdornment={
              rename && (
                <InputAdornment position="end">
                  <IconButton
                    // aria-label="toggle password visibility"
                    onClick={handleUpdateColumn}
                    onMouseDown={handleUpdateColumn}
                    edge="end"
                    color="primary"
                  >
                    <Iconify
                      icon={"eva:checkmark-circle-2-outline"}
                      width={25}
                      height={25}
                    />
                  </IconButton>
                </InputAdornment>
              )
            }
            sx={{
              typography: "h6",
              fontWeight: "fontWeightBold",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
            }}
          />
        </ClickAwayListener>

        <IconButton
          size="small"
          onClick={handleOpen}
          color={open ? "inherit" : "default"}
        >
          <Iconify icon={"eva:more-horizontal-fill"} width={25} height={25} />
        </IconButton>
      </Stack>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          width: "auto",
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        {/* <MenuItem onClick={handleClickRename}>
          <Iconify
            icon={"eva:edit-fill"}
            sx={{ width: 20, height: 20, flexShrink: 0, mr: 1 }}
          />
          Rename section
        </MenuItem> */}

        <MenuItem onClick={handleClicktoadddetail}>
          <Iconify
            icon={"eva:edit-fill"}
            sx={{ width: 20, height: 20, flexShrink: 0, mr: 1 }}
          />
          Update Details
        </MenuItem>
        <MenuItem onClick={() => setOpenModal(true)} sx={{ color: "error.main" }}>
          <Iconify
            icon={"eva:trash-2-outline"}
            sx={{ width: 20, height: 20, flexShrink: 0, mr: 1 }}
          />
          Delete section
        </MenuItem>



      </MenuPopover>
      <DialogAnimate open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>
          <Typography variant="h6">Delete Module</Typography>
        </DialogTitle>
        <DialogActions>
          <Box sx={{ flexGrow: 1 }} />

          <Button
            variant="outlined"
            color="inherit"
            onClick={() => setOpenModal(false)}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            variant="contained"
            loadingIndicator="Loading..."
            onClick={() => handleDelete()}
          >
            Delete
          </Button>
        </DialogActions>
      </DialogAnimate>
    </>
  );
}
