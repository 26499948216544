/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
// @mui
import { DateTimePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Iconify from 'src/components/Iconify';
import { contentOrdering } from 'src/redux/slices/courseupdate';
import { addQuiz, getQuiz, setQuiznull, updateQuiz } from 'src/redux/slices/lesson';
import { useDispatch, useSelector } from 'src/redux/store';
import MediaModal from 'src/sections/media/MediaModal';
// import { async } from "@firebase/util";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import LoadingScreen from 'src/components/LoadingScreen';
import { TimeFieldHHMMSS } from 'src/components/TimeField';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';
import { fNumber2decimal } from 'src/utils/formatNumber';
import QuizView from '../../course/courseView/contentView/QuizView';
import ClosewithoutSave from './component/ClosewithoutSave';
import ContentWeightage from './component/ContentWeightage';
import TextEditorForDescription from './component/TextEditorForDescription';

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 180,
  fontSize: 13,
  flexShrink: 0,
  color: theme.palette.text.secondary
}));

export default function Quiz({ data = {}, section, handleUpdate, index, courseid, accessibleRoles = [] }) {
  const dispatch = useDispatch();
  const { data: quizdata, isLoading: loading, update, error } = useSelector((state) => state.lesson.quiz);
  const [value, setValue] = useState({
    can_skip: true,
    can_proceed_without_marking: false,
    limited_attempt: false,
    attempts_allowed: null,
    start_datetime: null,
    time_allowed: null,
    max_score_after_attempts_exhausted: null
  });
  const [question, setQuestion] = useState([]);
  const [errors, setErrors] = useState({});
  const [qhasmcq, setqhasmcq] = useState(true);
  const [mcqalert, setMcqAlert] = useState(false);
  const [patch, setPatch] = useState(false);
  const [type, setType] = useState('mcq');
  const { enqueueSnackbar } = useSnackbar();
  const { openwithoutsave } = useSelector((state) => state.lesson);
  const [openQuestion, setOpenQuestion] = useState({
    open: false,
    index: null,
    data: null
  });
  // console.log("quizdata", quizdata);
  useEffect(() => {
    setErrors({ ...error });
  }, [error]);

  useEffect(() => {
    if (data.id) {
      dispatch(setQuiznull());

      dispatch(getQuiz(data.id));
    } else {
      dispatch(setQuiznull());
      //setValue({});
      setQuestion([]);
    }
  }, [data.id]);

  useEffect(() => {
    if (data.id) {
      let quizdata1 = {
        ...quizdata,
        max_score_after_attempts_exhausted: quizdata?.max_score_after_attempts_exhausted
          ? fNumber2decimal(quizdata?.max_score_after_attempts_exhausted)
          : null
      };
      setValue({ ...quizdata1 });
      setPatch(true);
      setqhasmcq(!!quizdata?.marks_per_question ? true : false);
      setQuestion(quizdata?.questions || []);
      if (+quizdata?.marks_per_question > 0) {
        setType('mcq');
      } else {
        setType('subjective');
      }
    }
  }, [quizdata]);

  const addQuestion = (a, i) => {
    // console.log("saveQuestion--------", a);
    let newQuestion = [...question];
    newQuestion.push(a);
    setQuestion(newQuestion);
    setValue({ ...value, questions: [...newQuestion] });
    setOpenQuestion({ open: false, index: null, data: null });
  };
  const updateQuestion = (q) => {
    setQuestion(q);
    setValue({ ...value, questions: [...q] });
  };
  const checkvalid = () => {
    // check quiz has question or not

    let sub = value.questions?.filter((a) => a.question_type === 'subjective')?.length || 0;
    let mcq = value.questions?.filter((a) => a.question_type === 'mcq')?.length || 0;
    let check = mcq > parseInt(value.display_questions) ? parseInt(value.display_questions) : mcq;
    let mark =
      value.questions
        ?.map((a) => (a.question_type === 'subjective' ? a.maximum_marks : 0))
        .reduce(function (x, y) {
          return parseInt(x || 0) + parseInt(y || 0);
        }, 0) +
      parseInt(check) * parseInt(value.marks_per_question || 0);
    if (value.time_allowed <= 0) {
      enqueueSnackbar('Time allowed field value should be greater than 0', {
        variant: 'error'
      });
      setErrors({
        ...errors,
        time_allowed: 'Time allowed field value should be greater than or equal to 1'
      });
      return false;
    }

    if (qhasmcq) {
      if (!value.marks_per_question) {
        enqueueSnackbar('Marks per question field is required', { variant: 'error' });
        setErrors({ marks_per_question: 'This field is required' });
        return false;
      }
      if (!value.display_questions) {
        enqueueSnackbar('Display Questions field is required', { variant: 'error' });

        setErrors({
          display_questions: 'This field is required'
        });
        return false;
      }
    }

    if (
      (value.max_score_after_attempts_exhausted <= 0 || value.max_score_after_attempts_exhausted > 100) &&
      value.max_score_after_attempts_exhausted != null &&
      value.max_score_after_attempts_exhausted != ''
    ) {
      enqueueSnackbar('Max Score After Attempts Exhausted field value should be greater than 0 and less than or equal to 100', {
        variant: 'error'
      });
      setErrors({
        ...errors,
        max_score_after_attempts_exhausted: ['Max Score After Attempts Exhausted field value should be greater than 0 and less than or equal to 100']
      });
      return false;
    }
    if (mark > value.total_marks) {
      enqueueSnackbar('Mark can not be greater than Total Marks', {
        variant: 'error'
      });
      return false;
    }
    if (mark < value.total_marks) {
      enqueueSnackbar('Mark can not be less than Total Marks, Please add MCQ or Subjective Question', {
        variant: 'error'
      });
      return false;
    }
    if (value.limited_attempt && value.attempts_allowed <= 0) {
      enqueueSnackbar('Attempts Allowed field value should be greater than 0', {
        variant: 'error'
      });

      return false;
    }

    return true;
  };

  const saveQuiz = async () => {
    let validation = checkvalid();
    // enqueueSnackbar(JSON.stringify(value), { variant: "error" });

    if (value?.questions?.length <= 0 || !value?.questions) {
      enqueueSnackbar('Please add atleast one question', {
        variant: 'error'
      });
      // setErrors({ ...errors, questions: "Please add atleast one question" });
      return;
    }
    if (!validation) {
      // enqueueSnackbar("error", { variant: "error" });
      return;
    }
    let res = '';
    let value2 = {
      ...value,
      transcript_weightage: !_.isEmpty(value.transcript_weightage) ? value.transcript_weightage.id : null,
      questions: value.questions.map((a) => ({
        ...a,
        attachment: a.attachment?.id || null
      })),
      max_score_after_attempts_exhausted: value?.max_score_after_attempts_exhausted ? +value?.max_score_after_attempts_exhausted : null,
      start_datetime: value.start_datetime ? value.start_datetime : null
    };
    // console.log("value---index--", value2, index);
    if (!value.id) {
      res = await dispatch(
        addQuiz(
          {
            ...value2,
            type: 'Quiz',
            index: index,
            section: section.id
          },
          section
        )
      );
      if (res.res == 'success') {
        let l = {
          id: courseid,
          section: [{ id: section.id, content: [...section?.content, res.data] }]
        };
        let r = await dispatch(contentOrdering(l));
      }
    } else {
      res = await dispatch(
        updateQuiz(
          {
            ...value2,
            type: 'Quiz',
            section: section.id
          },
          data.id
        )
      );
    }

    if (res === 'success' || res.res === 'success') {
      handleUpdate({ ...value, type: 'Quiz', section: section.id });
    }
  };
  const AlertforDelmcq = () => {
    const deleteAllmcq = () => {
      let q = value?.questions?.filter((a) => a.question_type === 'subjective') || [];
      setValue({
        ...value,
        questions: [...q],
        marks_per_question: null,
        display_questions: null
      });
      setqhasmcq(false);
      setType('subjective');
      setMcqAlert(false);
    };
    return (
      <Dialog open={true}>
        <DialogTitle>Alert</DialogTitle>
        <DialogContent>
          <DialogContentText>if you have any MCQ.it will be deleted</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setMcqAlert(false)}>
            Cancel
          </Button>
          <Button variant="contained" onClick={() => deleteAllmcq()}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleChangeDescription = (e) => {
    if (!!data.id) {
      if (!!value.description) {
        setValue({ ...value, description: e });
      }
    } else {
      setValue({ ...value, description: e });
    }
  };
  const handleWeightage = (weightage) => {
    setValue({ ...value, transcript_weightage: weightage });
  };

  if (loading && !value.id) {
    return <LoadingScreen />;
  }
  return (
    <RoleBasedGuard
      accessibleRoles={accessibleRoles}
      noPermissionChildren={
        <>
          <QuizView data={{ ...value, quizHasMCQ: qhasmcq }} />
        </>
      }
    >
      <InputField
        label="Title"
        value={value.name}
        setValue={(e) => setValue({ ...value, name: e })}
        error={errors.name ? errors.name : ''}
        helperText="The title of the quiz ."
        placeholder="Enter Title"
      />
      <Stack direction="row">
        <LabelStyle sx={{ mt: 2 }}>Quiz Description</LabelStyle>
        <div style={{ width: '100%' }}>
          <TextEditorForDescription
            value={value.description}
            setValue={handleChangeDescription}
            errors={errors.description ? errors.description : ''}
            helperText={errors.description}
          />
        </div>
      </Stack>

      <InputField
        label="Time Allowed"
        placeholder="please enter time in minutes"
        helperText="Time allowed in minutes ( Maxiumum time a student can take to complete the quiz)."
        type="number"
        value={value.time_allowed}
        error={errors.time_allowed ? errors.time_allowed : ''}
        setValue={(e) => setValue({ ...value, time_allowed: e || null })}
      />
      <Stack direction="row">
        <LabelStyle sx={{ mt: 1 }}>Number of Hours</LabelStyle>
        <TimeFieldHHMMSS
          value={value.time_allotment}
          name="time_allotment"
          error={errors.time_allotment}
          helperText={errors.time_allotment}
          handleValue={(e) => {
            setValue({ ...value, time_allotment: e });
          }}
        />
      </Stack>
      {/* limit no. of attempt */}
      <Stack direction="row">
        <LabelStyle sx={{ mt: 2 }}> Limit no. of attempts ?</LabelStyle>
        <Checkbox
          checked={!!value.limited_attempt}
          onChange={(e) =>
            setValue({
              ...value,
              limited_attempt: e.target.checked,
              attempts_allowed: e.target.value ? value.attempts_allowed : null,
              max_score_after_attempts_exhausted: e.target.checked ? 70 : null
            })
          }
          inputProps={{ 'aria-label': 'controlled' }}
          sx={{ mt: 1 }}
        />
        {(value.limited_attempt && (
          <TextField
            size="small"
            sx={{ mt: 2, fontSize: 12 }}
            type="number"
            InputProps={{ inputProps: { min: 1 } }}
            value={value.attempts_allowed}
            error={errors.attempts_allowed}
            helperText={errors.attempts_allowed || 'The number of attempts allowed for this quiz.'}
            onChange={(e) => setValue({ ...value, attempts_allowed: e.target.value })}
          />
        )) || (
          <Typography variant="body2" sx={{ mt: 2, fontSize: 12 }} color="text.secondary">
            Do you want to limit the number of attempts for the quiz?
          </Typography>
        )}
      </Stack>

      <Stack direction="row">
        <LabelStyle sx={{ mt: 2 }}>Quiz has Multiple Choice Questions ?</LabelStyle>
        {!patch && (
          <Checkbox
            checked={qhasmcq}
            onChange={(e) => {
              if (!e.target.checked) {
              }
              if (!e.target.checked) {
                setMcqAlert(true);
                return;
              }
              setqhasmcq(e.target.checked);
            }}
            inputProps={{ 'aria-label': 'controlled' }}
            sx={{ mt: 1 }}
          />
        )}
        <Typography variant="body2" sx={{ mt: 2, fontSize: 12 }} color="text.secondary">
          {qhasmcq ? 'Quiz has MCQ and subjective both.' : 'Quiz has only subjective.'}
        </Typography>
      </Stack>
      {qhasmcq && (
        <>
          <Stack direction="row">
            <LabelStyle sx={{ mt: 2 }}>Display Questions</LabelStyle>
            <TextField
              fullWidth
              multiline
              size="small"
              error={errors.display_questions ? true : false}
              helperText={
                errors.attempts_allowed?.join(',') ||
                'The number of mcq questions presented to student while attempting the quiz. Should be less than equal to the number of mcq questions added to the  quiz question bank.'
              }
              placeholder="Enter Display Questions"
              value={value.display_questions}
              onChange={(e) => setValue({ ...value, display_questions: e.target.value })}
              sx={{ typography: 'body2', mt: 1 }}
            />
          </Stack>
          <InputField
            label="Marks per MCQ"
            value={value.marks_per_question}
            helperText="Marks needed to each MCQ question."
            placeholder="Enter Marks for MCQ"
            type="number"
            error={errors.marks_per_question ? errors.marks_per_question : ''}
            setValue={(e) => setValue({ ...value, marks_per_question: e })}
          />
        </>
      )}
      <InputField
        label="Total Marks"
        value={value.total_marks}
        helperText="Total marks is needed"
        placeholder="Enter Marks"
        type="number"
        error={errors.total_marks ? errors.total_marks : ''}
        setValue={(e) => setValue({ ...value, total_marks: e })}
      />
      <InputField
        label="Passing Marks"
        value={value.passing_marks}
        helperText="Marks needed to pass the quiz."
        placeholder="Enter Passing Marks"
        error={errors.passing_marks ? errors.passing_marks : ''}
        setValue={(e) => setValue({ ...value, passing_marks: e })}
      />
      {value.limited_attempt && (
        <Stack direction="row">
          <LabelStyle sx={{ mt: 2 }}>Max score % after attempts are exhausted</LabelStyle>
          <TextField
            size="small"
            sx={{ mt: 2, fontSize: 12 }}
            type="number"
            value={value.max_score_after_attempts_exhausted}
            error={errors.max_score_after_attempts_exhausted}
            helperText={
              errors.max_score_after_attempts_exhausted ||
              'After the limit no. of attempts is exhausted and the student reattempts after instructor request for resubmit then this is maximum score % the student can get.'
            }
            inputProps={{
              min: 0,
              max: 100
            }}
            onChange={(e) => setValue({ ...value, max_score_after_attempts_exhausted: e.target.value })}
          />
        </Stack>
      )}
      <Stack direction="row">
        <LabelStyle sx={{ mt: 2 }}>Weightage Group in Grade</LabelStyle>
        <ContentWeightage data={value.transcript_weightage} handleSubmit={handleWeightage} type="quiz" />
      </Stack>

      <Stack direction="row">
        <LabelStyle sx={{ mt: 2 }}>Start Date</LabelStyle>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            value={value.start_datetime}
            onChange={(e) => setValue({ ...value, start_datetime: e })}
            // inputFormat="yyyy/MM/dd hh:mm a"
            // mask="___/__/__ __:__ _M"
            renderInput={(params) => (
              <TextField
                {...params}
                error={errors?.start_datetime || false}
                helperText={
                  errors?.start_datetime?.join(',') || (
                    <>
                      Select Date from when you want this content to be available for the students.
                      <br />
                      This can be left blank if you want this to be always available.
                    </>
                  )
                }
                fullWidth
                variant="outlined"
              />
            )}
          />
        </LocalizationProvider>
      </Stack>
      <Stack direction="row">
        <LabelStyle sx={{ mt: 2 }}>Can Skip</LabelStyle>
        <Checkbox
          defaultChecked={!!value?.can_skip}
          checked={!!value?.can_skip}
          onChange={(e) => setValue({ ...value, can_skip: e.target.checked })}
          inputProps={{ 'aria-label': 'controlled' }}
          sx={{ mt: 1 }}
        />
        <Stack>
          <Typography variant="body2" sx={{ mt: 2, fontSize: 12 }} color="text.secondary">
            Can student skip this content and proceed to next content?
          </Typography>
          <Typography variant="body2" sx={{ mt: 0, fontSize: 12 }} color="error.main">
            If this is selected/checked then this quiz score will not be included in the final grade for student
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row">
        <LabelStyle sx={{ mt: 2 }}>Is gated</LabelStyle>
        <Checkbox
          // defaultChecked
          checked={!!value.can_proceed_without_marking}
          disabled={!!value.can_skip}
          onChange={(e) =>
            setValue({
              ...value,
              can_proceed_without_marking: e.target.checked
            })
          }
          inputProps={{ 'aria-label': 'controlled' }}
          sx={{ mt: 1 }}
        />
        <Typography
          variant="body2"
          sx={{
            mt: 2,
            fontSize: 12,
            textDecoration: value.can_skip ? 'line-through' : ''
          }}
          color="text.secondary"
        >
          Can student proceed to next content without instructor marking the quiz .
        </Typography>
      </Stack>

      {errors.questions && (
        <Typography variant="body2" sx={{ mt: 1, fontSize: 12, ml: 1 }} color={'error.main'}>
          {errors.questions}
        </Typography>
      )}
      <DetailsView
        questions={value.questions}
        total_marks={value.total_marks}
        marks_per_question={value.marks_per_question}
        display_questions={value.display_questions}
      />

      <Stack direction="row">
        <LabelStyle sx={{ mt: 2 }}>Note</LabelStyle>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 2, fontSize: 12 }}>
          Current Total=( Marks per MCQ X Display Questions ) + ( All Marks by Subjective )
        </Typography>
      </Stack>
      <ToggleButtonGroup
        color="primary"
        size="medium"
        fullWidth
        value={type}
        exclusive
        onChange={(e, v) => {
          if (v) {
            setType(v);
          }
        }}
      >
        {patch
          ? +value.marks_per_question > 0 && <ToggleButton value="mcq">MCQ</ToggleButton>
          : qhasmcq && <ToggleButton value="mcq">MCQ</ToggleButton>}
        <ToggleButton value="subjective">Subjective</ToggleButton>
      </ToggleButtonGroup>
      <QuestionView type={type} question={question} saveQuestions={(q) => updateQuestion(q)} marks_per_question={value.marks_per_question} />

      <Stack direction="row" justifyContent="center">
        <Button variant="outlined" color="primary" onClick={() => setOpenQuestion({ open: true, index: null, data: null })}>
          Add Question
        </Button>
      </Stack>
      <Stack direction="row" justifyContent="flex-start">
        <LoadingButton variant="contained" onClick={() => saveQuiz()} sx={{ ml: 2, mt: 2 }} loading={loading} disabled={loading}>
          Save Content
        </LoadingButton>
      </Stack>
      {openQuestion.open && (
        <AddandEditQuestion
          data={openQuestion}
          handleClose={() => setOpenQuestion({ open: false, index: null, data: null })}
          type={type}
          handleSubmit={(q) => addQuestion(q, openQuestion.index)}
        />
      )}
      {mcqalert && <AlertforDelmcq />}
      {openwithoutsave && (
        <ClosewithoutSave
          oncancel={() => handleUpdate()}
          onok={() => {
            saveQuiz();
          }}
        />
      )}
    </RoleBasedGuard>
  );
}

const AddandEditQuestion = ({ data, handleClose, handleSubmit, type }) => {
  // const [value, setValue] = useState({});
  return (
    <Dialog
      open={data.open}
      // open={true}
      fullWidth
      maxWidth="md"
      onClose={() => handleClose()}
    >
      <DialogTitle id="alert-dialog-title">
        {data.index !== null ? 'Edit Question' : 'Add Question'}
        <IconButton
          aria-label="close"
          onClick={() => handleClose()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <Iconify icon={'ant-design:close-circle-o'} width={24} height={24} />
        </IconButton>
      </DialogTitle>

      <Question question={data.data || { question_type: type }} saveQuestion={handleSubmit} handleClose={handleClose} />
    </Dialog>
  );
};

const InputField = ({ label, value, setValue, error = '', placeholder = '', helperText = '', type = 'text', name = '' }) => {
  // console.log("error--", error);
  return (
    <Stack direction="row">
      <LabelStyle sx={{ mt: 2 }}>{label}</LabelStyle>
      <TextField
        fullWidth
        rows={1}
        InputProps={{ inputProps: { min: type == 'number' ? 0 : '' } }}
        size="small"
        type={type}
        placeholder={placeholder}
        value={value}
        name={name}
        error={error ? true : false}
        helperText={error || helperText}
        onChange={(e) => setValue(e.target.value)}
        sx={{ typography: 'body2', mt: 1 }}
      />
    </Stack>
  );
};

const QuestionView = ({ question, saveQuestions, marks_per_question, type }) => {
  // const { name, options } = data;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [openQuestion, setOpenQuestion] = useState({
    open: false,
    index: null,
    data: null
  });
  const handleDelete = async (i, id) => {
    let newQuestion = [...question];
    newQuestion.splice(i, 1);
    saveQuestions(newQuestion);
    enqueueSnackbar('Removed', { variant: 'error' });
    enqueueSnackbar('To see the changes, please click "Save Content"');
  };

  const saveQuestion = (a, i) => {
    let newQuestion = [...question];
    newQuestion[i] = a;
    saveQuestions(newQuestion);
    setOpenQuestion({ open: false, index: null, data: null });
  };
  return (
    <>
      {/* {question?.filter(a => a.question_type == type)?.map((q, i) => ( */}
      {question?.map((q, i) => {
        if (q.question_type != type) {
          return <></>;
        }

        return (
          <div key={'question' + i}>
            <Divider />
            <Stack direction="row" justifyContent="flex-end">
              <IconButton size="small" onClick={() => setOpenQuestion({ open: true, index: i, data: q })}>
                <Iconify
                  sx={{ color: 'info.main' }}
                  // color='info'
                  icon={'ant-design:edit-outlined'}
                  width={24}
                  height={24}
                />
              </IconButton>
              <IconButton sx={{ color: 'error.main' }} size="small" onClick={() => handleDelete(i, q?.id)}>
                <Iconify icon={'eva:trash-2-outline'} width={24} height={24} />
              </IconButton>
            </Stack>
            <Stack direction="row" justifyContent={'space-between'}>
              <LabelStyle sx={{ mt: 0 }}>{i + 1}. Question </LabelStyle>
              <LabelStyle sx={{ mt: 0, flexGrow: 1 }}>{q.name}</LabelStyle>
              <LabelStyle sx={{ whiteSpace: 'pre-line' }}>
                Type : {q.question_type == 'mcq' ? 'MCQ' : 'Subjective'}
                <br /> Mark : {q.maximum_marks || marks_per_question}
              </LabelStyle>
            </Stack>
            {q.options.map((item, index) => (
              <Stack direction="row" key={index} sx={{ mt: 2 }}>
                <Iconify
                  icon={item.is_correct ? 'ic:round-check' : 'ic:round-arrow-forward'}
                  sx={{ mb: 0, mr: 1 }}
                  width={20}
                  height={20}
                  color={item.is_correct ? 'primary.main' : ''}
                />
                <LabelStyle sx={{ color: item.is_correct ? 'primary.main' : '' }}>{String.fromCharCode(index + 65)} . Answer</LabelStyle>
                <LabelStyle>{item.name}</LabelStyle>
              </Stack>
            ))}
          </div>
        );
      })}
      {openQuestion.open && (
        <AddandEditQuestion
          data={openQuestion}
          handleClose={() => setOpenQuestion({ open: false, index: null, data: null })}
          handleSubmit={(q) => saveQuestion(q, openQuestion.index)}
          type={openQuestion.data.question_type}
        />
      )}
    </>
  );
};

const Question = ({ question: q2, saveQuestion, handleClose }) => {
  const { name = '', options = [], attachment = null, question_type = 'mcq', maximum_marks = '' } = q2;
  const [question, setQuestion] = useState(name);
  const [media, setMedia] = useState(attachment);
  const [option, setOption] = useState([...options]);
  const [maximum_mark, setMaximum_mark] = useState(maximum_marks);
  const [errors, setErrors] = useState({});
  // console.log("question", media);
  const setAnswer = (value, i) => {
    let newOption = [...option];
    newOption[i] = { ...newOption[i], is_correct: value };
    setOption(newOption);
  };

  const setValueOption = (value, i) => {
    let newOption = [...option];
    newOption[i] = { ...newOption[i], name: value };
    setOption(newOption);
  };

  const deleteOption = (i) => {
    setOption((prev) => prev.filter((_, index) => _.name !== i.name));
  };

  const submit = () => {
    let p = {
      ...q2,
      name: question,
      options: option,
      attachment: media,
      question_type: question_type,
      maximum_marks: maximum_mark
    };

    let op_testing = option.find((a) => a.is_correct == true);
    let op_testing2 = option.find((a) => a.name.length < 1);

    if (question.length < 1) {
      setErrors({
        question: 'Question is required',
        option: 'Question is required'
      });
      return;
    }
    if (question_type === 'mcq') {
      if (option.length < 2) {
        setErrors({
          option: 'At least two options are required'
        });
        return;
      }
      if (!op_testing) {
        setErrors({
          option: 'At least one option have right answer.'
        });
        return;
      }
      if (op_testing2) {
        setErrors({
          option: 'Option must have text.'
        });
        return;
      }
      p.maximum_marks = null;
    }
    if (question_type === 'subjective') {
      p.options = [];
      if (maximum_mark <= 0) {
        setErrors({ maximum_marks: 'Maximum marks is required' });
        return;
      }
    }

    saveQuestion(p);
  };
  const addingMedia = (media) => {
    setMedia(media);
  };
  const handlequestion = (e) => {
    setQuestion(e.target.value);
  };
  const handleaddoption = () => {
    setOption([...option, { name: '', is_correct: false }]);
  };
  return (
    <>
      <DialogContent>
        <Stack direction="row" sx={{ mt: 2 }} justifyContent={'center'}>
          {/* <LabelStyle sx={{ mt: 2, fontWeight: "bold" }}></LabelStyle> */}
        </Stack>
        <Stack direction="row">
          <LabelStyle sx={{ mt: 2, fontWeight: 'bold' }}>Question</LabelStyle>
          <Stack direction="column" sx={{ flexGrow: 1 }}>
            <TextField
              fullWidth
              multiline
              rows={3}
              error={errors?.question || question?.length > 500 ? true : false}
              helperText={errors?.question || question?.length > 500 ? 'Maximum 500 characters' : null}
              size="small"
              placeholder="Question"
              inputProps={{ maxLength: 500 }}
              value={question}
              onChange={handlequestion}
              sx={{ typography: 'body2', mt: 1 }}
            />
            {/* <Typography
              sx={{
                color: "text.secondary",
                fontStyle: "italic",
                fontSize: 15,
                textAlign: "right",
              }}
            >
              <b>{1200 - (question?.length || 0)} characters </b> left
            </Typography> */}
          </Stack>
        </Stack>
        <Stack direction="row" sx={{ mt: 2, mb: 2 }}>
          <LabelStyle sx={{ mt: 2 }}>Attachment</LabelStyle>
          <Stack direction="column">
            <MediaModal media={media?.id || null} addingMedia={addingMedia} />
            <Typography variant="" sx={{ mt: 1, fontSize: 12, ml: 1 }}>
              Select only image
            </Typography>
          </Stack>
        </Stack>

        {option.map((option, index) => (
          <OptionList
            label={`Option ${index + 1}.`}
            key={'op' + index}
            data={option}
            setValue={(v) => setValueOption(v, index)}
            deleteOption={() => deleteOption(option)}
            setAnswer={(a) => setAnswer(a, index)}
          />
        ))}

        {question_type !== 'mcq' && (
          <Stack direction="row" sx={{ mt: 2 }}>
            {/* <LabelStyle sx={{ mt: 2, fontWeight: "bold" }}>Enter Mark</LabelStyle> */}
            <InputField
              label="Enter Maximum Marks"
              fullWidth
              size="small"
              placeholder="Enter Marks"
              value={maximum_mark}
              type="number"
              setValue={setMaximum_mark}
              error={errors.maximum_mark ? errors.maximum_mark : ''}
              helperText={errors.maximum_mark || 'Enter Marks for this question'}
            />
          </Stack>
        )}
        <Stack direction="row" sx={{ mt: 2, mb: 2, color: 'error.main' }}>
          <LabelStyle sx={{ mt: 2 }} />

          {errors.option || (errors.maximum_marks && <>{errors.option || errors.maximum_marks}</>)}
        </Stack>
        {question_type == 'mcq' && (
          <Stack direction="row" sx={{ mt: 2 }} justifyContent="center">
            <Button variant="outlined" onClick={handleaddoption}>
              Add Option
            </Button>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={submit}>
          Submit
        </Button>
        <Button variant="outlined" onClick={() => handleClose()} sx={{ ml: 2, mr: 2 }}>
          Cancel
        </Button>
      </DialogActions>
    </>
  );
};

const OptionList = ({ label, deleteOption, setAnswer, data, setValue }) => {
  return (
    <Stack direction="row" sx={{ mt: 2 }} alignItems={'flex-start'} alignContent={'flex-start'}>
      <LabelStyle sx={{ mt: 1 }}>{label}</LabelStyle>
      <Stack direction="column" sx={{ flexGrow: 1 }}>
        <TextField
          fullWidth
          rows={2}
          multiline
          size="small"
          placeholder="enter option"
          inputProps={{ maxLength: 200 }}
          error={data?.name?.length == 200 ? true : false}
          helperText={data?.name?.length == 200 ? 'Maximum 200 characters' : null}
          value={data.name}
          onChange={(e) => setValue(e.target.value)}
          sx={{ typography: 'body2', mt: 0 }}
        />
        {/* <Typography
          sx={{
            color: "text.secondary",
            fontStyle: "italic",
            fontSize: 12,
            textAlign: "right",
          }}
        >
          <b>{500 - (data?.name?.length || 0)} characters </b> left
        </Typography> */}
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-end"
        // flexGrow={1}
        sx={{ ml: 1 }}
      >
        <Box sx={{ display: 'flex' }}>
          <IconButton size="small" onClick={() => setAnswer(true)} sx={{ mr: 0 }}>
            <Iconify icon={'mi:circle-check'} color={data.is_correct ? 'primary.main' : 'disabled'} width={24} height={24} />
          </IconButton>
          <IconButton size="small" onClick={() => setAnswer(false)}>
            <Iconify icon={'mi:circle-error'} color={!data.is_correct ? 'primary.main' : 'disabled'} width={24} height={24} />
          </IconButton>
          <IconButton size="small" onClick={deleteOption}>
            <Iconify icon={'mi:delete'} width={24} height={24} />
          </IconButton>
        </Box>
      </Stack>
    </Stack>
  );
};

const DetailsView = ({ questions, marks_per_question, display_questions = '', total_marks }) => {
  const [que, setQue] = useState(questions);
  useEffect(() => {
    setQue(questions);
  }, [questions]);

  let sub = que?.filter((a) => a.question_type === 'subjective')?.length || 0;
  console.log(sub);
  let mcq = que?.filter((a) => a.question_type === 'mcq')?.length || 0;
  console.log(mcq);

  let check = mcq > parseInt(display_questions) ? parseInt(display_questions) : mcq;
  // console.log(check);

  let mark =
    questions
      ?.map((a) => (a.question_type === 'subjective' ? a.maximum_marks : 0))
      .reduce(function (x, y) {
        return parseInt(x || 0) + parseInt(y || 0);
      }, 0) +
    parseInt(check) * parseInt(marks_per_question || 0);
  // console.log(mark, parseInt(marks_per_question || 0));

  return (
    <Stack direction="row" justifyContent={'space-between'}>
      <LabelStyle sx={{ mt: 2 }}>Current Total:</LabelStyle>
      <Typography variant="body2" sx={{ mt: 2, fontSize: 14, flexGrow: 1 }} color={mark != total_marks ? 'error.main' : 'success.main'}>
        {`${mark || 0} / ${total_marks || 0}`}
      </Typography>
      <LabelStyle sx={{ mt: 2, whiteSpace: 'pre-line' }}>
        No. of MCQ : {mcq} <br />
        No. of Subjective : {sub}
      </LabelStyle>
    </Stack>
  );
};
