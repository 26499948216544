import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Typography,
  ToggleButton,
  useTheme,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  alpha,
  Tooltip,
  FormLabel,
  IconButton,
  DialogContentText,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { eduleyApi } from "src/api/apiConfig";
import { SCOREWEIGHTAGE } from "src/apiUrl";
import Avatar from "src/components/Avatar";

import Iconify from "src/components/Iconify";
import IconifyButton from "src/components/IconifyButton";
import Label from "src/components/Label";
import LoadingIcon from "src/components/LoadingIcon";
import Scrollbar from "src/components/Scrollbar";
import { IconButtonAnimate } from "src/components/animate";
import useAuth from "src/hooks/useAuth";
import useResponsive from "src/hooks/useResponsive";
import AlertDialog from "src/pages/AlertDialog";
import { changeconfiguration, getCourse } from "src/redux/slices/courseupdate";
import { useDispatch, useSelector } from "src/redux/store";

const CustomScoreSetup = ({
  a,
  weightage,
  total_score,
  setTotalscore,
  setWeightage,
  handleDelete,
}) => {
  const {
    addcourse: { firstform },
  } = useSelector((state) => state.courseupdate);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const validate = (total_score, weightage) => {
    let error = {};

    return error;
  };
  const handleDeleteAlert = () => {
    // on Live course show conformation
    if (firstform.status == "live") {
      setOpenDeleteAlert(!openDeleteAlert);
    } else {
      handleDeleteByAlert();
    }
  };
  const handleDeleteByAlert = () => {
    setOpenDeleteAlert(false);
    handleDelete();
  };
  const error = useMemo(
    () => validate(total_score, weightage),
    [total_score, weightage]
  );
  const Imessage = ({ name }) => (
    <Tooltip
      title={
        <>
          Enter the total score for this custom score to assign students their
          corresponding marks.
          <br />
          If you have multiple custom score for the same type, it is advisable
          to create separate ones for each. For instance, <b>{name}</b> - Round
          1, <b>{name}</b> - Round 2, and so forth. You are free to choose the
          names as you see fit.
        </>
      }
      describeChild
      arrow
    >
      <IconButton
        style={{
          position: "absolute",
          left: 95,
          top: -10,
          zIndex: 99999,
        }}
      >
        <Iconify
          width={20}
          height={20}
          icon="ant-design:exclamation-circle-twotone"
        />
      </IconButton>
    </Tooltip>
  );
  return (
    <>
      <Stack
        direction={"column"}
        // spacing={3}
        sx={{ mb: 5, width: { xs: "100%" }, mt: 5 }}
        // justifyContent={"space-between"}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          sx={{ width: 1 }}
          justifyContent={"flex-start"}
        >
          <Stack
            direction={"row"}
            spacing={1}
            sx={{ width: 1, mb: { xs: 3, sm: 0 } }}
          >
            <Iconify sx={{ mt: 0.5 }} icon="mingcute:arrow-right-fill" />
            <Typography>{a.institute_transcript_weightage.name}</Typography>
          </Stack>
          <Stack sx={{ width: 1, mb: { xs: 3, sm: 0 } }}>
            {!(
              a.institute_transcript_weightage.key ||
              a.calculated_or_manual == "calculated"
            ) && (
              <>
                <Box
                  sx={{
                    position: "relative",
                    mt: -2,
                  }}
                >
                  <Imessage name={a.institute_transcript_weightage.name} />

                  <TextField
                    // sx={{ mt: -2 }}
                    label={"Enter total score"}
                    value={total_score}
                    variant={"standard"}
                    type="number"
                    helperText={error?.total_score}
                    error={error?.total_score}
                    onChange={(e) => setTotalscore(parseFloat(e.target.value))}
                  />
                </Box>
              </>
            )}
          </Stack>
          <Stack direction={"row"} sx={{ width: 1 }}>
            <TextField
              sx={{ mt: -2, ml: 1 }}
              value={weightage}
              label={"Enter weightage "}
              type="number"
              disabled={!a.can_edit_weightage}
              variant={"standard"}
              helperText={error?.weightage}
              error={error?.weightage}
              onChange={(e) => setWeightage(parseFloat(e.target.value))}
            />

            <Box>
              {!(
                a.institute_transcript_weightage.key ||
                a.calculated_or_manual == "calculated"
              ) && (
                <IconifyButton
                  onClick={handleDeleteAlert}
                  sx={{ mt: -1 }}
                  icon={"material-symbols:delete-outline"}
                />
              )}
            </Box>
          </Stack>
        </Stack>
        <Typography color="warning.dark" sx={{ fontSize: 12 }}>
          {a.note}
        </Typography>
      </Stack>
      <Dialog
        open={openDeleteAlert}
        onClose={() => setOpenDeleteAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>

        <DialogContent>
          <DialogContentText sx={{ mt: 2 }} id="alert-dialog-description">
            Deleting this custom score will remove it from the live courses it
            is being used on. Are you sure you want to delete this?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleDeleteAlert}>
            Cancel
          </Button>
          <Button
            startIcon={<Iconify icon="material-symbols:delete-outline" />}
            variant="contained"
            color="error"
            onClick={handleDeleteByAlert}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default CustomScoreSetup;
