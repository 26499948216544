// ----------------------------------------------------------------------
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
// form
// @mui
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
import {
  Container,
  Grid,
  Card,
  Stack,
  Button,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  FormControl,
  OutlinedInput,
  Switch,
  Checkbox,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from "@mui/material";
// routes
import useSettings from "../../../../hooks/useSettings";

// import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Page from "../../../../components/Page";
import HeaderBreadcrumbs from "../../../../components/HeaderBreadcrumbs";
import { FormProvider, RHFSwitch } from "../../../../components/hook-form";
import {
  changesection,
  getInstructorList,
  updatepublish,
} from "src/redux/slices/courseupdate";
import Imgwelcome from "src/assets/images/publih2.png";

import { useDispatch, useSelector } from "src/redux/store";
import Image from "src/components/Image";
import Iconify from "src/components/Iconify";
import { eduleyApi } from "src/api/apiConfig";
import { CREATE_COURSE } from "src/apiUrl";
import MultipleErrorModal from "src/components/MulipleErrorModal";
import { IconButtonAnimate } from "src/components/animate";
import DeleteModal from "src/components/DeleteModal";
import RoleBasedGuard from "src/guards/RoleBasedGuard";
import PublishView from "../courseView/PublishView";
//
// import BlogNewPostPreview from './BlogNewPostPreview';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(0, 0, 0, 0),
}));

const StyledFormControlLabel = styled((props) => (
  <FormControlLabel {...props} />
))(({ theme, value }) => ({
  ".MuiFormControlLabel-label": {
    color: [
      value == "draft"
        ? theme.palette.warning.main
        : value == "live"
          ? theme.palette.primary.main
          : theme.palette.error.main,
    ],
  },
}));

// ----------------------------------------------------------------------

export default function PriceOfCourse({ accessibleRoles = [] }) {
  const navigate = useNavigate();
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const { addcourse, instructorlist: ilist = [] } = useSelector(
    (state) => state.courseupdate
  );
  const [cohortAlert, setCohortAlert] = useState(false);
  const [values, setValues] = useState({});
  const [error, setError] = useState([]);
  // const [instructorlist, setInstructorlist] = useState(ilist);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [openerror, setOpenError] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const handleBack = () => {
    dispatch(changesection(3));
  };
  useEffect(() => {
    console.log("currency_list", addcourse.firstform);
    setValues(addcourse.firstform);
  }, [addcourse.firstform]);

  useEffect(() => {
    dispatch(getInstructorList());
  }, []);

  const onSubmit = async () => {
    updatepublish({
      ...addcourse.firstform,
      ...values,
    });
  };

  const deleteCourse = async (p) => {
    setDeleting(true);
    try {
      const response = await eduleyApi.delete(`${CREATE_COURSE}${p.id}/`);
      enqueueSnackbar("Deleted successfully");
      navigate("/program", {});
    } catch (error) {
      enqueueSnackbar(error?.data?.data?.non_field_errors, {
        variant: "error",
      });
      // setOpenError(true);
      // console.log("error---", error);
    }
    setDeleting(false);
  };

  const updatepublish = async (p) => {
    setLoading(true);

    try {
      const response = await eduleyApi.patch(`${CREATE_COURSE}${p.id}/`, p);
      console.log("publish----", response.data);
      enqueueSnackbar(" Updated successfully", {
        variant: "success",
      });
      navigate("/program", {});
    } catch (e) {
      let res = e;
      setError(res?.data?.data?.non_field_errors);
      setOpenError(true);
    }
    setLoading(false);
  };

  return (
    <Page title="Program: Media">
      <RoleBasedGuard accessibleRoles={accessibleRoles} noPermissionChildren={<PublishView />}>
        <Container maxWidth={themeStretch ? false : "lg"}>
          <HeaderBreadcrumbs heading="Publishing Program" links={[{ name: "" }]} />
          <Card sx={{ pt: 2, pb: 5 }}>
            <Stack spacing={3} direction="row" justifyContent={"space-around"}>
              <Stack
                md={6}
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent: "center",
                  width: "100%",
                  // alignItems: "center",
                }}
                spacing={3}
              >
                {/* <Typography>{values.status}</Typography> */}
                <Stack
                  spacing={3}
                  direction="row"
                  justifyContent={"space-between"}
                >
                  <Typography sx={{ mt: 1 }}>Publish</Typography>
                  <ToggleButtonGroup
                    color="primary"
                    value={values.status}
                    exclusive
                    onChange={(e, v) => {
                      setValues({ ...values, status: v });
                    }}
                  >
                    <ToggleButton value="live">Live</ToggleButton>
                    <ToggleButton value="draft">Draft</ToggleButton>
                    <ToggleButton value="archive">Archive</ToggleButton>
                  </ToggleButtonGroup>

                </Stack>


                <Stack
                  spacing={3}
                  direction="row"
                  justifyContent={"space-between"}
                >
                  <Button
                    sx={{ pt: 1 }}
                    color="primary"
                    endIcon={
                      <Tooltip
                        title={`Transcripted programs require atleast one exam to
                          complete.`}
                        arrow
                      >
                        <IconButton>
                          <Iconify
                            icon={"carbon:information"}
                            width={24}
                            height={24}
                          />
                        </IconButton>
                      </Tooltip>
                    }
                  >
                    Transcripted Program
                  </Button>

                  <ToggleButtonGroup
                    color="primary"
                    value={values.is_transcript ? "true" : "false"}
                    exclusive
                    onChange={(e, v) => {
                      setValues({
                        ...values,
                        is_transcript: !values.is_transcript,
                      });
                    }}
                  >
                    <ToggleButton value="true">Yes</ToggleButton>
                    <ToggleButton value="false">No</ToggleButton>
                  </ToggleButtonGroup>
                </Stack>
                <Stack>
                  <TextField
                    label={"Update Your Program Slug"}
                    onChange={(e) =>
                      setValues({ ...values, slug: e.target.value })
                    }
                    value={values.slug}
                  />
                </Stack>
                <Typography variant="h6" gutterBottom>
                  {addcourse.firstform.status === "draft"
                    ? "Your Program is not Published yet."
                    : addcourse.firstform.status === "live"
                      ? "Your Program is Published."
                      : "Your Program is Internal."}
                </Typography>
                {/* <Stack
                direction="row"
                justifyContent={"center"}
                spacing={1.5}
                sx={{ mt: 3 }}
              >
                <LoadingButton
                  //   fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  // loading={isSubmitting}
                  sx={{
                    ml: 5,
                    mr: 5,
                  }}
                  onClick={() => onSubmit()}
                >
                  Save
                </LoadingButton>
              </Stack> */}
              </Stack>
              <Stack md={6}>
                <SectionStyle>
                  <Typography variant="h3" sx={{ px: 5, mt: 5, mb: 5 }}>
                    Publish Program
                  </Typography>
                  <Image alt="login" src={Imgwelcome} />
                </SectionStyle>
              </Stack>
            </Stack>
          </Card>
        </Container>
        <Stack sx={{ mt: 3 }} direction="row" justifyContent="space-between">
          <Stack item xs={12} md={3}>
            <Button variant="contained" onClick={() => handleBack()} size="large">
              Back
            </Button>
          </Stack>
          <Stack direction={"row"} spacing={3} item xs={12} md={3}>
            <DeleteModal
              handledelete={() => deleteCourse(addcourse.firstform)}
              details="Are you sure you want to delete this program ?"
              action={
                <LoadingButton
                  endIcon={
                    <IconButtonAnimate>
                      <Iconify
                        color="error.main"
                        icon="material-symbols:delete-outline"
                      />
                    </IconButtonAnimate>
                  }
                  variant="outlined"
                  color="error"
                  loading={deleting}
                  // onClick={() => onSubmit()}
                  size="large"
                >
                  Delete {values.name}
                </LoadingButton>
              }
            />

            <LoadingButton
              loading={loading}
              variant="contained"
              onClick={() => onSubmit()}
              size="large"
            >
              Save
            </LoadingButton>
          </Stack>

        </Stack>

        <MultipleErrorModal
          title="Please fix the following to proceed!"
          open={openerror}
          error={error}
          onclose={() => {
            setOpenError(false);
            setError(null);
          }}
        />
      </RoleBasedGuard>
    </Page >
  );
}
