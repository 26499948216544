import PropTypes from "prop-types";
import { useState, useRef, useEffect } from "react";
// @mui
import { LoadingButton, MobileDateRangePicker } from "@mui/lab";
import { styled } from "@mui/material/styles";
import {
    Box,
    Stack,
    Drawer,
    Button,
    Tooltip,
    Divider,
    Typography,
    OutlinedInput,
    Container,
    RadioGroup,
    FormControlLabel,
    Radio,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
} from "@mui/material";
import { Assignments, Lecture, Quiz } from "src/sections/@dashboard/addcurriculum/addlesson";

const ContentView = ({ data,
    isOpen,
    onClose,
    onDeleteTask,
    saveLesson,
    index = 1,
    section = {},
    courseId, }) => {
    const { content_type: lessonType } = data
    return (
        <Drawer
            open={isOpen}
            onClose={onClose}
            anchor="right"
            PaperProps={{ sx: { width: { xs: 1, sm: 800 } } }}
        >
            <Stack p={2.5} direction="row" alignItems="center">
                {"lesson" === lessonType && (
                    <Lecture
                        courseid={courseId}
                        data={data}
                        section={section}
                        handleUpdate={() => { }}
                        index={index}
                        handleCancel={onClose}
                    />
                )}
                {"quiz" === lessonType && (
                    <Quiz
                        courseid={courseId}
                        data={data}
                        section={section}
                        index={index}
                        handleUpdate={() => { }}
                        handleCancel={onClose}
                    />
                )}
                {"quiz" != lessonType && lessonType != "lesson" && lessonType && (
                    <Assignments
                        data={data}
                        courseid={courseId}
                        index={index}
                        section={section}
                        handleUpdate={() => { }}
                        handleCancel={onClose}
                    />
                )}
            </Stack>

        </Drawer>
    )
}

export default ContentView