import dayjs from 'dayjs';

export const tableStyle = {
  '& .MuiTableCell-head': {
    boxShadow: 'none !important',
    '&:first-of-type': {
      borderRadius: 0
    },
    '&:last-of-type': {
      borderRadius: 0
    }
  }
};

export const WEEK_DAYS = [
  {
    name: 'Sunday',
    id: 1
  },
  {
    name: 'Monday',
    id: 2
  },
  {
    name: 'Tuesday',
    id: 3
  },
  {
    name: 'Wednesday',
    id: 4
  },

  {
    name: 'Thursday',
    id: 5
  },
  {
    name: 'Friday',
    id: 6
  },
  {
    name: 'Saturday',
    id: 7
  }
];

export const DATE_RANGE = [
  {
    id: 1,
    name: 'Last 7 days'
  },
  {
    id: 2,
    name: 'Last 30 days'
  },
  {
    id: 3,
    name: 'This Month'
  },
  {
    id: 4,
    name: 'Last Month'
  },
  {
    id: 5,
    name: 'Custom Range'
  }
];

export function getDocumentStatus(status, returnType) {
  const isText = returnType === 'text';
  switch (status) {
    case 'pending_upload':
      return isText ? 'Pending Upload' : 'warning';

    case 'awaiting_review':
      return isText ? 'Awaiting Review' : 'info';

    case 'approved':
      return isText ? 'Approved' : 'success';

    case 'cancelled':
      return isText ? 'Cancelled' : 'error';

    default:
      return isText ? 'N/A' : 'default';
  }
}
export default function darkenColor(color, factor = 0.8) {
  // Ensure the factor is within the valid range (0 to 1)
  factor = Math.max(0, Math.min(1, factor));

  // If the color is in hexadecimal format, convert it to RGB
  if (color[0] === '#') {
    color = color.slice(1);
    const hexToRgb = (hex) => parseInt(hex, 16);
    const r = hexToRgb(color.substring(0, 2));
    const g = hexToRgb(color.substring(2, 4));
    const b = hexToRgb(color.substring(4, 6));

    // Darken the color
    const darkenedR = Math.round(r * factor);
    const darkenedG = Math.round(g * factor);
    const darkenedB = Math.round(b * factor);

    // Convert back to hexadecimal
    const componentToHex = (c) => {
      const hex = c.toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    };

    return `#${componentToHex(darkenedR)}${componentToHex(darkenedG)}${componentToHex(darkenedB)}`;
  }

  // If the color is in RGB format, darken it and convert to hexadecimal
  if (color.startsWith('rgb(')) {
    const rgbValues = color.match(/\d+/g).map(Number);

    // Darken the color
    const darkenedValues = rgbValues.map((value) => Math.round(value * factor));

    // Convert back to hexadecimal
    const componentToHex = (c) => {
      const hex = c.toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    };

    return `#${darkenedValues.map(componentToHex).join('')}`;
  }

  // Return the original color if it's not in a recognized format
  return color;
}

export const handleDownload = (url) => {
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', url);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};
export const handleOpenInNewTab = (url) => {
  window.open(url, '_blank');
};

export const getAccessibility = (perm, gr) => {
  // perm is array of permissions
  // gr is array of groups that user belongs to
  // returns true if user has access
  // returns false if user does not have access
  // returns null if user does not have permission
  let d = perm;
  let pr = false;
  let p = gr.forEach((element) => {
    if (pr === false) {
      pr = d.includes(element);
      console.log(d.includes(element));
    }
  });
  return pr;
};

export function formatNumber(num, dec = 1) {
  num = Number(num);
  // Check if the number has a decimal part
  if (num % 1 !== 0) {
    return num.toFixed(dec); // If yes, format it to one decimal place
  }
  return num.toString(); // If no, return the number as a string without decimals
}

export const getDayWithSuffix = (date) => {
  const day = dayjs(date).format('D');
  if (day.endsWith('1') && day !== '11') return `${day}st`;
  if (day.endsWith('2') && day !== '12') return `${day}nd`;
  if (day.endsWith('3') && day !== '13') return `${day}rd`;
  return `${day}th`;
};
