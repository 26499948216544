import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Typography,
  ToggleButton,
  useTheme,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  alpha,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { eduleyApi } from "src/api/apiConfig";
import { SCOREWEIGHTAGE } from "src/apiUrl";
import Avatar from "src/components/Avatar";

import Iconify from "src/components/Iconify";
import IconifyButton from "src/components/IconifyButton";
import Label from "src/components/Label";
import LoadingIcon from "src/components/LoadingIcon";
import Scrollbar from "src/components/Scrollbar";
import { IconButtonAnimate } from "src/components/animate";
import useAuth from "src/hooks/useAuth";
import useResponsive from "src/hooks/useResponsive";
import AlertDialog from "src/pages/AlertDialog";
import { changeconfiguration, getCourse } from "src/redux/slices/courseupdate";
import { useDispatch, useSelector } from "src/redux/store";
const AddNewCustomScore = ({ a, handleAdding }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ListItemButton
        onClick={() => handleAdding(a)}
        startIcon={<Iconify icon={"ic:round-plus"} width={23} height={23} />}
        sx={{ textTransform: "none" }}
        style={{ justifyContent: "flex-start" }}
      >
        <ListItemIcon>
          <Iconify icon={"ic:round-plus"} width={23} height={23} />
        </ListItemIcon>
        <ListItemText primary={a.name} sx={{ width: "100%" }} />
      </ListItemButton>
      {open && (
        <UpdateModal
          open={open}
          handleClose={() => setOpen(false)}
          a={a}
          handleAdding={(a) => {
            handleAdding(a);
            setOpen(false);
          }}
        />
      )}
    </>
  );
};
export default AddNewCustomScore;

const UpdateModal = ({ a: item, handleClose, handleAdding }) => {
  const [value, setValue] = useState({
    total_score: 0,
    weightage: 0,
  });
  const validate = (data) => {
    const error = {};
    if (data.total_score <= 0)
      error.total_score = "Total score should be greater than 0";
    return error;
  };
  const error = useMemo(() => validate(value), [value]);

  const { addcourse } = useSelector((state) => state.courseupdate);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handlesave = async () => {
    if (Object.keys(error).length > 0) return;
    handleAdding(value);
  };

  return (
    <Dialog open={true} fullWidth onClose={handleClose} maxWidth="xs">
      <DialogTitle>
        {item.institute_transcript_weightage ? "Edit score" : "Add score"}
      </DialogTitle>
      <DialogContent sx={{ pt: 3 }}>
        <TextField
          fullWidth
          sx={{ mt: 3 }}
          label={"Enter the total score"}
          value={value.total_score}
          type="number"
          helperText={error?.total_score}
          error={error?.total_score}
          onChange={(e) =>
            setValue((prev) => ({
              ...prev,
              total_score: parseFloat(e.target.value),
            }))
          }
        />
        <TextField
          fullWidth
          sx={{ mt: 3 }}
          label={"Enter weightage "}
          value={value.weightage}
          type="number"
          helperText={error?.weightage}
          error={error?.weightage}
          onChange={(e) =>
            setValue((prev) => ({
              ...prev,
              weightage: parseFloat(e.target.value),
            }))
          }
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={() => handlesave(item)}
          disabled={Object.keys(error).length > 0}
        >
          Save
        </LoadingButton>
        <Button variant="outlined" onClick={() => handleClose()}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
