import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Typography,
  ToggleButton,
  useTheme,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  alpha
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { eduleyApi } from 'src/api/apiConfig';
import { COURSE_CUSTOM_TRANSCRIPT, SCOREWEIGHTAGE } from 'src/apiUrl';
import Avatar from 'src/components/Avatar';

import Iconify from 'src/components/Iconify';
import IconifyButton from 'src/components/IconifyButton';
import Label from 'src/components/Label';
import LoadingIcon from 'src/components/LoadingIcon';
import Scrollbar from 'src/components/Scrollbar';
import { IconButtonAnimate } from 'src/components/animate';
import useAuth from 'src/hooks/useAuth';
import useResponsive from 'src/hooks/useResponsive';
import AlertDialog from 'src/pages/AlertDialog';
import { changeconfiguration, getCourse } from 'src/redux/slices/courseupdate';
import { useDispatch, useSelector } from 'src/redux/store';
import CustomScoreDetails from './customScoreComponent/CustomScoreDetails';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';
import DisableCustomScoreView from './ConfigurationView/DisableCustomScoreView';

const CustomScore = ({ accessibleRoles }) => {
  const theme = useTheme();
  const [openDetails, setOpenDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const { addcourse } = useSelector((state) => state.courseupdate);
  const courseInfo = addcourse?.firstform;
  useEffect(() => {
    updateCostumTranscript();
  }, []);

  const handleChange = () => {
    setOpenDetails(!openDetails);
  };

  /* ***********************
  API CALL AVAILABLE TRANSCRIPT FOR COURSE
   ************************** */

  const updateCostumTranscript = async () => {
    setLoading(true);
    try {
      let res = await eduleyApi.get(`${COURSE_CUSTOM_TRANSCRIPT}?course=${addcourse.firstform.id}`);
      let d = [...res.data];
      let sum = 0;
      let total = d.forEach((x) => {
        return (sum += +x.weightage);
      });
      setData(sum);
      console.log('custom score', d, sum);
    } catch (error) {
      console.log('first', error);
    }
    setLoading(false);
  };

  return (
    <>
      <Stack
        direction={'row'}
        spacing={2}
        sx={{
          width: 1,
          position: 'relative',
          border: (theme) => `1px solid ${theme.palette.divider}`,
          my: 2,
          borderRadius: 1,
          backgroundColor: '#fff',
          cursor: 'pointer'
        }}
        onClick={handleChange}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 2, flex: 1 }}>
          <Avatar
            sx={{
              backgroundColor: alpha(theme.palette.primary.light, 0.1),
              color: 'primary.main',
              mt: 1
            }}
          >
            <Iconify icon={'radix-icons:mixer-vertical'} />
          </Avatar>

          <Stack sx={{ flex: 1 }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} sx={{ pb: 1, flexGrow: 1 }}>
              <Typography variant="h6">Custom Scores</Typography>
              <Label
                variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                color={courseInfo?.configure_custom_score ? 'success' : 'default'}
                sx={{
                  letterSpacing: 1,
                  fontSize: 12
                }}
              >
                {courseInfo?.configure_custom_score ? 'Custom Score Enabled' : 'Custom Score Disabled'}
              </Label>
            </Stack>
            <Typography variant="caption" color="text.secondary">
              Custom scores are scores that are configured for the calculation of the student's GPA final score. You can add/update any of the custom
              scores to this program.
            </Typography>
            {courseInfo?.configure_custom_score && data == 0 && (
              <>
                <br />
                <Typography variant="caption" color={'error'}>
                  Note: Any Score weightage with 0% will not be included in Final GPA Score Please check this before proceeding forward.
                </Typography>
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
      {openDetails && <CustomScoreDetails handleCloseDrawer={handleChange} accessibleRoles={accessibleRoles} />}
    </>
  );
};

export default CustomScore;
