import { Dialog, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getInstructor } from "src/redux/slices/user";
import InstructorTable from "./InstructorTable";

const InstructorModal = ({ id = "", changeInstructor, viewOnly = false, }) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [instructor, setInstructor] = React.useState({
    id: "",
    // iap_user: {},
    // designation: "",
  });
  const dispatch = useDispatch();
  const fetchInstructor = async () => {
    let res = await dispatch(getInstructor(id));
    setInstructor(res);
  };
  React.useEffect(() => {
    console.log(id);
    if (id && instructor.id !== id) {
      fetchInstructor();
    }
  }, [id]);
  const setInstructorView = (p) => {
    setInstructor(p);
    setOpenModal(false);
    changeInstructor(p);
    console.log("select table", p);
  };
  if (viewOnly) {
    return <>
      <Typography variant="body2">
        {(instructor.iap_user?.first_name || '') + " " + (instructor.iap_user?.last_name || '')}
      </Typography>
    </>
  }



  return (
    <>
      <TextField
        onClick={() => setOpenModal(true)}
        value={
          !instructor.iap_user
            ? ""
            : instructor.iap_user?.first_name +
            " " +
            instructor.iap_user?.last_name || ""
        }
        fullWidth
        required
      />
      <InsModal
        openModal={openModal}
        setinstructor={(p) => setInstructorView(p)}
        setclose={() => setOpenModal(false)}
      />
    </>
  );
};

const InsModal = ({ openModal, setinstructor, setclose }) => {
  //   const [openModal, setOpenModal] = React.useState(false);
  return (
    <Dialog
      open={openModal}
      onClose={() => setclose(false)}
      fullWidth
      maxWidth="lg"
    >
      <InstructorTable changeinstructor={setinstructor} />
    </Dialog>
  );
};

export default InstructorModal;
