import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '0,0' : '0,0.00');
}

export function fAmount(number) {
  let currency = localStorage.getItem('currency');
  currency = JSON.parse(currency);
  return currency?.prefix ? (currency?.prefix || '') + ' ' + +number : +number + ' ' + (currency?.suffix || '');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export function fNumber2decimal(number) {
  number = Number(number);
  if (number && number % 1 != 0) {
    return number?.toFixed(2);
  }
  return number;
}
