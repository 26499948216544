import React, { useState } from 'react'
import {
    Paper,
    Stack,
    Button,
    Typography,
    DialogTitle,
    TextField,
    Dialog,
    DialogContentText,
    DialogContent,
    DialogActions,
    OutlinedInput,
    ClickAwayListener, useTheme, Box
} from "@mui/material";
import Iconify from "src/components/Iconify";
import { useSelector } from 'src/redux/store';
import ModuleLessonDetails from '../../addcurriculum/ModuleLessonDetails';
import ContentView from './contentView';

const CurriculumView = ({ data }) => {
    return (
        <>
            <Stack
                direction="column"
                alignItems="flex"
                spacing={3}
                sx={{ height: "calc(100% - 32px)", overflowY: "hidden" }}
            >

                {data?.section?.map((section, index) => <Paper
                    variant="outlined"
                    sx={{ px: 1, bgcolor: "grey.5008", py: 2 }}
                >
                    <Stack spacing={1} >
                        <Stack sx={{ mt: 1, ml: 1 }} direction="row">
                            <Typography
                                variant="div"
                                sx={{
                                    fontWeight: "200",
                                    fontSize: "18px",
                                    color: "primary.main",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                Section - {index + 1}
                            </Typography>
                            {index == 0 &&
                                !data?.is_course_free &&
                                data?.offer_first_section_free && (
                                    <Typography
                                        variant="div"
                                        sx={{
                                            ml: 4,
                                            color: "text.secondary",
                                        }}
                                    >
                                        The first section of this program will be offered as a free
                                        trial to the student. Add more than 1 section to this
                                        program.
                                    </Typography>
                                )}
                        </Stack>
                        {
                            section?.content?.map((a, index) => <ContentCard data={a} index={index} />)
                        }
                    </Stack>
                </Paper>)}
            </Stack>
        </>
    )
}


const ContentCard = ({ data, index }) => {
    const theme = useTheme();
    const { name, content_type: type, custom_weightage = "" } = data;
    const [open, setOpen] = useState(false);
    const { addcourse } = useSelector((state) => state.courseupdate);
    const handleOpenDetails = () => {
        setOpen(!open);
    };
    return <>
        <Paper
            sx={{
                px: 2,
                width: 1,
                position: "relative",
                boxShadow: (theme) => theme.customShadows.z1,
                "&:hover": {
                    boxShadow: (theme) => theme.customShadows.z16,
                },

            }}
        >
            <Box
                onClick={handleOpenDetails}
                sx={{
                    cursor: "pointer",

                }}
            >
                <Stack
                    direction={"column"}
                    sx={{
                        pb: 2,
                        // pl: 5,
                        pl: 5,
                        pt: 2,
                        mr: 4,
                    }}
                // spacing={2}
                >
                    <Stack direction={"row"} alignContent={"center"} alignItems={"center"} spacing={3}>
                        <Iconify
                            icon={
                                type === "lesson"
                                    ? "ic:outline-play-lesson"
                                    : type === "quiz"
                                        ? "emojione-monotone:letter-q"
                                        : type === "homework" ? 'material-symbols:home-work-outline'
                                            : "ic:outline-assignment"
                            }
                            color={
                                theme.palette.mode === "light"
                                    ? theme.palette.primary.main
                                    : theme.palette.primary.light
                            }
                            width={24}
                            height={25}
                        />
                        <Typography noWrap variant="body" color="textPrimary">
                            {index + 1}. {name}
                        </Typography>
                    </Stack>
                    <Stack direction={"row"} sx={{ ml: 1 }} spacing={2}>
                        {custom_weightage && (
                            <>
                                <Typography variant="subtitle2" color="textSecondary">
                                    Custom Weightage Group Assigned :
                                </Typography>
                                <Typography variant="subtitle2" color="textPrimary">
                                    <b>{custom_weightage}</b>
                                </Typography>
                            </>
                        )}
                    </Stack>
                </Stack>
            </Box>
        </Paper>

        {open && (
            <ContentView
                data={data}
                courseid={addcourse.firstform.id}
                isOpen={open}
                onClose={handleOpenDetails}
                onDeleteTask={() => { }}
                saveLesson={(p) => handleOpenDetails()}
                section={data}
                index={index + 1}
            />
        )}
    </>
}

export default CurriculumView