import { Popover, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import Label from 'src/components/Label';
import SvgIconStyle from 'src/components/SvgIconStyle';
import useAuth from 'src/hooks/useAuth';
import { fCurrency } from 'src/utils/formatNumber';
import FeePopover from './FeePopover';
import useFeeStructure from './useFeeStructure';

export default function DiscountPreview({
  discountVariant,
  priceVariant,
  isPrice = true,
  price,
  discountedPrice,
  feeComponents = null,
  totalAmount
}) {
  const { instituteinfo } = useAuth();
  const { hasDiscount, discountUnit, discountValue, discountData } = useFeeStructure();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Stack
      sx={{
        p: 2.5,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`
      }}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      {isPrice && <Typography>Price</Typography>}
      <FeePopover data={feeComponents} totalAmount={totalAmount}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography
            variant={hasDiscount ? priceVariant || 'h6' : priceVariant || 'h4'}
            color="text.secondary"
            sx={{
              textDecorationLine: hasDiscount ? 'line-through' : undefined
            }}
          >
            {`${instituteinfo?.default_currency?.prefix || ''}${fCurrency(price, 2)}${instituteinfo?.default_currency?.suffix || ''}`}
          </Typography>
          {hasDiscount && (
            <Typography variant={discountVariant || 'h4'} color="primary.main">
              {`${instituteinfo?.default_currency?.prefix || ''}${fCurrency(discountedPrice, 2)}${instituteinfo?.default_currency?.suffix || ''}`}
            </Typography>
          )}
          {hasDiscount && (
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: 'none'
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Label
                variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                color={'success'}
                sx={{
                  letterSpacing: 1,
                  fontSize: 12
                }}
              >
                {`${discountUnit == 'amount' ? `${instituteinfo?.default_currency?.prefix || ''} ` : ''}${discountData?.value}${
                  discountUnit == 'percentage' ? '%' : instituteinfo?.default_currency?.suffix || ''
                } off on tuition fee`}
              </Label>
            </Popover>
          )}
          {hasDiscount && (
            <SvgIconStyle
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              src={`/icons/discount.svg`}
              sx={{ width: 24, height: 24, color: (theme) => theme.palette.success.main }}
            />
          )}
        </Stack>
      </FeePopover>
    </Stack>
  );
}
