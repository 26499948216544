import { useState, useRef, useEffect } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
  Stack,
  Typography,
  OutlinedInput,
  Button,
  TextField,
  Box,
  IconButton,
  Checkbox,
  ButtonGroup,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Skeleton
} from '@mui/material';
import TaskAttachments from '../../TaskAttachments';
import { useDispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';
import { getDocAssignment, addDocAssignment, updateDocAssignment, setDocAssignment } from 'src/redux/slices/lesson';
import { DateTimePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import MediaModal from 'src/sections/media/MediaModal';
import Iconify from 'src/components/Iconify';
import { contentOrdering } from 'src/redux/slices/courseupdate';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { fDateTime, fDateTimenoZone } from 'src/utils/formatTime';
import ClosewithoutSave from '../component/ClosewithoutSave';
import ContentWeightage from '../component/ContentWeightage';
import _ from 'lodash';

import SubjectiveQuestionList from './SubjectiveQuestionList';
import { fNumber2decimal } from 'src/utils/formatNumber';
import { useSnackbar } from 'notistack';
import { TimeFieldHHMMSS } from 'src/components/TimeField';
import TextEditorForDescription from '../component/TextEditorForDescription';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';
const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 180,
  fontSize: 13,
  flexShrink: 0,
  color: theme.palette.text.secondary
}));

const defaultData = {
  can_skip: true,
  can_proceed_without_marking: false,
  start_datetime: null,
  submission_type: 'uploaded',
  content_type: 'homework'
};

export default function DocAssignment({ data, handleUpdate, section, index, courseid }) {
  const fileInputRef = useRef(null);

  const dispatch = useDispatch();
  const { data: docdata, isLoading, error } = useSelector((state) => state.lesson.docAssignment);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { openwithoutsave } = useSelector((state) => state.lesson);
  const [value, setValue] = useState(defaultData);
  const [errors, setErrors] = useState({});
  const [openMedia, setOpenMedia] = useState(false);

  useEffect(() => {
    console.log('Data ID:', data);

    if (data.id) {
      setValue(null);
      dispatch(getDocAssignment(data.id));
    } else {
      dispatch(setDocAssignment({}));
    }
    return () => {
      dispatch(setDocAssignment({}));
    };
  }, [data.id]);

  useEffect(() => {
    setErrors({ ...error });
  }, [error]);

  useEffect(() => {
    if (docdata?.id) {
      // Check if `docdata` and `docdata.content` exist before accessing `docdata.content.id`
      setValue((prevState) => ({
        ...prevState,
        ...docdata,
        content: docdata.content ? docdata.content.id : null
      }));
    }
  }, [docdata]);

  // console.log("lecturedata--error--", error);

  const saveLesson = async () => {
    console.log('Checking:', value);
    if (value?.submission_type == 'subjective') {
      if (value?.questions?.length <= 0 || !value?.questions) {
        enqueueSnackbar('Please add atleast one question', {
          variant: 'error'
        });
        return;
      }
    }
    if (value?.total_marks <= 0 || !value?.total_marks) {
      enqueueSnackbar('Please add total marks', {
        variant: 'error'
      });
      return;
    }
    setLoading(true);
    let v = {
      ...value,
      section: section.id,
      transcript_weightage: !_.isEmpty(value?.transcript_weightage) ? value?.transcript_weightage.id : null,
      index: index,

      start_datetime: value?.start_datetime ? value?.start_datetime : null,
      questions: value?.questions?.map((a) => ({
        ...a,
        attachment: a.attachment?.id || null
      }))
    };
    let v2 = {
      ...value,
      transcript_weightage: !_.isEmpty(value?.transcript_weightage) ? value?.transcript_weightage.id : null,
      start_datetime: value?.start_datetime ? value?.start_datetime : null,
      questions: value?.questions?.map((a) => ({
        ...a,
        attachment: a.attachment?.id || null
      }))
    };
    // console.log('doc assignment--', v)
    let res = '';
    if (!value?.id) {
      res = await dispatch(addDocAssignment(v, section));
      // console.log(res)
      if (res.res == 'success') {
        let l = {
          id: courseid,
          section: [{ id: section.id, content: [...section?.content, res.data] }]
        };
        let r = await dispatch(contentOrdering(l));
      }
      // console.log("addLecture---", v);
    } else {
      res = await dispatch(updateDocAssignment(v2, data.id));
    }
    // console.log("res---", res);
    if (res === 'success' || res.res == 'success') {
      handleUpdate({ ...data, ...value });
    }
    setLoading(false);
  };
  const addingMedia = (media) => {
    setValue({ ...value, content: media?.id || null });
    setOpenMedia(false);
  };
  const handleChange = (e) => {
    // console.log("object", e.target.value);
    if (e.target.name == 'submission_type' && e.target.value == 'subjective') {
      // for setup total marks again
      setValue({
        ...value,
        [e.target.name]: e.target.value,
        total_marks: value?.questions?.map((a) => a.maximum_marks).reduce((a, b) => +a + +b, 0)
      });
    } else {
      setValue({ ...value, [e.target.name]: e.target.value });
    }
  };
  const handleWeightage = (weightage) => {
    setValue({ ...value, transcript_weightage: weightage });
  };
  const handleUpdateQuestion = (question) => {
    setValue({
      ...value,
      questions: question,
      total_marks: question?.map((a) => a.maximum_marks).reduce((a, b) => +a + +b, 0)
    });
  };
  console.log('object-- homework', value);
  const handleChangeDescription = (e) => {
    if (!!data.id) {
      if (!!value?.description) {
        setValue((prevState) => ({
          ...prevState,
          description: e
        }));
      }
    } else {
      setValue((prevState) => ({
        ...prevState,
        description: e
      }));
    }
  };
  if (isLoading && !loading) {
    return (
      <>
        {new Array(6).fill(0).map((a, i) => (
          <Skeleton variant="rectangular" height={65} key={i} />
        ))}
      </>
    );
  }

  return (
    <>
      <Stack direction="row">
        <LabelStyle sx={{ mt: 2 }}>Homework name</LabelStyle>
        <TextField
          fullWidth
          //   multiline
          error={errors.name ? true : false}
          helperText={errors.name?.join(', ') || 'Name of the homework  '}
          rows={1}
          size="small"
          placeholder="Homework name"
          value={value?.name}
          name="name"
          onChange={handleChange}
          sx={{ typography: 'body2', mt: 1 }}
        />
      </Stack>

      <Stack direction="row">
        <LabelStyle sx={{ mt: 2 }}>Description</LabelStyle>
        <div style={{ width: '100%' }}>
          <TextEditorForDescription
            value={value?.description}
            setValue={(e) => handleChangeDescription(e)}
            errors={errors.description ? errors.description : ''}
            helperText={errors.description}
          />
        </div>
      </Stack>
      <Stack direction="row">
        <LabelStyle sx={{ mt: 2 }}>Homework file</LabelStyle>
        <Stack direction="column">
          <MediaModal media={value?.content} addingMedia={(p) => addingMedia(p)} />
          <Typography variant="body2" sx={{ mt: 1, fontSize: 12, ml: 1 }} color={errors.assignment ? 'error.main' : 'text.secondary'}>
            {errors.document ? errors.assignment.join('') : 'Attach the file for the homework'}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="row">
        <LabelStyle sx={{ mt: 2 }}>Weightage Group in Grade</LabelStyle>
        <ContentWeightage data={value?.transcript_weightage} handleSubmit={handleWeightage} type="homework" />
      </Stack>
      <Stack direction="row">
        <LabelStyle sx={{ mt: 1 }}>Number of Hours </LabelStyle>
        <TimeFieldHHMMSS
          value={value?.time_allotment}
          error={errors.time_allotment}
          helperText={errors.time_allotment}
          handleValue={(e) => {
            setValue({ ...value, time_allotment: e });
          }}
        />
      </Stack>
      <Stack direction="row">
        <LabelStyle sx={{ mt: 2 }}>Start Date</LabelStyle>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            value={value?.start_datetime}
            onChange={(e) => setValue({ ...value, start_datetime: e })}
            renderInput={(params) => (
              <TextField
                {...params}
                error={errors?.start_datetime || false}
                helperText={
                  errors?.start_datetime?.join(',') || (
                    <>
                      Select Date from when you want this content to be available for the students.
                      <br />
                      This can be left blank if you want this to be always available.
                    </>
                  )
                }
                fullWidth
                variant="outlined"
              />
            )}
          />
        </LocalizationProvider>
      </Stack>
      <Stack direction="row">
        <LabelStyle sx={{ mt: 0 }}>Can Skip</LabelStyle>
        <Checkbox
          defaultChecked={!!value?.can_skip}
          checked={!!value?.can_skip}
          onChange={(e) =>
            setValue({
              ...value,
              can_skip: e.target.checked,
              can_proceed_without_marking: e.target.checked ? false : value?.can_proceed_without_marking
            })
          }
          inputProps={{ 'aria-label': 'controlled' }}
          sx={{ mt: -1, ml: -1.5 }}
        />
        <Stack>
          <Typography variant="body2" sx={{ mt: 0, fontSize: 12 }} color="text.secondary">
            Can student skip this content and proceed to next content?
          </Typography>
          <Typography variant="body2" sx={{ mt: 0, fontSize: 12 }} color="error.main">
            If this is selected/checked then this homework score will not be included in the final grade for student
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row">
        <LabelStyle sx={{ mt: 2 }}>Is gated</LabelStyle>
        <Checkbox
          defaultChecked={false}
          checked={!!value?.can_proceed_without_marking}
          disabled={!!value?.can_skip}
          onChange={(e) =>
            setValue({
              ...value,
              can_proceed_without_marking: e.target.checked
            })
          }
          inputProps={{ 'aria-label': 'controlled' }}
          sx={{ mt: 1, ml: -1.5 }}
        />
        <Typography
          variant="body2"
          sx={{
            mt: 2,
            fontSize: 12,
            textDecoration: value?.can_skip ? 'line-through' : ''
          }}
          color="text.secondary"
        >
          Can student proceed to next content without instructor marking the homework .
        </Typography>
      </Stack>
      <Stack direction="row">
        <LabelStyle sx={{ mt: 1 }}>Submission type </LabelStyle>
        <Stack direction={'column'} alignContent={'flex-start'} alignItems={'flex-start'}>
          <RadioGroup
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="submission_type"
            onChange={handleChange}

          // defaultValue={"uploaded"}
          >
            <FormControlLabel
              value="uploaded"
              checked={value?.submission_type === 'uploaded'}
              control={<Radio sx={{ mt: -2 }} />}
              label={
                <>
                  File Uploaded <br />
                  <FormHelperText sx={{ mt: 0 }}>Student submit the homework by uploading a file</FormHelperText>
                </>
              }
            />
            <FormControlLabel
              sx={{ mt: 0.5 }}
              value="subjective"
              checked={value?.submission_type === 'subjective'}
              control={<Radio sx={{ mt: -2 }} />}
              label={
                <>
                  Subjective <br />
                  <FormHelperText sx={{ mt: 0 }}>Student submit homework in a question and answer format</FormHelperText>
                </>
              }
            />
          </RadioGroup>
        </Stack>
      </Stack>
      <Stack direction="row">
        <LabelStyle sx={{ mt: 2 }}>Total Marks</LabelStyle>
        <TextField
          fullWidth
          disabled={value?.submission_type === 'subjective'}
          //   multiline
          error={errors.total_marks ? true : false}
          helperText={errors.total_marks?.join(', ') || 'Enter Total Marks'}
          rows={1}
          size="small"
          placeholder="Enter Total Marks"
          value={value?.total_marks}
          name="total_marks"
          onChange={handleChange}
          sx={{ typography: 'body2', mt: 1 }}
        />
      </Stack>
      {value?.submission_type == 'subjective' && (
        <>
          <Stack direction="row">
            <LabelStyle>No of Questions</LabelStyle>
            {value?.questions?.length || 0}
          </Stack>
          <Divider />
          <SubjectiveQuestionList handleUpdate={handleUpdateQuestion} data={value?.questions || []} />
        </>
      )}
      <SaveButton saving={() => saveLesson()} loading={loading} />
      {openwithoutsave && (
        <ClosewithoutSave
          oncancel={() => handleUpdate()}
          onok={() => {
            saveLesson();
          }}
        />
      )}
    </>
  );
}
const SaveButton = ({ saving, loading }) => (
  <Stack direction="row">
    <RoleBasedGuard accessibleRoles={[1, 2]} noPermissionChildren={<></>}>
      <LoadingButton loadingPosition="start" loading={loading} variant="contained" onClick={() => saving()} sx={{ ml: 2, mt: 2 }}>
        Save Content
      </LoadingButton>
    </RoleBasedGuard>
  </Stack>
);
