import PropTypes from "prop-types";
// @mui
import { useTheme, styled } from "@mui/material/styles";
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  InputAdornment,
  Button,
  Stack,
} from "@mui/material";
// components
import Iconify from "src/components/Iconify";
import InputStyle from "src/components/InputStyle";
import { Link as RouterLink } from "react-router-dom";
import { PATH_DASHBOARD } from "src/routes/paths";

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  onDeleteUsers: PropTypes.func,
};

export default function UserListToolbar({
  numSelected,
  filterName,
  onFilterName,
  // onDeleteUsers,
}) {
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: isLight ? "primary.main" : "text.primary",
          bgcolor: isLight ? "primary.lighter" : "primary.dark",
        }),
      }}
    >
      <InputStyle
        sx={{ width: "100%" }}
        size="small"
        value={filterName}
        onChange={(event) => onFilterName(event.target.value)}
        placeholder="Search ..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon={"eva:search-fill"}
                sx={{ color: "text.disabled", width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
        }}
      />

      <Stack direction="row" spacing={2}>
        <Tooltip title="Filter list">
          <IconButton>
            <Iconify icon={"ic:round-filter-list"} />
          </IconButton>
        </Tooltip>

        {/* <Button
          sx={{ width: 120 }}
          variant="contained"
          size="small"
          component={RouterLink}
          to={PATH_DASHBOARD.user.newUser}
          // startIcon={<Iconify icon={"eva:plus-fill"} />}
        >
          New User
        </Button> */}
      </Stack>
    </RootStyle>
  );
}
