import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Avatar, Button, Checkbox, Divider, IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import { getEnrollmentDocuments, setAddDocumentOpened } from 'src/redux/slices/configuration';
import _ from 'lodash';
import { DOCUMENT_ICON_URL } from 'src/sections/@dashboard/configuration/enrollment_documents/constants';
import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';
import { eduleyApi } from 'src/api/apiConfig';
import { STUDENT_DOCUMENT } from 'src/apiUrl';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import ConfirmDialog from 'src/components/confirm-dialog';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';
import { updateStudentDocument } from 'src/redux/slices/courseupdate';

export default function AddRemoveDocument({ studentDocuments, onClose, getDocumentList, accessibleRoles }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { enrollmentDocument } = useSelector((state) => state.configuration);
  const [documentList, setDocumentList] = useState([]);
  const { addcourse } = useSelector((state) => state.courseupdate);
  const courseInfo = addcourse.firstform;
  const methods = useForm({
    resolver: yupResolver()
  });

  const {
    reset,
    watch,
    handleSubmit,
    setValue,
    formState: { isSubmitting }
  } = methods;

  const fields = watch('items');

  // console.log('Fields:', fields, documentList);

  // console.log('Course:', courseInfo);
  useEffect(() => {
    if (!_.isEmpty(studentDocuments)) {
      setValue('items', mapStudentDocuments(enrollmentDocument?.data, studentDocuments));
    } else if (!_.isEmpty(enrollmentDocument?.data)) {
      setValue('items', [
        {
          name: enrollmentDocument?.data[0].name,
          global_document: enrollmentDocument?.data[0].id,
          document_name: enrollmentDocument?.data[0].name,
          course: courseInfo?.id
        }
      ]);
    }
  }, [enrollmentDocument, studentDocuments]);

  useEffect(() => {
    console.log('D');
    if (!_.isEmpty(enrollmentDocument?.data)) {
      const list = formatDocFields(enrollmentDocument?.data);
      setDocumentList(list);
    }
  }, [enrollmentDocument]);

  useEffect(() => {
    console.log('E');
    if (!_.isEmpty(fields)) {
      const updateDoc = enrollmentDocument.data.filter((item) => !fields.some((doc) => doc.global_document === item.id));
      setDocumentList(formatDocFields(updateDoc));
    }
  }, [fields]);

  function formatDocFields(data) {
    return data?.map((item) => {
      console.log('A');
      return {
        name: item.name,
        global_document: item.id,
        document_name: item.name,
        course: courseInfo?.id
      };
    });
  }

  function mapStudentDocuments(enrolledDocuments, studentDocuments) {
    return studentDocuments.map((document) => {
      let doc = enrolledDocuments?.find((item) => item.id == document.global_document);
      doc = doc?.id ? doc : document;
      if (doc) {
        return {
          ...document,
          name: doc.name || doc.document_name,
          global_document: doc?.id ? doc.id : doc.global_document
        };
      }
    });
  }

  useEffect(() => {
    dispatch(getEnrollmentDocuments());
  }, []);

  async function handleDocument(data) {
    // console.log('Data to send:', fields);
    const payload = fields?.map((item) => {
      const matchObj = studentDocuments?.find((obj) => obj?.global_document === item?.global_document);
      if (matchObj) {
        return {
          name: item?.name,
          global_document: item?.global_document,
          document_name: item?.name,
          course: courseInfo?.id,
          id: matchObj?.id
        };
      } else {
        return {
          name: item?.name,
          global_document: item?.global_document,
          document_name: item?.name,
          course: courseInfo?.id
        };
      }
    });
    // console.log('Course:', courseInfo);

    try {
      const response = await eduleyApi.patch(`${STUDENT_DOCUMENT}${courseInfo?.id}/`, payload);
      await getDocumentList();
      dispatch(
        updateStudentDocument({
          ...courseInfo,
          enable_student_document: true
        })
      );
      onClose();
      enqueueSnackbar('Document added successfully', { variant: 'success' });
    } catch (error) {}
  }

  const handleOpenDocumentAddModal = () => {
    dispatch(setAddDocumentOpened(true));
  };

  return (
    <Stack sx={{ height: '100%' }}>
      <FormProvider methods={methods} style={{ height: '100%' }}>
        <Scrollbar sx={{ height: 1, bgcolor: 'background.neutral' }}>
          <Stack spacing={1.5} justifyContent="center" sx={{ p: 2.5 }}>
            <Typography variant="body2">Mandatory student documents to be collected for the program enrollment</Typography>
            <Divider sx={{ borderStyle: 'dashed' }} />
            <DocumentRow documentList={documentList} courseInfo={courseInfo} accessibleRoles={accessibleRoles} />
          </Stack>
        </Scrollbar>
      </FormProvider>
      <Stack spacing={1.5} sx={{ p: 2.5 }}>
        <RoleBasedGuard accessibleRoles={accessibleRoles} noPermissionChildren={<></>}>
          <Button
            fullWidth
            variant="soft"
            color="info"
            size="large"
            onClick={handleOpenDocumentAddModal}
            startIcon={<Iconify icon="ic:outline-plus" />}
          >
            Add Document
          </Button>
          <LoadingButton
            loading={isSubmitting}
            fullWidth
            variant="soft"
            color="primary"
            size="large"
            disabled={_.isEmpty(fields)}
            startIcon={<Iconify icon="mdi:tick" />}
            onClick={handleSubmit(handleDocument)}
          >
            Submit
          </LoadingButton>
        </RoleBasedGuard>
      </Stack>
    </Stack>
  );
}

function DocumentRow({ documentList, courseInfo, accessibleRoles }) {
  const [openCaution, setOpenCaution] = useState(false);
  const [tempIdx, setTempIdx] = useState(false);
  const [search, setSearch] = useState('');
  const { control, setValue, watch } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items'
  });

  const values = watch('items');

  function handleAddExistingDocument(event, value, reason) {
    console.log('Selecttt:', value, reason);
    if (reason == 'selectOption') {
      append(value);
    }
    if (reason == 'clear') {
      setSearch('');
    }
  }

  const handleRemove = (index) => {
    setTempIdx(index);
    setOpenCaution(true);
  };

  function onCloseCaution() {
    setOpenCaution(false);
    setTempIdx(false);
  }

  function onOkay() {
    remove(tempIdx);
    onCloseCaution();
  }

  console.log('Values:', values);

  return (
    <Stack>
      <RoleBasedGuard accessibleRoles={accessibleRoles} noPermissionChildren={<></>}>
        <Autocomplete
          inputValue={search}
          onInputChange={(event, value, reason) => {
            console.log('Value in input:', value);
            if (reason === 'input') {
              setSearch(value);
            }
            if (reason === 'clear') {
              setSearch('');
            }
          }}
          fullWidth
          options={documentList}
          getOptionLabel={(option) => option?.name || ''}
          onChange={handleAddExistingDocument}
          renderInput={(params) => <TextField label="Search for Documents" {...params} />}
        />
      </RoleBasedGuard>
      <Stack
        sx={{
          py: 2
        }}
      >
        <Stack spacing={1.5}>
          {fields.map((field, index) => (
            <Stack
              sx={{
                bgcolor: 'background.default',
                p: 2,
                borderRadius: 1,
                width: '100%'
              }}
              key={field.global_document}
            >
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" spacing={1} alignItems="center">
                  <Avatar sx={{ height: 18, width: 18, borderRadius: 0 }} src={DOCUMENT_ICON_URL} />
                  <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                    {field.name}
                  </Typography>
                </Stack>

                <Tooltip title="Remove">
                  <IconButton color="error" size="small" onClick={() => handleRemove(index)}>
                    <Iconify icon={'zondicons:minus-outline'} width={18} height={18} />
                  </IconButton>
                </Tooltip>
              </Stack>
              {/* <RHFTextField size="small" name={`items[${index}].document_name`} label="Alias" InputLabelProps={{ shrink: true }} /> */}
            </Stack>
          ))}
        </Stack>
      </Stack>
      <ConfirmDialog
        open={openCaution}
        onClose={onCloseCaution}
        title="Are you sure you want to remove this?"
        content="This document is required for subscriptions and any new subscriptions will not be uploading this document"
        action={
          <Button color="error" onClick={onOkay}>
            Remove
          </Button>
        }
      />
    </Stack>
  );
}
