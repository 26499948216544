import React, { useEffect, useState } from 'react'
import { Button, IconButton, Tooltip } from '@mui/material'
import Iconify from '../../../../../components/Iconify'
import { eduleyApi } from 'src/api/apiConfig'
import { GENERATE_DESCRIPTION } from 'src/apiUrl'
import { useSnackbar } from 'notistack'
const AiImplemention = ({ data, handleResponse }) => {
    const [loading, setLoading] = React.useState(false)
    const [open, setOpen] = React.useState(false)
    const { enqueueSnackbar } = useSnackbar()

    const getInformation = async () => {
        setLoading(true)
        try {
            let res = await eduleyApi.post(`${GENERATE_DESCRIPTION}`, {
                course_name: data?.name,
                course_category: data?.category
            })
            // console.log(res.data)
            let data1 = res.data.data
            handleResponse(data1)

            enqueueSnackbar("Description generated successfully", { variant: 'success', })
        } catch (error) {
            console.log('error', error)
            enqueueSnackbar(error?.data?.data, { variant: 'error', })
        }
        setLoading(false)
    }
    return (<>
        <Tooltip title="Add description to program by help of AI assistance">
            <Button onClick={getInformation} disabled={loading} color='primary'
                startIcon={<Iconify icon={loading ? "eos-icons:loading" : "streamline-emojis:robot-face-1"} width={20} height={20} />}>
                {loading ? "Generating..." : "Generate description by AI"}
            </Button>
        </Tooltip>
    </>
    )
}

export default AiImplemention