import { useEffect, useState } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import {
  Card,
  Table,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  DialogTitle,
  DialogActions,
  Box,
} from "@mui/material";
// routes
// hooks
import useSettings from "src/hooks/useSettings";
// _mock_
// import { _userList } from "../../_mock";
// components
import Page from "src/components/Page";
import Label from "src/components/Label";
import Iconify from "src/components/Iconify";
import Scrollbar from "src/components/Scrollbar";
import SearchNotFound from "src/components/SearchNotFound";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
// sections
import { UpadateInstructor, UserListHead, UserListToolbar, UserMoreMenu } from "src/sections/@dashboard/instructor";
import { useDispatch, useSelector } from "src/redux/store";
import { deleteuser, getInstructorList } from "src/redux/slices/user";
import { DialogAnimate } from "src/components/animate";
import { LoadingButton } from "@mui/lab";
import { Link as RouterLink } from "react-router-dom";
import { PATH_DASHBOARD } from "src/routes/paths";
import { getPageLimit, setPageLimit } from "src/utils/pageLimitControl";
// import { getInstructorList } from "src/redux/slices/user";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "id", label: "Id", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "", label: "Status", alignRight: false },
  { id: "designation", label: "Qualification", alignRight: false },
  { id: "description", label: "Description", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

export default function InstructorTable({ changeinstructor }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { instructor: users, isLoading, error, rolelist = [] } = useSelector((state) => state.user);
  const { themeStretch } = useSettings();
  const [openDelete, setOpenDelete] = useState({ data: {}, open: false });
  const [openEdit, setOpenEdit] = useState({ data: {}, open: false });

  const [userList, setUserList] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("id");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(getPageLimit("instructorlist"));
  console.log("inst-----", users);
  let query =
    "?limit=" + rowsPerPage + "&offset=" + page * rowsPerPage + "&ordering=" + (order == "asc" ? "-" : "") + orderBy + "&search=" + filterName;

  useEffect(() => {
    dispatch(getInstructorList(query));
  }, [dispatch, page, rowsPerPage, order, orderBy, filterName, openEdit]);

  useEffect(() => {
    setUserList(users.results);
  }, [users]);

  const handleRequestSort = (property, p) => {
    const isAsc = orderBy === p && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(p);
  };

  const handleSelectAllClick = (checked) => {
    if (checked) {
      const newSelecteds = userList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    let p = parseInt(event.target.value, 10);
    setRowsPerPage(p);
    setPageLimit("instructorlist", p);
    setPage(0);
  };

  const handleFilterByName = (filterName) => {
    console.log("filterName", filterName);
    setFilterName(filterName);
    setPage(0);
  };

  const handleDeleteUser = (userId) => {
    const deleteUser = userList.filter((user) => user.id !== userId);
    setSelected([]);
    setUserList(deleteUser);
    dispatch(deleteuser(userId));
    setOpenDelete({ data: "", open: false });
  };

  const handleDeleteMultiUser = (selected) => { };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;

  return (
    <>
      <Card>
        <UserListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          onDeleteUsers={() => handleDeleteMultiUser(selected)}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={userList?.length}
                numSelected={selected?.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {userList?.map((row, index) => {
                  const {
                    designation,
                    description,
                    iap_user: { first_name = "", last_name = "", id = "", profile_photo = "", is_active = false, email = "" },
                  } = row;
                  const isItemSelected = selected.indexOf(first_name) !== -1;

                  return (
                    <TableRow hover key={id} tabIndex={-1} role='checkbox' selected={isItemSelected} aria-checked={isItemSelected}>
                      <TableCell padding='checkbox' onClick={() => changeinstructor(row)}>
                        {id}
                      </TableCell>
                      <TableCell sx={{ display: "flex", alignItems: "center" }} onClick={() => changeinstructor(row)}>
                        <Avatar alt={first_name} src={profile_photo} sx={{ mr: 2 }} />
                        <Typography variant='subtitle2' noWrap>
                          {first_name + " " + last_name}
                        </Typography>
                      </TableCell>
                      <TableCell align='left' onClick={() => changeinstructor(row)}>
                        <Label variant={theme.palette.mode === "light" ? "ghost" : "filled"} color={!is_active ? "error" : "success"}>
                          {is_active ? "Active" : "Inactive"}
                        </Label>
                      </TableCell>
                      <TableCell align='left' onClick={() => changeinstructor(row)}>
                        {designation || "-"}
                      </TableCell>
                      <TableCell align='left'>{description?.length > 45 ? description?.slice(0, 45) + "..." : description || "-"}</TableCell>

                      <TableCell align='right'>
                        <UserMoreMenu
                          onDelete={() => setOpenDelete({ data: id, open: true })}
                          userName={id}
                          onEdit={() => setOpenEdit({ data: row, open: true })}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
                {users?.count == 0 && (
                  <TableRow>
                    <TableCell align='center' colSpan={3} sx={{ py: 3, color: "text.main" }}>
                      No Instructors Data Found
                    </TableCell>
                    <TableCell align='center' colSpan={2} sx={{ py: 3, color: "text.main" }}>
                      <Button sx={{ width: 120 }} variant='contained' size='small' component={RouterLink} to={PATH_DASHBOARD.user.newUser}>
                        New User
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        {users.count > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={users?.count || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Card>
      <DialogAnimate open={openDelete.open} onClose={() => setOpenDelete({ data: {}, open: false })}>
        <DialogTitle>
          <Typography variant='h6'>Delete Instructor</Typography>
        </DialogTitle>
        <DialogActions>
          <Box sx={{ flexGrow: 1 }} />
          <Button variant='outlined' color='inherit' onClick={() => setOpenDelete({ data: {}, open: false })}>
            Cancel
          </Button>
          <LoadingButton type='submit' variant='contained' loadingIndicator='Loading...' onClick={() => handleDeleteUser(openDelete.data)}>
            Delete
          </LoadingButton>
        </DialogActions>
      </DialogAnimate>
      {openEdit.open && (
        <UpadateInstructor data={openEdit.data} onclose={() => setOpenEdit({ data: {}, open: false })} color='primary' open={openEdit.open} />
      )}
    </>
  );
}

// ----------------------------------------------------------------------
