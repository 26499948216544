import { Card, CardContent, CardHeader, Stack, Typography } from '@mui/material'
import React from 'react'

import useAuth from "src/hooks/useAuth";
import MultipleErrorModal from "src/components/MulipleErrorModal";
import MediaModal2 from "src/sections/media/MediaModal2";
import { useDispatch, useSelector } from "src/redux/store";
import { useMemo } from 'react';
import MediaModal from 'src/sections/media/MediaModal';

const MediaView = () => {

    const { addcourse } = useSelector(
        (state) => state.courseupdate
    );
    const {
        poster_image,
        intro_video_media,
    } = useMemo(() => addcourse?.firstform, [addcourse?.firstform]);
    console.log('addMedia', addcourse?.firstform, poster_image, intro_video_media);
    return (

        <Card sx={{ p: 3 }}>
            <CardHeader
                title="Add Introduction Media"
            // sx={{ textAlign: "center" }}
            />
            <CardContent>
                <Stack spacing={3} sx={{ mr: 5 }}>
                    <div>
                        <Typography variant="body2">
                            1. Cover Picture (only public image)
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{ fontSize: 12, color: "text.secondary", mb: 1 }}
                        >
                            Recommended size of the banner to upload [600 x 600 px]
                        </Typography>

                        <MediaModal
                            media={poster_image}
                            addingMedia={(rajeev) => { }}
                            removingMedia={(data) => { }}
                            file_type={"image"}
                            public_private="public"
                            viewOnly={true}
                        />
                        <Typography
                            variant="body2"
                            sx={{ mt: 1, fontSize: 12, ml: 1 }}
                            color="text.secondary"
                        >
                            Add a cover image for your program.
                        </Typography>
                        {/* </Stack> */}
                    </div>
                </Stack>
                <Stack spacing={3} sx={{ mt: 3 }}>
                    <div>
                        <Typography variant="body2">
                            2. Introduction Video (only public video)
                        </Typography>

                        <MediaModal
                            media={intro_video_media}
                            addingMedia={(rajeev) => { }}
                            removingMedia={(data) => { }}
                            file_type={"video"}
                            public_private="public"
                            viewOnly={true}
                        />
                        <Typography
                            variant="body2"
                            sx={{ mt: 1, fontSize: 12, ml: 1 }}
                            color="text.secondary"
                        >
                            Add a video to introduce your program.
                        </Typography>
                        {/* </Stack> */}
                    </div>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default MediaView