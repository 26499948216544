import { alpha, Stack, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import Avatar from 'src/components/Avatar';

import Iconify from 'src/components/Iconify';

import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';
import { useDispatch, useSelector } from 'src/redux/store';
import DiscountPreview from './DiscountPreview';
import FeeDiscount from './FeeDiscount';
import FeeStructureDrawer from './FeeStructureDrawer';
import useFeeStructure from './useFeeStructure';

const FeeStructure = ({ accessibleRoles }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { instituteinfo } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const {
    getFeeDiscount,
    price,
    onChangePrice,
    onUpdateData,
    hasDiscount,
    feeData,
    isCohort,
    onChangeDiscountValue,
    onChangeDiscountUnit,
    getCoursePriceBreakup,
    priceBreakup,
    discountData,
    discountValue
  } = useFeeStructure();
  const { addcourse } = useSelector((state) => state.courseupdate);
  const [openDetails, setOpenDetails] = useState(false);
  const [isOpeningPriceConfigure, setOpeningPriceConfigure] = useState(false);
  const isFree = isCohort ? feeData?.is_free : feeData?.is_course_free;

  useEffect(() => {
    getFeeDiscount(feeData?.id);
    if (feeData?.total_price > 0) {
      getCoursePriceBreakup(feeData?.id);
    }
    if (feeData?.total_price == 0 && !isFree) {
      onUpdateData(feeData);
    }
    return () => {
      onChangePrice(null);
      onChangeDiscountUnit(null);
      onChangeDiscountValue(null);
    };
  }, []);

  const handleChange = async () => {
    if (Number(discountData?.value) !== Number(discountValue) && discountValue !== '') {
      return enqueueSnackbar('Please submit the discount value', { variant: 'error' });
    }
    try {
      setOpeningPriceConfigure(true);
      const response = await getCoursePriceBreakup(feeData?.id);
      setOpeningPriceConfigure(false);
      setOpenDetails(!openDetails);
    } catch {
      setOpeningPriceConfigure(false);
    }

    // if (Number(feeData?.price) !== Number(price) && !isFree && Number(price) !== 0) {
    //   try {
    //     setOpeningPriceConfigure(true);
    //     await onUpdateData({ price });
    //     setOpeningPriceConfigure(false);
    //     setOpenDetails(!openDetails);
    //   } catch (e) {
    //     setOpeningPriceConfigure(false);
    //   }
    // } else {
    //   setOpenDetails(!openDetails);
    // }
  };

  return (
    <>
      <Stack
        spacing={2}
        sx={{
          width: 1,
          position: 'relative',
          border: (theme) => `1px solid ${theme.palette.divider}`,
          my: 2,
          borderRadius: 1,
          backgroundColor: '#fff'
        }}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 2, borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}>
          <Avatar
            sx={{
              backgroundColor: alpha(theme.palette.primary.light, 0.1),
              color: 'primary.main'
            }}
          >
            <Iconify icon={'solar:card-bold-duotone'} />
          </Avatar>

          <Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} sx={{ pb: 1, flexGrow: 1 }}>
              <Typography variant="h6">
                Fee structure
                <Typography variant="caption" color="red">
                  *
                </Typography>
              </Typography>
              {/* <Label
                variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                color={priceBreakup.length > 2 ? 'success' : 'default'}
                sx={{
                  letterSpacing: 1,
                  fontSize: 12
                }}
              >
                {priceBreakup.length > 2 ? 'Enabled' : 'Disabled'}
              </Label> */}
            </Stack>
            <Typography variant="caption" color="text.secondary">
              Define and customize your course fee structure to ensure transparency and optimize revenue.
            </Typography>
          </Stack>
        </Stack>
        <Stack sx={{ p: 2 }} spacing={2}>
          {/* <TextField
            fullWidth
            InputProps={{
              startAdornment: <InputAdornment position="start">{instituteinfo?.default_currency?.prefix}</InputAdornment>,
              endAdornment: <InputAdornment position="end"> {instituteinfo?.default_currency?.suffix}</InputAdornment>
            }}
            name="price"
            size="medium"
            disabled={isFree}
            placeholder="Enter Price"
            value={price}
            onChange={onChangePrice}
          /> */}
          {/* {feeData?.price !== price && feeData?.has_price_breakup && (
            <Alert icon={<Iconify icon="ant-design:alert-twotone" />} severity="error">
              Please re-configure your fee structure after changing course fee
            </Alert>
          )} */}
          {!!feeData?.total_price && (
            <DiscountPreview
              price={feeData?.total_price + feeData?.discounted_price}
              discountedPrice={feeData?.total_price}
              isPrice={!hasDiscount}
              priceVariant="subtitle2"
              discountVariant={'subtitle1'}
              feeComponents={priceBreakup}
            />
          )}
          {!!feeData?.total_price && <FeeDiscount />}

          <LoadingButton loading={isOpeningPriceConfigure} variant="soft" color="primary" onClick={handleChange}>
            Configure Fee Structure
          </LoadingButton>
        </Stack>
      </Stack>
      <FeeStructureDrawer open={openDetails} onClose={handleChange} accessibleRoles={accessibleRoles} />
    </>
  );
};

export default FeeStructure;
