import React from 'react'
import {
  Card,
  Chip,
  Grid,
  Stack,
  Typography,
  Container,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  IconButton,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  InputAdornment,
  FormControlLabel,
  styled,
  CardContent,
  Divider
} from '@mui/material';

import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Image from 'src/components/Image';
import Markdown from 'src/components/Markdown';
import { InstructorModal } from '../../instructor';
import Label from 'src/components/Label';
const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1)
}));
const BasicView = ({ data }) => {


  const GeneralDivider = () => <Grid item xs={12}>
    <Divider sx={{ width: '100%' }} />
  </Grid>
  return (
    <Page title="Basic Information" sx={{ height: 1 }}>
      <Container maxWidth={false} sx={{ height: 1 }}>
        <HeaderBreadcrumbs
          heading="Basic Information"
          links={[
            {
              name: ''
            }
          ]}
        />
        <Card>
          <CardContent>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} >
                <LabelStyle>Program Name</LabelStyle>
                <Stack direction={"row"} spacing={3}>
                  <Typography variant="h5" color={'text.secondary'}>{data?.name}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={6} >
                <LabelStyle >Program fee</LabelStyle>
                {data.is_course_free ? <Label color="warning" size='large'>Free Course</Label> :

                  <>
                    <Typography variant="h5" color={'text.secondary'}>{data?.currency?.prefix} {data?.price}</Typography>
                    {data.offer_first_section_free && <Label color="warning" size='large'>Free trail available</Label>}
                  </>
                }
              </Grid>

              <GeneralDivider />
              <Grid item xs={12}  >
                <LabelStyle >Short Description</LabelStyle>
                <Typography variant="body" color={'text.secondary'}>{data?.short_description}</Typography>
              </Grid>
              <GeneralDivider />
              <Grid item xs={12}  >
                <LabelStyle >Long Description</LabelStyle>
                <Markdown children={data?.long_description} />
              </Grid>
              <GeneralDivider />
              <Grid item xs={12} md={6}>
                <LabelStyle >What will students learn in your program ?</LabelStyle>
                <Typography variant="body" >{data?.what_will_student_learn}</Typography>
              </Grid>
              <Grid item xs={12} md={6} >
                <LabelStyle >Pre-requisites</LabelStyle>
                <Typography variant="body" >{data?.prerequisite_for_course}</Typography>
              </Grid>
              <GeneralDivider />
              <Grid item xs={12} md={6} >
                <LabelStyle >Program Level</LabelStyle>
                <Typography variant="body" sx={{ textTransform: 'capitalize' }}>{data?.course_level}</Typography>
              </Grid>
              <Grid item xs={12} md={6} >
                <LabelStyle >Program Language</LabelStyle>
                <Typography variant="body" sx={{ textTransform: 'capitalize' }}>
                  English
                  {/* {data?.course_language} */}
                </Typography>
              </Grid>
              <GeneralDivider />
              <Grid item xs={12} md={6} >
                <LabelStyle >Program Category</LabelStyle>
                <Typography variant="body" sx={{ textTransform: 'capitalize' }}>
                  {data?.category}
                  {/* {data?.course_language} */}
                </Typography>
              </Grid>


              {/* information about program */}
              {data?.duration_in_weeks && <Grid item xs={12} md={6} >
                <LabelStyle >Program Duration in weeks </LabelStyle>
                <Typography variant="body" sx={{ textTransform: 'capitalize' }}>{data?.duration_in_weeks} weeks</Typography>
              </Grid>}
              <GeneralDivider />
              {data?.access_post_graduation_in_weeks && <Grid item xs={12} md={6} >
                <LabelStyle >Access post graduation in weeks</LabelStyle>
                <Typography variant="body" sx={{ textTransform: 'capitalize' }}>{data?.access_post_graduation_in_weeks} weeks</Typography>
              </Grid>}
              <Grid item xs={12} md={6} >
                <LabelStyle >Program Type</LabelStyle>
                <Typography variant="body" sx={{ textTransform: 'capitalize' }}>
                  {data?.is_cohort ? <Label color="success">Cohort</Label> : <Label color="info">Evergreen</Label>}
                </Typography>
              </Grid>
              <GeneralDivider />

              <Grid item xs={12} md={6}>
                <LabelStyle >Instructor </LabelStyle>
                <InstructorModal
                  id={data?.instructor}
                  changeInstructor={(p) => { }}
                  viewOnly={true}
                />
                {/* <Typography variant="body" sx={{ textTransform: 'capitalize' }}>{data?.course_language}</Typography> */}
              </Grid>
              <Grid item xs={12} md={6} >
                <LabelStyle >Add Co-Instructor</LabelStyle>
                <Typography variant="body" sx={{ textTransform: 'capitalize' }}>{data?.reviewers?.map((p, index) => <p key={index + 'revviewss'}>{index + 1}. {p?.first_name || '' + " " + p?.last_name || ''}, </p>)}</Typography>
              </Grid>
              <GeneralDivider />
              <Grid item xs={12} md={6} >
                <LabelStyle >Award Certificate on Program Completion</LabelStyle>
                {data.enable_certificate ? <Label color="success">Yes</Label> : <Label color="error">No</Label>}
                {/* <Typography variant="body" sx={{ textTransform: 'capitalize' }}>{data?.course_level}</Typography> */}
              </Grid>
              <Grid item xs={12} md={6} >
                <LabelStyle >Transcript Generation</LabelStyle>
                {data.enable_certificate ? <Label color="success">Yes</Label> : <Label color="error">No</Label>}
                {/* <Typography variant="body" sx={{ textTransform: 'capitalize' }}>{data?.course_level}</Typography> */}
              </Grid>


            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Page>
  )
}

export default BasicView