import * as Yup from "yup";
import { useRef, useState, useEffect } from "react";

// @mui
import {
  Link,
  Stack,
  Alert,
  IconButton,
  InputAdornment,
  Typography,
  Container,
  Card,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// routes
// import { PATH_AUTH } from "../../routes/paths";
// hooks
// import useAuth from "../../hooks/useAuth";
// import useIsMountedRef from "../../hooks/useIsMountedRef";
// components
// import Iconify from "../../components/Iconify";
// import {
//     FormProvider,
//     RHFTextField,
//     RHFCheckbox,
// } from "../../components/hook-form";
import { styled } from "@mui/material/styles";
import Page from "src/components/Page";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import useSettings from "src/hooks/useSettings";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "src/redux/store";
import {
  getVideo,
  updateDocument,
  updateDocumentlink,
  UpdateImage,
  UpdateImagelink,
  updateVideo,
  updateVideolink,
  uploadDocument,
  uploadDocumentlink,
  uploadImage,
  uploadImagelink,
  uploadVideo,
  uploadVideolink,
} from "src/redux/slices/media";
import MediaAttachment from "../MediaAttachment";
import Label from "src/components/Label";
import { useTheme } from "@emotion/react";
import { useSnackbar } from "notistack";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { MEDIA_DOCUMENT } from "src/apiUrl";
import { eduleyApi } from "src/api/apiConfig";
import PublicPrivate from "../component/PublicPrivate";
const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 165,
  fontSize: 18,
  flexShrink: 0,
  color: theme.palette.text.secondary,
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    // backgroundColor: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.primary.main,
  },
}));

const DocumentTypeMedia = ({ loading, afterSubmit, value }) => {
  const [mediatype, setMediaType] = useState("");
  useEffect(() => {
    if (value.media_type) {
      setMediaType(value.media_type);
    }
    // setMediaType(value.media_type);
  }, [value]);
  return (
    <>
      <MediaType
        mediatype={mediatype}
        setMediaType={setMediaType}
        value={value}
      />

      {mediatype === "url" && (
        <MediaURL filetype="document" afterSubmit={afterSubmit} value={value} />
      )}
      {mediatype === "uploaded" && (
        <Uploaded
          filetype="document"
          loading={loading}
          afterSubmit={afterSubmit}
          value={value}
        />
      )}
    </>
  );
};

export default DocumentTypeMedia;

const MediaURL = ({ filetype = "", loading, afterSubmit, value: value2 }) => {
  const [value, setValue] = useState({});
  // const { error } = useSelector((state) => state.media);
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    if (value2?.name) {
      setValue(value2);
    }
  }, [value2]);

  const handleUpdate = async (data) => {
    let res = "";

    if (data.file_type === "document") {
      res = await dispatch(updateDocumentlink(data, data.id));
    }

    if (res === "success") {
      afterSubmit();
      console.log("success");
    }
  };
  const isUrlValid = function (userInput) {
    if (userInput) {
      var res = userInput.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      );
      if (res == null) return false;
      else return true;
    }
  };
  const handleSubmit = async () => {
    // console.log("data--", value);
    let data = { ...value, media_type: "url" };

    if (data) {
      if (!data.name) {
        setError({ ...error, name: "Please enter a title" });
        return;
      }
      if (data.url) {
        if (!isUrlValid(data.url)) {
          setError({ ...error, url: "Invalid Url." });
          return;
        }
      } else {
        setError({ ...error, url: "Please enter a URL." });
      }
    }

    let res = "";
    if (value2.id) {
      handleUpdate({ ...value2, ...data });
      return;
    }

    if (filetype === "image") {
      // console.log("success----", filetype);
      res = await dispatch(uploadImagelink(data));
    }
    if (filetype === "document") {
      console.log("success----", filetype);

      res = await dispatch(uploadDocumentlink(data));
    }
    if (filetype === "video") {
      console.log("success----", filetype);

      res = await dispatch(uploadVideolink(data));
    }
    console.log("success----", res);
    if (res === "success") {
      afterSubmit();
    }
  };
  return (
    <>
      {/* <PublicPrivate
        edit={!!value2.name}
        value={value}
        setType={(o) => setValue({ ...value, is_private: o })}
      /> */}

      <Stack spacing={3} sx={{ mt: 2 }}>
        <InputField
          label="Title"
          value={value.name}
          setValue={(e) => setValue({ ...value, name: e })}
          setErrorText={(e) => {
            setError({ ...error, name: e ? "" : "Please enter a title" });
          }}
        />
      </Stack>
      <Stack>{error.name && <Label color={"error"}>{error.name}</Label>}</Stack>
      <Stack spacing={3}>
        <InputField
          label="Media URL"
          value={value.url}
          setValue={(e) => setValue({ ...value, url: e })}
          setErrorText={(e) => {
            setError({ ...error, name: e ? "" : "Please enter a URL" });
          }}
        />
      </Stack>
      <Stack>{error.url && <Label color={"error"}>{error.url}</Label>}</Stack>
      <SubmitButton
        label="Submit"
        onSubmit={() => handleSubmit()}
        loading={loading}
      />
    </>
  );
};

const Uploaded = ({ filetype, afterSubmit, value: value2 }) => {
  const fileInputRef = useRef(null);
  const [value, setValue] = useState({ is_private: false });
  const [error, setError] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  useEffect(() => {
    if (value2?.name) {
      setValue(value2);
    }
  }, [value2]);
  const [loading, setLoading] = useState(false);
  const [progressdata, setProgressdata] = useState(0);

  const config3 = {
    onUploadProgress: (progressEvent) => {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setProgressdata(percentCompleted);
      console.log(percentCompleted);
    },
  };
  const handleUpdate = async () => {
    var filedata = new FormData();
    let v = { ...value };
    delete v.url;
    // delete v["is_private"];

    let res = "";
    setLoading(true);

    if (value2.media_file !== value.media_file) {
      for (const x in v) {
        filedata.append(x, v[x]);
      }
      filedata.append("media_type", "uploaded");

      try {
        const response = await eduleyApi.patch(
          `${MEDIA_DOCUMENT}${value2.id}/`,
          filedata,
          config3
        );
        // dispatch(slice.actions.getMediaDocumentSuccess(response.data));
        // return "success";
        afterSubmit();
      } catch (error) {
        if (error?.data?.data?.non_field_errors) {
          let er = error?.data?.data?.non_field_errors;
          for (let index = 0; index < er.length; index++) {
            const element = er[index];
            enqueueSnackbar(element, { variant: "error" });
          }
        }
        //   dispatch(slice.actions.hasError(error));
        //   return "error";
      }
      //   };
    } else {
      // filedata = { ...value };
      delete v.media_file;
      // delete filedata["is_private"];
      for (const x in v) {
        filedata.append(x, v[x]);
      }
      try {
        const response = await eduleyApi.patch(
          `${MEDIA_DOCUMENT}${value2.id}/`,
          filedata,
          config3
        );
        // dispatch(slice.actions.getMediaDocumentSuccess(response.data));
        afterSubmit();
        // return "success";
      } catch (error) {
        if (error?.data?.data?.non_field_errors) {
          let er = error?.data?.data?.non_field_errors;
          for (let index = 0; index < er.length; index++) {
            const element = er[index];
            enqueueSnackbar(element, { variant: "error" });
          }
        }
        // dispatch(slice.actions.hasError(error));
        // return "error";
      }
    }
    setLoading(false);
  };
  const hasExtension = function (exts, fileName) {
    var regx = new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$");
    var res = regx.test(fileName);
    return res;
  };

  const handleSubmit = async () => {
    let data = { ...value };
    console.log("data--", data);

    if (value2.id) {
      handleUpdate();
      return;
    }
    console.log("data--", data);

    if (!value.name) {
      setError({ ...error, name: "Please enter a title" });
      return;
    }
    if (!value.media_file) {
      setError({ ...error, media_file: "Please upload a file" });
      return;
    }

    if (filetype === "document") {
      if (
        !hasExtension(
          [
            ".doc",
            ".DOC",
            ".docx",
            ".DOCX",
            ".pdf",
            ".PDF",
            ".txt",
            ".TXT",
            ".xls",
            ".XLS",
            ".xlsx",
            ".XSLX",
            ".ppt",
            ".PPT",
            ".pptx",
            ".PPTX",
          ],
          value.media_file.path
        )
      ) {
        setError({ ...error, media_file: "Please upload a valid document." });
        return;
      }
    }

    var filedata = new FormData();
    for (const x in value) {
      filedata.append(x, value[x]);
    }

    filedata.append("media_type", "uploaded");
    let res = "";
    setLoading(true);

    try {
      const response = await eduleyApi.post(
        `${MEDIA_DOCUMENT}`,
        filedata,
        config3
      );
      afterSubmit();
      // dispatch(slice.actions.getMediaDocumentSuccess(response.data));
      // return "success";
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      // return "error";
    }
    console.log("data--", filedata);

    setLoading(false);
  };

  const handleAttach = () => {
    fileInputRef.current?.click();
  };
  const setfiles = (e) => {
    console.log("e", e);
    if (e != undefined) {
      setValue({ ...value, media_file: e });
    } else {
      setValue({ ...value, media_file: value2.media_file });
    }
  };
  return (
    <>
      <PublicPrivate
        edit={!!value2.name}
        value={value}
        setType={(o) => setValue({ ...value, is_private: o })}
      />
      <Stack spacing={3}>
        <InputField
          label="Title"
          value={value.name || value2.name}
          // error={error.name}
          setValue={(e) => setValue({ ...value, name: e })}
          setErrorText={(e) => {
            setError({ ...error, name: e ? "" : "Please enter a Title" });
          }}
        />
      </Stack>
      <Stack>{error.name && <Label color={"error"}>{error.name}</Label>}</Stack>
      <Stack spacing={3} direction="row" sx={{ mt: 2, mb: 2 }}>
        <LabelStyle sx={{ mt: 2 }}>Choose Media</LabelStyle>

        <MediaAttachment
          filetype={filetype}
          attachments={value2.media_file}
          setfiles={(p) => setfiles(p)}
        />
      </Stack>
      <Stack>
        {error.media_file && (
          <Label color={"warning"}>{error.media_file}</Label>
        )}
      </Stack>

      {loading && (
        <BorderLinearProgress
          variant="determinate"
          sx={{ mt: 2, mb: 2 }}
          // valueBuffer={56}
          color="primary"
          value={progressdata}
        />
      )}
      <Grid container spacing={2}>
        <Grid item md={6} sx={{ display: "flex" }}>
          <LoadingButton
            fullWidth
            variant="contained"
            sx={{ mr: 1 }}
            // loadingIndicator={<CircularProgress value={progressdata} color="inherit" size={16} />}
            onClick={() => {
              if (!loading) {
                handleSubmit();
              }
            }}
            // disabled={loading}
          >
            {loading ? "Please Wait! Uploading" : "Submit"}{" "}
          </LoadingButton>
          {loading && (
            <Typography variant="h4" color="primary">
              {progressdata}%
            </Typography>
          )}
        </Grid>
        <Grid item md={6}></Grid>
      </Grid>
    </>
  );
};

const MediaType = ({ mediatype, setMediaType, value }) => {
  const [contenttype, setContentType] = useState("");

  return (
    <Stack spacing={3} direction="row" sx={{ mt: 2 }}>
      <LabelStyle>Content Type</LabelStyle>
      <Stack spacing={3} direction="row">
        {value?.media_type ? (
          <LabelStyle>{value?.media_type}</LabelStyle>
        ) : (
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="media_type"
            onChange={(e) => setMediaType(e.target.value)}
            value={mediatype}
          >
            <FormControlLabel
              value="uploaded"
              control={<Radio />}
              label="Uploaded"
            />
            <FormControlLabel value="url" control={<Radio />} label="URL" />
          </RadioGroup>
        )}
      </Stack>
    </Stack>
  );
};
const InputField = ({ label, value, setValue, setErrorText, error = "" }) => {
  return (
    <Stack direction="row" sx={{ mt: 4 }}>
      <LabelStyle sx={{ mt: 2 }}>{label}</LabelStyle>
      <TextField
        fullWidth
        rows={1}
        size="medium"
        placeholder={label}
        value={value}
        error={error ? true : false}
        helperText={error ? error : ""}
        onChange={(e) => {
          setValue(e.target.value);
          setErrorText && setErrorText(e.target.value);
        }}
        sx={{ typography: "body2", mt: 1 }}
      />
    </Stack>
  );
};

const SubmitButton = ({ label, onSubmit, loading }) => {
  console.log("submit loading", loading);
  const { media, isLoading } = useSelector((state) => state.media);

  return (
    <LoadingButton
      sx={{ pl: 4, pr: 4 }}
      size="large"
      type="submit"
      variant="contained"
      loading={isLoading}
      onClick={() => onSubmit()}
    >
      {label}
    </LoadingButton>
  );
};
