export const QUALIFICATION_LIST = [
    "Master of Computer Science",
    "Master of Business Administration (MBA)",
    "Master of Electrical Engineering",
    "Master of Public Health",
    "Master of Arts in Psychology",
    "Master of Science in Environmental Science",
    "Master of Education",
    "Master of Fine Arts in Creative Writing",
    "Master of Urban Planning",
    "Master of International Relations",
    "Master of Finance",
    "Master of Social Work",
    "Master of Architecture",
    "Master of Applied Linguistics",
    "Master of Nursing",
    "Master of Data Science",
    "Master of Public Administration",
    "Master of Aerospace Engineering",
    "Master of Human Resource Management",
    "Master of Political Science",
    "Master of Aesthetics and Skincare Science",
    "Bachelor of Science in Computer Science",
    "Master of Cosmetology and Beauty Management",
    "Master of Arts in Beauty and Wellness Studies",
    "Master of Science in Aesthetic Medicine",
    "Master of Fine Arts in Beauty Design",
    "Bachelor of Commerce",
    "Bachelor of Arts in Economics",
    "Bachelor of Engineering in Civil Engineering",
    "Bachelor of Health Sciences",
    "Bachelor of Fine Arts in Visual Arts",
    "Bachelor of Business Administration",
    "Bachelor of Environmental Science",
    "Bachelor of Music",
    "Bachelor of Education",
    "Bachelor of Science in Biology",
    "Bachelor of Social Work",
    "Bachelor of Applied Mathematics",
    "Bachelor of Nursing",
    "Bachelor of Computer Engineering",
    "Bachelor of Psychology",
    "Bachelor of Architecture",
    "Bachelor of Media Studies",
    "Bachelor of International Relations",
    "Bachelor of Mechanical Engineering",
    "BSc in Physics",
    "Ph.D. in Chemistry",
    "Diploma in Electrical Engineering",
    "Certification in Data Science",
    "Associate Degree in Business Administration",
    "MBA in Finance",
    "Bachelor of Arts (BA) in Psychology",
    "Master of Fine Arts (MFA) in Creative Writing",
    "Certified Public Accountant (CPA)",
    "Chartered Financial Analyst (CFA)",
    "Cisco Certified Network Associate (CCNA)",
    "Microsoft Certified Azure Solutions Architect",
    "AWS Certified Solutions Architect",
    "Certification in Project Management (PMP)",
    "Google Certified Professional Cloud Architect",
    "Certified ScrumMaster (CSM)",
    "CompTIA Security+ Certification",
    "Fashion Design and Merchandising Diploma",
    "Bachelor of Fashion Technology (BFT)",
    "Master of Arts in Fashion Design",
    "Certification in Fashion Styling",
    "Fashion Marketing and Management Certificate",
    "Footwear Design and Development Course",
    "Textile Designing Diploma",
    "Jewelry Design and Manufacturing Certification",
    "Fashion Photography Workshop",
    "Makeup Artist Certification",
    "Esthetician License",
    "Hair Stylist Diploma",
    "Nail Technician Certification",
    "Beauty and Fashion Journalism Course",
    "Certificate in Esthetics",
    "Professional Makeup Artist Certification",
    "Advanced Skin Care Specialist Diploma",
    "Cosmetology License Program",
    "Spa Management Certificate",
    "Laser Hair Removal Technician Certification"
];