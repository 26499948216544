import { LoadingButton } from "@mui/lab";
import {


    Checkbox,

    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    IconButton,
    LinearProgress,
    Stack,

    Typography,
    alpha,
    useTheme,
} from "@mui/material";
const ChoosePaymentType = ({ value, handleChange, disabled }) => {
    return (
        <FormControl disabled={disabled} row>
            <FormGroup aria-label="position" row>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={value.fullpayment}
                                    onChange={handleChange}
                                    name="full_payment"
                                    sx={{
                                        mt: -3,
                                        //checkbox size increase
                                        "& .MuiSvgIcon-root": {
                                            fontSize: "5rem",
                                            width: 44,
                                            height: 44,
                                        },
                                    }}
                                />
                            }
                            label={
                                <>
                                    <Typography variant="body1" color={"text.primary"}>
                                        Full Payment
                                    </Typography>
                                    <Typography variant="subtitle" color={"text.secondary"}>
                                        This option enables student will be able to pay the full
                                        program fees at a go
                                    </Typography>
                                </>
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={value.installment}
                                    onChange={handleChange}
                                    name="payment_plan"
                                    sx={{
                                        mt: -3,
                                        //checkbox size increase
                                        "& .MuiSvgIcon-root": {
                                            fontSize: "5rem",
                                            width: 44,
                                            height: 44,
                                        },
                                    }}
                                />
                            }
                            label={
                                <Stack>
                                    <Typography variant="body1" color={"text.primary"}>
                                        Payment Plan
                                    </Typography>
                                    <Typography variant="subtitle" color={"text.secondary"}>
                                        This option enables student will be able to pay the program
                                        fees in installments
                                    </Typography>
                                </Stack>
                            }
                        />
                    </Grid>
                </Grid>
            </FormGroup>
        </FormControl>
    );
};

export default ChoosePaymentType