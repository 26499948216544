import { useEffect, useState } from "react";
// @mui
import { Container, Stack, Button, Checkbox, Typography } from "@mui/material";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
// redux
import { useDispatch, useSelector } from "../../../../redux/store";
import {
  persistColumn,
  persistCard,
  changesection,
  persistCard2,
  getCourse,
  editcourse,
} from "../../../../redux/slices/courseupdate";
// components
import Page from "../../../../components/Page";
import HeaderBreadcrumbs from "../../../../components/HeaderBreadcrumbs";
import { SkeletonKanbanColumn } from "../../../../components/skeleton";
// sections
import { ModuleColumn, ModuleAdd } from "../../addcurriculum";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { CREATE_COURSE } from "src/apiUrl";
import { eduleyApi } from "src/api/apiConfig";
import RoleBasedGuard from "src/guards/RoleBasedGuard";
import { CurriculumView } from "../courseView";
// ----------------------------------------------------------------------

export default function Curriculum({ accessibleRoles = [] }) {
  const [loading, setLoading] = useState(false);
  const [addcourse, setAddCourse] = useState({});
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const {
    board,
    addcourse: course,
    module,
    loadingtoform,
  } = useSelector((state) => state.courseupdate);
  useEffect(() => {
    setAddCourse(course);
  }, [course]);

  useEffect(() => {
    dispatch(getCourse(addcourse?.firstform?.id));
  }, [dispatch]);
  const handleNext = () => {
    dispatch(changesection(2));
  };
  const handleBack = () => {
    dispatch(changesection(0));
  };
  const onDragEnd = (result) => {
    // Reorder card
    const { destination, source, draggableId, type } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    if (type === "column") {
      const newColumnOrder = Array.from(board.columnOrder);
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggableId);
      console.log(module, "-----------newColumnOrder");

      let m = newColumnOrder.map((id) => {
        let l = [...module].find((m) => m.id === id);

        return { ...l };
      });
      // console.log(m, "m");
      let d = { id: addcourse?.firstform?.id, section: m };
      // console.log("newcolold");
      dispatch(persistColumn(newColumnOrder, d));
      return;
    }

    const start = board.columns[source.droppableId];
    const finish = board.columns[destination.droppableId];
    // console.log("start", start, "finish", finish);

    if (start.id === finish.id) {
      const updatedCardIds = [...start.cardIds];
      updatedCardIds.splice(source.index, 1);
      updatedCardIds.splice(destination.index, 0, draggableId);

      const updatedColumn = {
        ...start,
        cardIds: updatedCardIds,
      };

      let section = [...module].find((m) => m.id === start.id);
      let content = [];
      let l = [...updatedColumn.cardIds].map((l) => {
        let c = [...section.content].find((c) => c.id === l);
        content.push({ ...c, section: start.id });
      });

      // console.log(content, "m");
      // console.log("updatedColumn--", updatedColumn);
      let d = {
        id: addcourse?.firstform?.id,
        section: [{ id: start.id, content: content }],
      };

      dispatch(
        persistCard(
          {
            ...board.columns,
            [updatedColumn.id]: updatedColumn,
          },
          d
        )
      );
      return;
    }

    const startCardIds = [...start.cardIds];
    startCardIds.splice(source.index, 1);
    const updatedStart = {
      ...start,
      cardIds: startCardIds,
    };

    const finishCardIds = [...finish.cardIds];
    finishCardIds.splice(destination.index, 0, draggableId);
    const updatedFinish = {
      ...finish,
      cardIds: finishCardIds,
    };

    // let section = [...module].find((m) => m.id === start.id);
    let content = [];
    let l = [...finishCardIds].map((l) => {
      let c = board.cards[l];
      content.push({ ...c, section: finish.id });
    });
    // console.log("content=====", content);
    // console.log("updatedFinish=====", updatedFinish);
    // console.log("updatedStart=====", draggableId);
    let d = {
      id: addcourse?.firstform?.id,
      section: [{ id: finish.id, content: content }],
    };
    // let changesection={id:}
    dispatch(
      persistCard2(
        {
          ...board.columns,
          [updatedStart.id]: updatedStart,
          [updatedFinish.id]: updatedFinish,
        },
        d
      )
    );
  };
  const { enqueueSnackbar } = useSnackbar();
  const handleNextwithordering = async () => {
    setLoading(true);
    let c = addcourse?.firstform;
    let s = c.section.map((a, i) => {
      let b = [...a.content];

      b = b.map((c, inx) => {
        return { ...c, index: inx + 1 };
      });
      let d1 = {
        id: c.id,
        section: [{ id: a.id, content: b }],
      };
      // dispatch(persistCard({ ...board.columns }, d1));
      return { ...a, index: i + 1, content: b };
    });
    let d = { id: c.id, section: s };
    console.log("section", s);
    const validation = d.section.every((item) => item.content.length > 0);
    if (!validation) {
      setLoading(false);
      enqueueSnackbar("Section can not be added without any content", {
        variant: "error",
      });
      return;
    }
    let newc = s.map((a) => a.id);

    let res = await dispatch(persistColumn(newc, d));
    setLoading(false);
    handleNext();
  };

  const handlefreetrial = async () => {
    let p = {
      ...course.firstform,
      offer_first_section_free: !course.firstform.offer_first_section_free,
    };
    try {
      const response = await eduleyApi.patch(`${CREATE_COURSE}${p.id}/`, p);
      dispatch(getCourse(addcourse?.firstform?.id));
    } catch (error) {
      console.log("err--", error);
      if (error.data.data.non_field_errors[0]) {
        enqueueSnackbar(error.data.data.non_field_errors[0], {
          variant: "error",
        });
      }
    }
  };

  return (
    <Page title="Curriculum" sx={{ height: 1 }}>
      <Container maxWidth={false} sx={{ height: 1 }}>
        <HeaderBreadcrumbs
          heading="Curriculum"
          links={[
            {
              name: "Add Sections & Lessons",
            },
          ]}
        />
        <RoleBasedGuard accessibleRoles={accessibleRoles} noPermissionChildren={<>
          <CurriculumView data={course.firstform} />
        </>}>


          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable
              droppableId="all-columns"
              direction="vertical"
              type="column"
            >
              {(provided) => (
                <Stack
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  direction="column"
                  alignItems="flex"
                  spacing={3}
                  sx={{ height: "calc(100% - 32px)", overflowY: "hidden", pt: 1 }}
                >
                  {board.columnOrder?.map((columnId, index) => (
                    <ModuleColumn
                      index={index}
                      key={columnId}
                      column={board.columns[columnId]}
                    />
                  ))}

                  {provided.placeholder}
                  <ModuleAdd
                    id={addcourse?.firstform?.id}
                    index={addcourse?.firstform?.section?.length + 1}
                  />
                </Stack>
              )}
            </Droppable>
          </DragDropContext>


          <Stack sx={{ mt: 3 }} direction="row" justifyContent="space-between">
            <Stack item xs={12} md={3}>
              <Button variant="contained" onClick={() => handleBack()} size="large">
                Back
              </Button>
            </Stack>
            <Stack direction={"row"}>
              <Stack sx={{ mt: 1, mr: 2 }} direction="row">
                <Checkbox
                  disabled={course.firstform.is_course_free}
                  checked={!!course.firstform.offer_first_section_free}
                  onChange={(e) => {
                    handlefreetrial();

                    // setTrailSection(!trailsection);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{ mt: 0 }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    mt: 1,
                  }}
                  color={course.firstform.is_course_free ? "text.secondary" : ""}
                >
                  Offer the first section of the program as free
                </Typography>
              </Stack>
              <LoadingButton
                variant="contained"
                onClick={() => handleNextwithordering()}
                size="large"
                loading={loading}
                disabled={loading}
              >
                Next
              </LoadingButton>
            </Stack>
          </Stack>
        </RoleBasedGuard>
      </Container>
    </Page>

  );
}
