import { useContext } from 'react';
//
import { FeeContext } from './FeeContext';
// import { AuthContext } from '../contexts/FirebaseContext';
// import { AuthContext } from '../contexts/Auth0Context';
// import { AuthContext } from '../contexts/AwsCognitoContext';

// ----------------------------------------------------------------------

const useFeeStructure = () => {
  const context = useContext(FeeContext);

  if (!context) throw new Error('Auth context must be use inside AuthProvider');

  return context;
};

export default useFeeStructure;
