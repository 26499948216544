import { useState } from "react";
// @mui
import { Box, Button, MenuItem, Typography } from "@mui/material";
// redux
import { useDispatch, useSelector } from "src/redux/store";
import { sortByProducts } from "src/redux/slices/course";
// components
import Iconify from "src/components/Iconify";
import MenuPopover from "src/components/MenuPopover";

// ----------------------------------------------------------------------

const SORT_BY_OPTIONS = [
  { value: "", label: "All" },
  { value: "document", label: "Document" },
  { value: "image", label: "Image" },
  { value: "video", label: "Video" },
];

function renderLabel(label) {
  if (label === "document") {
    return "Document";
  }
  if (label === "image") {
    return "Image";
  }
  if (label === "video") {
    return "Video";
  }
  return "";
}

// ----------------------------------------------------------------------

export default function MediaListSort({ sortMedia }) {
//   const { sortBy } = useSelector((state) => state.c);

  const [open, setOpen] = useState(null);
  const [sortBy, setSortBy] = useState("");

  const handleOpen = (currentTarget) => {
    setOpen(currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleSortBy = (value) => {
    handleClose();
    console.log("value", value);
    sortMedia(value);
    setSortBy(value);
    // dispatch(sortByProducts(value));
  };

  return (
    <Box>
      <Button
        color="inherit"
        disableRipple
        onClick={(event) => handleOpen(event.currentTarget)}
        endIcon={
          <Iconify
            icon={open ? "eva:chevron-up-fill" : "eva:chevron-down-fill"}
          />
        }
      >
        file type:&nbsp;
        <Typography
          component="span"
          variant="subtitle2"
          sx={{ color: "text.secondary" }}
        >
          {renderLabel(sortBy)}
        </Typography>
      </Button>

      <MenuPopover
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleClose}
        sx={{
          width: "auto",
          "& .MuiMenuItem-root": { typography: "body2", borderRadius: 0.75 },
        }}
      >
        {SORT_BY_OPTIONS.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === sortBy}
            onClick={() => handleSortBy(option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
      </MenuPopover>
    </Box>
  );
}
