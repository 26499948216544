import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import IconifyButton from './IconifyButton';
const ClipboardCopy = ({ text, ...other }) => {
    // const [text, setText] = useState('Copy this text');
    const { enqueueSnackbar } = useSnackbar();

    const copyToClipboardFallback = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            enqueueSnackbar('Text copied to clipboard!');
            // alert(`Copied text: ${text}`);
        } catch (err) {
            // console.error('Failed to copy text: ', err);
        }


    };

    return (
        <IconifyButton icon="carbon:copy" title="Copy to Clipboard" onClick={() => copyToClipboardFallback(text)} {...other} />
    );
};

export default ClipboardCopy;
