import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Typography,
  ToggleButton,
  useTheme,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ToggleButtonGroup,
  Drawer,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { eduleyApi } from "src/api/apiConfig";
import {
  COURSE_CUSTOM_TRANSCRIPT,
  CREATE_COURSE,
  SCOREWEIGHTAGE,
} from "src/apiUrl";
import Avatar from "src/components/Avatar";

import Iconify from "src/components/Iconify";
import IconifyButton from "src/components/IconifyButton";
import Label from "src/components/Label";
import LoadingIcon from "src/components/LoadingIcon";
import Scrollbar from "src/components/Scrollbar";
import { IconButtonAnimate } from "src/components/animate";
import useAuth from "src/hooks/useAuth";
import useResponsive from "src/hooks/useResponsive";
import AlertDialog from "src/pages/AlertDialog";
import { changeconfiguration, getCourse } from "src/redux/slices/courseupdate";
import { useDispatch, useSelector } from "src/redux/store";
import CustomScoreSetup from "./CustomScoreSetup";
import AddNewCustomScore from "./AddNewCustomScore";
import AddNewCustomScoreModal from "./AddNewCustomScoreModal";
import RoleBasedGuard from "src/guards/RoleBasedGuard";
import DisableCustomScoreView from "../ConfigurationView/DisableCustomScoreView";

const CustomScoreDetails = ({ handleCloseDrawer, accessibleRoles }) => {
  const [data, setData] = useState([]);
  const [serverCostomsocre, setSeverCostomscore] = useState([]);
  const [data1, setData1] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isDesktop = useResponsive("up", "sm");
  const [openAlert, setOpenAlert] = useState(false);

  const { addcourse, configuration } = useSelector(
    (state) => state.courseupdate
  );
  // check data1 error and write message if total score is null or undefined or less than 0 or equal to 0
  const error = useMemo(() => {
    let error = false;
    data1.forEach((a) => {
      if (!(a.total_score > 0)) {
        error = true;
      }
    });
    return error;
  }, [data1]);

  useEffect(() => {
    getCustumTranscript();
    updateCustumTranscript();
  }, []);

  const getCustumTranscript = async () => {
    setLoading(true);
    try {
      let res = await eduleyApi.get(
        `${SCOREWEIGHTAGE}?course=${addcourse.firstform.id}`
      );
      //   enqueueSnackbar(JSON.stringify(res));
      setData(res.data);
      console.log("COURSE CUSTOM SCORE", res);
    } catch (error) { }
    setLoading(false);
  };

  const updateCustumTranscript = async () => {
    setLoading(true);
    try {
      let res = await eduleyApi.get(
        `${COURSE_CUSTOM_TRANSCRIPT}?course=${addcourse.firstform.id}`
      );
      setData1(res.data);
      setOriginalData(res.data);
      dispatch(
        changeconfiguration({
          customweightage: {
            status: "success",
          },
        })
      );
      setSeverCostomscore(res.data);
      // console.log("COURSE CUSTOM SCORE", res);
    } catch (error) { }
    setLoading(false);
  };
  const handleadding = async (a) => {
    setLoading(true);
    const payload = [...data1].map((a) => {
      return {
        id: a?.id,
        weightage: a.weightage,
        total_score: a.total_score,
        course: addcourse.firstform.id,
        institute_transcript_weightage: a.institute_transcript_weightage.id,
      };
    });
    try {
      let res = await eduleyApi.post(
        `${COURSE_CUSTOM_TRANSCRIPT}?course=${addcourse.firstform.id}`,
        payload
      );
      // setData1(res.data);
      // chages successfully message
      dispatch(
        changeconfiguration({
          customweightage: {
            status: "success",
          },
        })
      );

      enqueueSnackbar("Changes saved successfully");

      getCustumTranscript();
      updateCustumTranscript();
      handleCloseDrawer();
      // console.log("COURSE CUSTOM SCORE", res);
    } catch (error) {
      // console.log(error.data.data);
      if (error?.data?.data?.non_fields_errors)
        enqueueSnackbar(error?.data?.data?.non_fields_errors, {
          variant: "error",
        });
    }
    setLoading(false);
  };

  const calculateCustomWeightage = useMemo(() => {
    let p = data1.reduce((a, b) => {
      return +a + +(b.weightage || 0);
    }, 0);
    if (p > 0 && p < 100) {
      dispatch(
        changeconfiguration({
          customweightage: {
            status: "pending",
          },
        })
      );
    }
    return p;
  }, [data1]);

  const selected = useMemo(() => {
    return addcourse.firstform?.configure_custom_score;
  }, [addcourse.firstform]);

  const handlecourseupdate = async (p) => {
    setLoading(true);

    try {
      const response = await eduleyApi.patch(`${CREATE_COURSE}${p.id}/`, p);
      dispatch(getCourse(addcourse.firstform.id));
      getCustumTranscript();
      updateCustumTranscript();
    } catch (e) {
      console.log("error", e);
      enqueueSnackbar("Error", {
        variant: "error",
      });
    }
    setLoading(false);
  };

  const handleSetWeightage = (e, index) => {
    setData1((prev) => {
      let data = [...prev];
      data[index].weightage = e;
      return data;
    });
  };
  const handleSetTotalScore = (e, index) => {
    setData1((prev) => {
      let data = [...prev];
      data[index].total_score = e;
      return data;
    });
  };
  const handleDeleteScore = (a) => {
    console.log("object", data, a);

    setData((prev) => {
      let data = [...prev];
      data.push({
        ...a.institute_transcript_weightage,
      });
      return data;
    });
    setData1((prev) => {
      let data = [...prev];
      data = data.filter(
        (b) =>
          b.institute_transcript_weightage.id !==
          a.institute_transcript_weightage.id
      );
      return data;
    });
    // delete message
    enqueueSnackbar(' Click on "Save Changes" button to save the changes.');
  };
  const handleAddingWeightage = (a) => {
    setData1((prev) => {
      let data = [...prev];
      let value = originalData.find(
        (b) => b?.institute_transcript_weightage?.id === a.id
      ) || {
        total_score: 0,
        weightage: 0,
        can_edit_weightage: true,
        institute_transcript_weightage: { ...a },
      };
      console.log("object", originalData, value, a);
      data.push(value);
      return data;
    });
    setData((prev) => {
      let data = [...prev];
      data = data.filter((b) => b.id !== a.id);
      return data;
    });
    // enqueueSnackbar("Custom score added successfully !");

    // update weightage to 100 message
    enqueueSnackbar(
      'Please make sure the total weightage is equal to 100% and click on "Save Changes" button to save the changes.',
      { variant: "info" }
    );
  };
  const configurescore = () => (
    <>
      <CardContent>
        {/* disabled view of card content  */}
        <Card
          sx={{
            width: "100%",
            minHeight: 450,

            boxShadow: "none",
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row-reverse" }}
            divider={
              <Divider
                orientation={isDesktop ? "vertical" : "horizontal"}
                flexItem
                sx={{ borderStyle: "dashed" }}
              />
            }
            sx={{
              pt: -4,
            }}
          >
            <Stack sx={{ width: "75%", height: "100%" }}>
              <CardHeader
                title={
                  !loading &&
                  (data1?.length <= 0
                    ? data.length <= 0
                      ? "No custom scores configured for this institute."
                      : "No custom scores applied for this course"
                    : "  Scores selected for this course")
                }
              />
              <CardContent sx={{ pb: 5 }}>
                <Scrollbar style={{ maxHeight: 400 }}>
                  {data1?.map((a, index) => (
                    <>
                      <CustomScoreSetup
                        a={a}
                        index={index}
                        total_score={a.total_score}
                        weightage={a.weightage}
                        setWeightage={(e) => handleSetWeightage(e, index)}
                        setTotalscore={(e) => handleSetTotalScore(e, index)}
                        handleDelete={() => handleDeleteScore(a)}
                      />
                      {/* <TextField value={"0"} /> */}
                    </>
                  ))}
                </Scrollbar>
                {calculateCustomWeightage === 0 && (
                  <Typography variant="caption" color={"info.main"}>
                    GPA Score for students will not be calculated as the total
                    weightage is 0%.
                  </Typography>
                )}
                <Divider sx={{ mt: 0 }} />

                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"flex-end"}
                  sx={{ mt: 2 }}
                >
                  {/* show total  */}
                  {data1?.length > 0 && (
                    <Stack direction={"column"} spacing={1}>
                      <Typography
                        variant="h6"
                        sx={{ textAlign: "right" }}
                        color={"text.secondary"}
                      >
                        Total Weightage = {calculateCustomWeightage}%
                      </Typography>

                      {data1?.length > 0 &&
                        calculateCustomWeightage !== 100 &&
                        calculateCustomWeightage !== 0 && (
                          <Typography variant="body2" color={"error.main"}>
                            The total % should be equal to 100%
                          </Typography>
                        )}
                    </Stack>
                  )}
                </Stack>
              </CardContent>
            </Stack>
            <Stack
              direction="column"
              // alignItems="center"
              // justifyContent="center"
              // spacing={3}
              sx={{ width: "25%" }}
            >
              <CardHeader title={"Add custom score to this program"} />
              <CardContent>
                {data?.length <= 0 && (
                  <Typography variant="body2" color={"text.secondary"}>
                    No new custom scores available for this program.
                  </Typography>
                )}
                <Scrollbar style={{ maxHeight: 300 }}>
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      bgcolor: "background.paper",
                    }}
                  >
                    {data?.map((a) => (
                      <AddNewCustomScore
                        a={a}
                        handleAdding={() => handleAddingWeightage(a)}
                      />
                    ))}
                  </List>
                </Scrollbar>
              </CardContent>
              <CardActions
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "end",
                  bottom: 12,
                  // position: "absolute",
                  width: "100%",
                  // backgroundColor: "red",
                }}
              >
                <AddNewCustomScoreModal
                  handleSuccess={() => {
                    getCustumTranscript();
                    updateCustumTranscript();
                  }}
                />
              </CardActions>
            </Stack>
          </Stack>
        </Card>
      </CardContent>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          mt: 1,
        }}
      >
        {(data1.length > 0 || serverCostomsocre.length > 0) && (
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={handleadding}
            loading={loading}
            disabled={
              data1.length > 0
                ? calculateCustomWeightage !== 100 &&
                calculateCustomWeightage !== 0
                : false
            }
          >
            Save Changes
          </LoadingButton>
        )}
      </Box>
    </>
  );
  const handleCancelAlertDialog = () => {
    setOpenAlert(false);
  };
  const handleOkByAlertDialog = () => {
    handleCancelAlertDialog();
    handlecourseupdate({
      ...addcourse.firstform,
      configure_custom_score: !selected,
    });
  };
  return (
    <>
      <Drawer
        open={true}
        onClose={handleCloseDrawer}
        anchor={"right"}
        // configure width of drawer
        sx={{
          "& .MuiDrawer-paper": {
            width: "70%",
            // maxWidth: 500,
            padding: 2,
          },
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Tooltip title="Go back">
            <IconButtonAnimate onClick={handleCloseDrawer} size="small">
              <Iconify
                icon={"material-symbols:arrow-back-rounded"}
                width={20}
                height={20}
              />
            </IconButtonAnimate>
          </Tooltip>
        </Stack>
        <RoleBasedGuard accessibleRoles={accessibleRoles} noPermissionChildren={<DisableCustomScoreView data={data1} disableCustomScore={!selected} />}>

          <CardHeader
            title=" Configure Scores"
            // subheader={
            //   <Typography variant="caption">
            //     Custom scores are scores that are configured for the calculation
            //     of the student's GPA final score. You can add/update any of the
            //     custom scores to this course.
            //   </Typography>
            // }
            action={
              loading ? (
                <LoadingIcon loading={loading} />
              ) : (
                <>
                  <ToggleButtonGroup
                    color="primary"
                    value={selected ? "Yes" : "No"}
                    disabled={loading}
                    exclusive
                    onChange={() => {
                      if (selected) {
                        setOpenAlert(true);
                      } else {
                        handlecourseupdate({
                          ...addcourse.firstform,
                          configure_custom_score: !selected,
                        });
                      }
                    }}
                  >
                    <ToggleButton value="Yes">Yes</ToggleButton>
                    <ToggleButton value="No">No</ToggleButton>
                  </ToggleButtonGroup>
                  {openAlert && (
                    <AlertDialog
                      dialogTitle={"Remove Custom Scores"}
                      dialogContent={
                        <Typography variant="body2" color={"text.secondary"}>
                          All the custom score configuration for this program will
                          be removed. Are you sure you want to do this?
                        </Typography>
                      }
                      onOkClick={handleOkByAlertDialog}
                      onCancleClick={handleCancelAlertDialog}
                      isOpen={openAlert}
                    />
                  )}
                </>
              )
            }
          />
          {selected ? (
            configurescore()
          ) : (
            <Card
              sx={{
                mt: 5,
                mb: 5,
                backgroundColor: "background.default",
                // boxShadow: "none",
              }}
            >
              <CardHeader title="Information" />
              <CardContent>
                <Typography variant="body2">
                  Scores are automatically distributed with 50% assigned to
                  quizzes and 50% to assignments. If this doesn't align with your
                  setup, you can customize your scoring pattern here. Simply
                  select <b>'Yes'</b> to configure it now!
                </Typography>
                <List>
                  <ListItem
                    sx={{
                      alignItems: "flex-start",
                    }}
                  >
                    <ListItemIcon sx={{ mt: 0.5 }}>
                      <Iconify icon="fluent-mdl2:radio-bullet" />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        variant: "body2",
                      }}
                      primary="In cases where there are no quizzes, the entire weightage of 100% is allocated to assignments. Conversely, if there are no assignments, the full 100% weightage is applied to quizzes."
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <Iconify icon="fluent-mdl2:radio-bullet" />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        variant: "body2",
                      }}
                      primary={
                        "If there are no assignments or quizzes present, neither of them will be factored into the GPA scores."
                      }
                    />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          )}
        </RoleBasedGuard>
      </Drawer>
    </>
  );
};

export default CustomScoreDetails;
