import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { DialogAnimate } from "src/components/animate";
import RoleBasedGuard from "src/guards/RoleBasedGuard";
import { closewithoutsave } from "src/redux/slices/lesson";

const ClosewithoutSave = ({ oncancel, onok }) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closewithoutsave());
  };
  const handleok = () => {
    dispatch(closewithoutsave());
    onok();
  };
  const handelCancel = () => {
    dispatch(closewithoutsave());
    oncancel();
  };
  return (
    <Dialog fullWidth maxWidth="sm" open={true} onClose={handleClose}>
      <DialogTitle>
        <Typography variant="h6">Save content </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mt: 1 }}>
          Do you want to save the content ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box sx={{ flexGrow: 1 }} />

        <Button variant="outlined" color="inherit" onClick={handelCancel}>
          No
        </Button>
        <RoleBasedGuard accessibleRoles={[1, 2]} noPermissionChildren={<>
          <Button variant="contained" color="info" onClick={handelCancel}>
            Cancel
          </Button>
        </>}>
          <LoadingButton
            type="submit"
            variant="contained"
            loadingIndicator="Loading..."
            onClick={() => handleok()}
          >
            Yes
          </LoadingButton>
        </RoleBasedGuard>
      </DialogActions>
    </Dialog>
  );
};

export default ClosewithoutSave;
