import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Typography,
  ToggleButton,
  useTheme,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  alpha,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { eduleyApi } from "src/api/apiConfig";
import { SCOREWEIGHTAGE } from "src/apiUrl";
import Avatar from "src/components/Avatar";

import Iconify from "src/components/Iconify";
import IconifyButton from "src/components/IconifyButton";
import Label from "src/components/Label";
import LoadingIcon from "src/components/LoadingIcon";
import Scrollbar from "src/components/Scrollbar";
import { IconButtonAnimate } from "src/components/animate";
import useAuth from "src/hooks/useAuth";
import useResponsive from "src/hooks/useResponsive";
import AlertDialog from "src/pages/AlertDialog";
import { changeconfiguration, getCourse } from "src/redux/slices/courseupdate";
import { useDispatch, useSelector } from "src/redux/store";

const AddNewCustomScoreModal = ({ handleSuccess }) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState({
    name: "",
  });
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const { user } = useAuth();
  useEffect(() => {
    getScoreWeightage();
  }, []);

  const getScoreWeightage = async () => {
    setLoading(true);
    try {
      let res = await eduleyApi.get(`${SCOREWEIGHTAGE}`);
      // console.log(res);
      setData(res.data);
    } catch (error) {}
    setLoading(false);
  };

  const saveWeightage = async () => {
    setLoading(true);
    let payload = [...data, { name: value.name, default_weightage: 0 }];
    try {
      let res = await eduleyApi.post(`${SCOREWEIGHTAGE}`, payload);
      // console.log(res);
      enqueueSnackbar(" Updated successfully");
      setData(res.data);
      handleSuccess();
      setOpen(false);
    } catch (error) {
      // console.log("er-------", error);
      enqueueSnackbar(error.data.data.non_fields_errors, { variant: "error" });
    }
    setLoading(false);
  };
  return (
    <>
      {user.groups.includes(2) && (
        <Button
          sx={{ mt: 3 }}
          startIcon={<Iconify icon={"mdi:plus"} color={"white"} />}
          variant="outlined"
          onClick={() => {
            setOpen(true);
            setValue({ name: "" });
          }}
        >
          New custom score
        </Button>
      )}
      {open && (
        <Dialog
          open={open}
          fullWidth
          onClose={() => setOpen(false)}
          maxWidth="xs"
        >
          <DialogTitle>Add new custom score</DialogTitle>
          <DialogContent sx={{ pt: 3 }}>
            <TextField
              fullWidth
              sx={{ mt: 3 }}
              label={"Enter the name of the custom score"}
              value={value.name}
              type="text"
              onChange={(e) =>
                setValue((prev) => ({
                  ...prev,
                  name: e.target.value,
                }))
              }
            />
          </DialogContent>
          <DialogActions>
            <LoadingButton
              variant="contained"
              onClick={() => saveWeightage()}
              disabled={value.name.length === 0}
              loading={loading}
            >
              Save
            </LoadingButton>
            <Button variant="outlined" onClick={() => setOpen(false)}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
export default AddNewCustomScoreModal;
