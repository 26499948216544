import { Suspense, lazy } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
// guards
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import AssignmentQuestionReview from 'src/pages/dashboard/AssignmentTrack/AssignmentQuestionReview';
import HomeworkQuestionReview from 'src/pages/dashboard/AssignmentTrack/HomeworkQuestionReview';
import ProfileEnrichment from 'src/pages/dashboard/Configuration/profileEnrichment';
import ViewCourse from 'src/pages/dashboard/ViewCourse';
import { FeeProvider } from 'src/sections/@dashboard/configuration/fee_structure/FeeContext';
import LoadingScreen from '../components/LoadingScreen';
import { CommissionPayout, InstitutePayout } from '../pages/dashboard/payout';
// import Media from "src/pages/dashboard/Media";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/program')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> }
      ]
    },

    // Dashboard Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        // { element: <GeneralCourse />, index: true },
        {
          path: 'program',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 3, 4, 5, 6, 7, 8, 9]}>
              <GeneralCourse />
            </RoleBasedGuard>
          )
        },
        {
          path: 'program-management',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 3, 4, 5, 6, 9, 10]}>
              <ProgramManagement />
            </RoleBasedGuard>
          )
        },
        {
          path: 'cohort',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 3, 6, 7, 9, 5]}>
              <Cohorts />
            </RoleBasedGuard>
          )
        },
        {
          path: 'cohort-management',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 4, 3, 9]}>
              <CohortManagement />
            </RoleBasedGuard>
          )
        },

        {
          path: 'add-program',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 3]}>
              <AddCourse />
            </RoleBasedGuard>
          )
        },
        {
          path: 'edit-program/:id',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 3, 10]}>
              <EditCourse />
            </RoleBasedGuard>
          )
        },
        {
          path: 'view-program/:id',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 3, 5, 6, 8, 9]}>
              <FeeProvider>
                <ViewCourse />
              </FeeProvider>
            </RoleBasedGuard>
          )
        },
        {
          path: 'student-profile',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 6, 4, 9]}>
              <StudentMenu />
            </RoleBasedGuard>
          )
        },
        {
          path: 'student-profile/:id',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 6, 4, 9]}>
              <StudentDetails />
            </RoleBasedGuard>
          )
        },
        {
          path: 'student/course-report/:course-:id',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 3, 6, 9]}>
              <StudentCourseView />
            </RoleBasedGuard>
          )
        },
        {
          path: 'discussion-board',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 3, 9, 5]}>
              <ForumsMenu />
            </RoleBasedGuard>
          )
        },
        {
          path: 'grades/quiz',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 3, 5]}>
              <QuizTrack />
            </RoleBasedGuard>
          )
        },
        {
          path: 'grades/quiz/:id',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 3, 5]}>
              <QuizTrackbyId />
            </RoleBasedGuard>
          )
        },
        {
          path: 'grades/quota',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 3, 5]}>
              <AssignmentTrack />
            </RoleBasedGuard>
          )
        },
        {
          path: 'grades/homework',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 3, 5]}>
              <HomeworkTrack />
            </RoleBasedGuard>
          )
        },

        {
          path: 'grades/homework/:id',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 3, 5]}>
              <HomeworkQuestionReview />
            </RoleBasedGuard>
          )
        },
        {
          path: 'grades/quota/:id',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 3, 5]}>
              <AssignmentQuestionReview />
            </RoleBasedGuard>
          )
        },

        // media
        // ----------------------------------------------------------------------
        //  2, name: 'Institute Admin',
        //  3, name: 'Instructor',
        //  4, name: 'Accounts',
        //  5, name: 'Reviewer',
        //  6, name: 'Counsellor',
        {
          path: 'media-management',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 4]}>
              <Media />
            </RoleBasedGuard>
          )
        },
        // analytics
        //   { path: "analytics", element:

        //   <GeneralAnalytics />

        // },
        {
          path: 'analytics/program-performance',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 4, 3, 5, 6]}>
              <SubscriptionsReport />
            </RoleBasedGuard>
          )
        },
        {
          path: 'analytics/subscriptions/:id',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 4, 3, 5, 6]}>
              <SubscriptionsReportbyID />
            </RoleBasedGuard>
          )
        },
        {
          path: 'analytics/revenue',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 4]}>
              <RevenueList />
            </RoleBasedGuard>
          )
        },
        {
          path: 'analytics/program/:id',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 3, 5]}>
              <AnalyticsClassroomOverview />
            </RoleBasedGuard>
          )
        },
        {
          path: 'analytics/program/:id/cohort/:cohort',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 3, 5]}>
              <AnalyticsClassroomOverview />
            </RoleBasedGuard>
          )
        },
        {
          path: 'analytics/progress',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 6]}>
              <CourseProgress />
            </RoleBasedGuard>
          )
        },
        {
          path: 'analytics/gamification',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 6]}>
              <GamificationAnalytics />
            </RoleBasedGuard>
          )
        },
        {
          path: 'analytics/attendance',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 3, 4]}>
              <CohortAttendanceAnalytics />
            </RoleBasedGuard>
          )
        },
        {
          path: 'analytics/attendance/course/:course/cohort/:cohort',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 3, 4]}>
              <CohortAttendanceReport />
            </RoleBasedGuard>
          )
        },
        {
          path: 'analytics/attendance/course/:course/cohort/:cohort/student/:student',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 3]}>
              <CohortAttendanceReport />
            </RoleBasedGuard>
          )
        },
        {
          path: 'analytics/instructor',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 3]}>
              <InstructorAttendanceAnalytics />
            </RoleBasedGuard>
          )
        },
        {
          path: 'support/student-support',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 3, 6]}>
              {' '}
              <StudentSupport />{' '}
            </RoleBasedGuard>
          )
        },
        {
          path: 'support/eduley-support',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 3, 6]}>
              <EduleySupport />
            </RoleBasedGuard>
          )
        },
        {
          path: 'platform-management/statement',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 4]}>
              <FundStatement />
            </RoleBasedGuard>
          )
        },
        {
          path: 'platform-management/payout',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 4]}>
              <InstitutePayout />
            </RoleBasedGuard>
          )
        },
        {
          path: 'platform-management/eduley',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 4]}>
              <CommissionPayout />
            </RoleBasedGuard>
          )
        },
        {
          path: 'configuration/theme',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2]}>
              <Configuration />
            </RoleBasedGuard>
          )
        },
        {
          path: 'configuration/paymentmethod',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2]}>
              <PaymentMethod />
            </RoleBasedGuard>
          )
        },
        {
          path: 'configuration/gamification',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2]}>
              <Gamification />
            </RoleBasedGuard>
          )
        },
        {
          path: 'configuration/support-teams',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2]}>
              <SupportEmail />
            </RoleBasedGuard>
          )
        },
        {
          path: 'configuration/score_weightage',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2]}>
              <ScoreWeightage />
            </RoleBasedGuard>
          )
        },

        {
          path: 'configuration/student-finance',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2]}>
              <StudentFinance />
            </RoleBasedGuard>
          )
        },
        {
          path: 'configuration/program-enrollment-documents',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 4, 6]}>
              <EnrollmentDocuments />
            </RoleBasedGuard>
          )
        },
        {
          path: 'configuration/student-profile-enrichment',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 4, 6]}>
              <ProfileEnrichment />
            </RoleBasedGuard>
          )
        },
        // { path: "theme/institute", element: <InstituteConfig /> },
        // { path: "theme/landingpage", element: <LandingPageConfig /> },

        {
          path: 'staff-management',
          children: [
            {
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <UserList />
                </RoleBasedGuard>
              ),
              index: true
            },
            // { path: "profile", element: <UserProfile /> },
            // { path: "cards", element: <UserCards /> },

            {
              path: 'new',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <UserCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: ':name/edit',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <UserCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: ':name/profile',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}>
                  <ProfileDetails />
                </RoleBasedGuard>
              )
            }
            //       { path: "account", element: <UserAccount /> },
          ]
        },
        {
          path: 'instructor/list',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 6]}>
              <InstructorList />
            </RoleBasedGuard>
          )
        },
        {
          path: '/student-progress',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 3, 8, 5, 9]}>
              <SubscriptionList />
            </RoleBasedGuard>
          )
        },
        {
          path: 'paymentplan',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 4, 6, 9]}>
              <SubscriberPaymentplan />
            </RoleBasedGuard>
          )
        },
        {
          path: 'paymentplan/:id',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 4, 6, 9]}>
              <PaymentplanbyId />
            </RoleBasedGuard>
          )
        },
        {
          path: 'attendance',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 3, 5]}>
              <CohortAttendanceList />
            </RoleBasedGuard>
          )
        },
        {
          path: 'attendance/:cohortId/:date/:eventId',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 3, 5]}>
              <CohortAttendanceByDate />
            </RoleBasedGuard>
          )
        },
        // duplicated for event id
        {
          path: 'attendance/:cohortId/:date/',
          element: (
            <RoleBasedGuard accessibleRoles={[1, 2, 3, 5]}>
              <CohortAttendanceByDate />
            </RoleBasedGuard>
          )
        },
        {
          path: 'connect',
          children: [
            {
              // element: <Navigate to="connect" replace />,
              index: true,
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2, 3, 5]}>
                  <LiveSessionList />
                </RoleBasedGuard>
              )
            },
            {
              path: 'create',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2, 3, 5]}>
                  <LiveSessionCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'edit/:id',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2, 3, 5]}>
                  <LiveSessionEdit />
                </RoleBasedGuard>
              )
            }
          ]
        }
      ]
    },

    // Main Routes
    { path: 'maintenance', element: <Maintenance /> },
    {
      path: '*',
      element: <NotFound />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        // { path: "pricing", element: <Pricing /> },
        // { path: "payment", element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> }
        // { path: "*", element: <Navigate to="/404" replace /> },
      ]
    },
    { path: '/auth/change-password', element: <ChangePassword /> },
    // {
    //   path: "/",
    //   element: <MainLayout />,
    //   children: [
    //     { element: <HomePage />, index: true },
    //     { path: "about-us", element: <About /> },
    //     { path: "contact-us", element: <Contact /> },
    //     { path: "faqs", element: <Faqs /> },
    //   ],
    // },
    { path: '500', element: <Page500 /> },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const ChangePassword = Loadable(lazy(() => import('../pages/dashboard/ChangePassword')));
// Dashboard
const AddCourse = Loadable(lazy(() => import('../pages/dashboard/AddCourse')));
const EditCourse = Loadable(lazy(() => import('../pages/dashboard/EditCourse')));
const GeneralCourse = Loadable(lazy(() => import('../pages/dashboard/GeneralCourse')));
const ProgramManagement = Loadable(lazy(() => import('../pages/dashboard/ProgramManagement')));
const Cohorts = Loadable(lazy(() => import('../pages/dashboard/CohortPage')));
const CohortManagement = Loadable(lazy(() => import('../pages/dashboard/CohortManagement')));
const StudentMenu = Loadable(lazy(() => import('../pages/dashboard/StudentMenu')));
const StudentDetails = Loadable(lazy(() => import('../pages/dashboard/StudentDetails')));
const StudentCourseView = Loadable(lazy(() => import('../pages/dashboard/StudentCourseView')));
const ForumsMenu = Loadable(lazy(() => import('../pages/dashboard/ForumsMenu')));
// course tracking
const QuizTrack = Loadable(lazy(() => import('../pages/dashboard/QuizTrack')));
const QuizTrackbyId = Loadable(lazy(() => import('../pages/dashboard/QuizTrackwithId')));
const AssignmentTrack = Loadable(lazy(() => import('../pages/dashboard/AssignmentTrack/AssignmentTracking')));
const HomeworkTrack = Loadable(lazy(() => import('../pages/dashboard/AssignmentTrack/HomeworkTracking')));

const Media = Loadable(lazy(() => import('../pages/dashboard/Media')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const SubscriptionsReport = Loadable(lazy(() => import('../pages/dashboard/SubscriptionsReport')));
// const AnalyticsCourseOverview = Loadable(lazy(() => import('../pages/dashboard/analytics/CourseOverview')));
const AnalyticsClassroomOverview = Loadable(lazy(() => import('../pages/dashboard/analytics/classroom-overview/ClassroomOverview')));
const SubscriptionsReportbyID = Loadable(lazy(() => import('../pages/dashboard/SubscriptionsReportbyID')));
const RevenueList = Loadable(lazy(() => import('../pages/dashboard/RevenueList')));
const CourseProgress = Loadable(lazy(() => import('../pages/dashboard/CourseProgress')));
const StudentSupport = Loadable(lazy(() => import('../pages/dashboard/StudentSupport')));
const EduleySupport = Loadable(lazy(() => import('../pages/dashboard/EduleySupport')));

const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const InstructorList = Loadable(lazy(() => import('../pages/dashboard/InstructorList')));

// management
const SubscriptionList = Loadable(lazy(() => import('../pages/dashboard/GeneralSubscription')));

const SubscriberPaymentplan = Loadable(lazy(() => import('../pages/dashboard/management/PaymentPlanSubscription')));
// const PaymentplanbyId = Loadable(
//   lazy(() => import("../pages/dashboard/management/PaymentPlanbyId"))
// );
const PaymentplanbyId = Loadable(lazy(() => import('../pages/dashboard/management/PaymentPlanById/index')));

const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const ProfileDetails = Loadable(lazy(() => import('../pages/dashboard/ProfileDetails')));
// Fund Management
const FundStatement = Loadable(lazy(() => import('../pages/dashboard/FundStatement')));
const FundPayout = Loadable(lazy(() => import('../pages/dashboard/payout')));
const Configuration = Loadable(lazy(() => import('../pages/dashboard/Configuration/Configuration')));
const PaymentMethod = Loadable(lazy(() => import('../pages/dashboard/Configuration/PaymentMethod/PaymentMethod')));
const Gamification = Loadable(lazy(() => import('../pages/dashboard/Configuration/Gamification')));
const SupportEmail = Loadable(lazy(() => import('../pages/dashboard/Configuration/SupportEmail')));
const ScoreWeightage = Loadable(lazy(() => import('../pages/dashboard/Configuration/scorewieghtage/ScoreWeightage')));
const StudentFinance = Loadable(lazy(() => import('../pages/dashboard/Configuration/StudentFinance')));
const GamificationAnalytics = Loadable(lazy(() => import('../pages/dashboard/analytics/GamificationAnalytics')));
// const InstituteConfig = Loadable(
//   lazy(() => import("../pages/dashboard/InstituteConfig"))
// );
// const LandingPageConfig = Loadable(
//   lazy(() => import("../pages/dashboard/LandingPageConfig"))
// );

const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/MaintenanceUnplanned')));
// const Maintenance = Loadable(lazy(() => import("../pages/Maintenance")));

const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

const CohortAttendanceByDate = Loadable(lazy(() => import('../pages/dashboard/management/CohortAttendance/CohortAttendanceByDate')));
const CohortAttendanceList = Loadable(lazy(() => import('../pages/dashboard/management/CohortAttendance/CohortAttendanceList')));
const CohortAttendanceReport = Loadable(lazy(() => import('../pages/dashboard/analytics/cohort/CohortAttendanceReport')));
const CohortAttendanceAnalytics = Loadable(lazy(() => import('../pages/dashboard/analytics/cohort/CohortAttendanceAnalytics')));
const InstructorAttendanceAnalytics = Loadable(lazy(() => import('../pages/dashboard/analytics/instructor/InstructorAttendanceAnalytics')));

const EnrollmentDocuments = Loadable(lazy(() => import('../pages/dashboard/Configuration/enrollment_documents/EnrollmentDocuments')));
//connect
const LiveSessionList = Loadable(lazy(() => import('../pages/dashboard/management/Connect/LiveSessionList')));
const LiveSessionCreate = Loadable(lazy(() => import('../pages/dashboard/management/Connect/LiveSessionCreate')));
const LiveSessionEdit = Loadable(lazy(() => import('../pages/dashboard/management/Connect/LiveSessionEdit')));
