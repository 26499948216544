import { useState } from "react";
// @mui
import { Box, Button, MenuItem, Typography } from "@mui/material";

// components
import Iconify from "src/components/Iconify";
import MenuPopover from "src/components/MenuPopover";

// ----------------------------------------------------------------------

const SORT_BY_OPTIONS = [
  { value: "", label: "All" },
  { value: "url", label: "URL" },
  { value: "uploaded", label: "Uploaded" },
];

function renderLabel(label) {
  if (label === "url") {
    return "URL";
  }
  if (label === "uploaded") {
    return "Uploaded";
  }

  return "";
}

// ----------------------------------------------------------------------

export default function MediatypeSort({ sortMedia }) {
  //   const { sortBy } = useSelector((state) => state.c);

  const [open, setOpen] = useState(null);
  const [sortBy, setSortBy] = useState("");

  const handleOpen = (currentTarget) => {
    setOpen(currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleSortBy = (value) => {
    handleClose();
    console.log("value", value);
    sortMedia(value);
    setSortBy(value);
    // dispatch(sortByProducts(value));
  };

  return (
    <Box>
      <Button
        color="inherit"
        disableRipple
        onClick={(event) => handleOpen(event.currentTarget)}
        endIcon={
          <Iconify
            icon={open ? "eva:chevron-up-fill" : "eva:chevron-down-fill"}
          />
        }
      >
        media type:&nbsp;
        <Typography
          component="span"
          variant="subtitle2"
          sx={{ color: "text.secondary" }}
        >
          {renderLabel(sortBy)}
        </Typography>
      </Button>

      <MenuPopover
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleClose}
        sx={{
          width: "auto",
          "& .MuiMenuItem-root": { typography: "body2", borderRadius: 0.75 },
        }}
      >
        {SORT_BY_OPTIONS.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === sortBy}
            onClick={() => handleSortBy(option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
      </MenuPopover>
    </Box>
  );
}
