// ----------------------------------------------------------------------
import * as Yup from "yup";
import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
// form
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
// @mui
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
import {
  Container,
  Grid,
  Card,
  Chip,
  Stack,
  Button,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
// routes
import useSettings from "../../../../hooks/useSettings";

// import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Page from "../../../../components/Page";
import HeaderBreadcrumbs from "../../../../components/HeaderBreadcrumbs";
import {
  RHFSwitch,
  RHFEditor,
  FormProvider,
  RHFTextField,
  RHFUploadSingleFile,
} from "../../../../components/hook-form";
import { changesection, getcurrency } from "src/redux/slices/courseupdate";
import { useDispatch, useSelector } from "src/redux/store";
//
// import BlogNewPostPreview from './BlogNewPostPreview';

// ----------------------------------------------------------------------

const TAGS_OPTION = [
  "Toy Story 3",
  "Logan",
  "Full Metal Jacket",
  "Dangal",
  "The Sting",
  "2001: A Space Odyssey",
  "Singin' in the Rain",
  "Toy Story",
  "Bicycle Thieves",
  "The Kid",
  "Inglourious Basterds",
  "Snatch",
  "3 Idiots",
];

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

// ----------------------------------------------------------------------

export default function PriceOfCourse({ accessibleRoles = [] }) {
  const navigate = useNavigate();
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const { board, addcourse, module, currencylist } = useSelector(
    (state) => state.courseupdate
  );
  const { price = "" } = addcourse.firstform;
  const [prices, setPrices] = useState(price);
  const [open, setOpen] = useState(false);
  console.log('currency_list', currencylist)
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    dispatch(getcurrency());
  }, []);

  const handleNext = () => {
    dispatch(changesection(4));
  };
  const handleBack = () => {
    dispatch(changesection(2));
  };
  const handleOpenPreview = () => {
    setOpen(true);
  };

  const handleClosePreview = () => {
    setOpen(false);
  };

  const NewBlogSchema = Yup.object().shape({
    price: Yup.number().required("Price is required"),
  });

  const defaultValues = {
    paid: true,
    price: prices,
  };

  const methods = useForm({
    resolver: yupResolver(NewBlogSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = methods;

  const values = watch();

  const onSubmit = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
      handleClosePreview();
      enqueueSnackbar("Post success!");
      //   navigate(PATH_DASHBOARD.blog.posts);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Page title="Program: Media">
      <Container maxWidth={themeStretch ? false : "lg"} sx={{ minHeight: 500 }}>
        <HeaderBreadcrumbs
          heading="Pricing"
          links={[
            // { name: 'Dashboard', href: PATH_DASHBOARD.root },
            // { name: 'Blog', href: PATH_DASHBOARD.blog.root },
            { name: "Set Price of Your Program" },
          ]}
        />
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={10}>
              <Card sx={{ p: 3, minHeight: 300 }}>
                <Stack spacing={3} sx={{ p: 3 }}>
                  <div>
                    <RHFSwitch
                      name="paid"
                      label="Program type is Paid"
                      labelPlacement="start"
                      sx={{
                        mb: 1,
                        mx: 0,
                        width: 1,
                        justifyContent: "space-between",
                      }}
                    />
                  </div>
                  <Stack sx={{ p: 1 }}>
                    {values.paid && (
                      <RHFTextField
                        name="price"
                        type="number"
                        label="Program Price "
                      />
                    )}
                  </Stack>
                  <Stack
                    direction={"column"}
                    justifyContent={"flex-start"}
                  ></Stack>
                  <Typography variant="h6" sx={{ mt: 3 }} component="h2">
                    Your Program is {values.paid ? "Paid" : "Free"}
                  </Typography>
                  {/* <Controller
                    name="tags"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        multiple
                        freeSolo
                        onChange={(event, newValue) => field.onChange(newValue)}
                        options={TAGS_OPTION.map((option) => option)}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              {...getTagProps({ index })}
                              key={option}
                              size="small"
                              label={option}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField label="Tags" {...params} />
                        )}
                      />
                    )}
                  /> */}

                  {/* <RHFTextField
                    name="metaDescription"
                    label="Meta description"
                    fullWidth
                    multiline
                    rows={3}
                  /> */}
                </Stack>
              </Card>

              <Stack
                direction="row"
                justifyContent={"center"}
                spacing={1.5}
                sx={{ mt: 3 }}
              >
                {/* <LoadingButton
                  //   fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  loading={isSubmitting}
                  sx={{
                    ml: 5,
                    mr: 5,
                  }}
                >
                  Save
                </LoadingButton> */}
              </Stack>
            </Grid>
          </Grid>
        </FormProvider>
        {/* <MediaPostForm /> */}
      </Container>
      <Stack sx={{ mt: 3 }} direction="row" justifyContent="space-between">
        <Stack item xs={12} md={3}>
          <Button variant="contained" onClick={() => handleBack()} size="large">
            Back
          </Button>
        </Stack>
        <Stack item xs={12} md={3}>
          <Button variant="contained" onClick={() => handleNext()} size="large">
            Next
          </Button>
        </Stack>
      </Stack>
    </Page>
  );
}
