import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React from "react";
import Iconify from "src/components/Iconify";

const ConfirmationPopup = ({ handleCancel, handleOk, type, info }) => {
  const title = type == "course" ? "Course" : "Cohort";
  return (
    <>
      <Dialog open={true}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Changing the following settings will disable the payment plan:
          </DialogContentText>
          <List>
            {info.map((a) => (
              <ListItem>
                <ListItemIcon>
                  <Iconify
                    icon="mdi:currency-usd"
                    width="1.5rem"
                    height="1.5rem"
                  />
                </ListItemIcon>
                <ListItemText>{a.label}</ListItemText>
              </ListItem>
            ))}
            {/* <ListItem>
              <ListItemIcon>
                <Iconify
                  icon="mdi:calendar-month"
                  width="1.5rem"
                  height="1.5rem"
                />
              </ListItemIcon>
              {type == "course" && <ListItemText primary="Course duration" />}
              {type == "cohort" && (
                <>
                
                  <ListItemText primary="Start date or End date" />
                 
                </>
              )}
            </ListItem> */}
          </List>
          <DialogContentText>
            Are you sure you want to update these values?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={handleCancel}>
            no
          </Button>
          <Button variant="contained" color="primary" onClick={handleOk}>
            yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationPopup;
