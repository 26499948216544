// @mui
import PropTypes from "prop-types";

import React, { useState, useEffect } from "react";
// import { useTheme } from "@mui/material/styles";
import { useParams } from "react-router-dom";

import { styled } from "@mui/material/styles";

// import { Container,Grid } from "@mui/material";
import {
    Box,
    Grid,
    Step,
    Stepper,
    Container,
    StepLabel,
    StepConnector,
    Skeleton,
    Card,
    CardHeader,
    IconButton,
} from "@mui/material";

// hooks
import { useDispatch, useSelector } from "../../redux/store";

import useSettings from "../../hooks/useSettings";
// components
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";

import Page from "../../components/Page";
// sections
import Iconify from "../../components/Iconify";

import { CheckoutOrderComplete } from "../../sections/@dashboard/e-commerce/checkout";
import {
    BasicForm,
    Curriculum,
    Media,
    PriceOfCourse,
    Publish,
} from "../../sections/@dashboard/course/addcourse";
import {
    changesection,
    getCourse,
    setEmptyState,
} from "src/redux/slices/courseupdate";
import ConfigurationPage from "src/sections/@dashboard/course/addcourse/ConfigurationPage";
// import  from "src/sections/@dashboard/course/addcourse/PriceOfCourse";

// ----------------------------------------------------------------------
const STEPS = ["Basic", "Curriculum", "Configuration", "Media", "Publish"];
// new steps with icon
const NEWSTEPS = [
    { name: "Basic", icon: "solar:book-2-broken" },
    { name: "Curriculum", icon: "system-uicons:window-content" },
    { name: "Configuration", icon: "fluent:launcher-settings-24-regular" },
    { name: "Media", icon: "fluent-mdl2:photo-video-media" },
    { name: "Publish", icon: "gg:globe-alt" },
];
const QontoConnector = styled(StepConnector)(({ theme }) => ({
    // top: ,
    left: "calc(-50% + 20px)",
    right: "calc(50% + 20px)",
    position: "absolute",
    [theme.breakpoints.up("sm")]: {
        top: 12,
        left: "calc(-50% + 24px)",
        right: "calc(50% + 24px)",
    },
    "& .MuiStepConnector-line": {
        borderTopWidth: 2,
        borderColor: theme.palette.primary.main,
    },
    "&.Mui-active, &.Mui-completed": {
        "& .MuiStepConnector-line": {
            borderColor: theme.palette.primary.main,
        },
    },
}));

QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
};

function QontoStepIcon({ active, completed, icon }) {
    return (
        <Box
            sx={{
                zIndex: 9,
                width: 44,
                height: 44,
                mt: -1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: active ? "primary.main" : "text.disabled",
            }}
        >
            <IconButton>
                <Iconify
                    icon={icon || "eva:checkmark-fill"}
                    sx={{
                        zIndex: 1,
                        width: 30,
                        height: 30,
                        color: active ? "primary.main" : "text.secondary",
                        // color: "primary.main",
                    }}
                />
            </IconButton>
        </Box>
    );
}

export default function ViewCourse() {
    const { themeStretch } = useSettings();
    const dispatch = useDispatch();

    const { section } = useSelector((state) => state.courseupdate);
    const [activeStep, setActiviteStep] = useState(section);
    const { id = "" } = useParams();
    const [fetchdata, setfetchData] = useState(false);
    useEffect(() => {
        setActiviteStep(section);
    }, [section]);

    useEffect(() => {
        dispatch(setEmptyState());
        fetchingcourse();
    }, [dispatch, id]);
    const fetchingcourse = async () => {
        setfetchData(true);
        let res = dispatch(getCourse(id));
        setfetchData(false);
    };
    const handleStep = (index) => {
        setActiviteStep(index);
        dispatch(changesection(index));
    };

    console.log("first", activeStep, section);
    const isComplete = activeStep === STEPS.length;

    return (
        <Page title="Program Information">
            <Container maxWidth={themeStretch ? false : "lg"}>
                <HeaderBreadcrumbs heading="Program Information" links={[{ name: "" }]} />
                <CheckoutOrderComplete open={isComplete} />

                <Grid container justifyContent={isComplete ? "center" : "flex-start"}>
                    <Grid item xs={12} md={12} sx={{ mb: 5 }}>
                        <Stepper
                            alternativeLabel
                            activeStep={activeStep}
                            connector={<QontoConnector />}
                        >
                            {NEWSTEPS.map((label, index) => (
                                <Step
                                    key={label.name}
                                    onClick={() => handleStep(index)}
                                    sx={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <StepLabel
                                        icon={label.icon}
                                        StepIconComponent={QontoStepIcon}
                                        sx={{
                                            "& .MuiStepLabel-label": {
                                                typography: "subtitle2",
                                                color: "text.disabled",
                                                cursor: "pointer",
                                            },
                                        }}
                                    >
                                        {label.name}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Grid>
                </Grid>
                {fetchdata ? (
                    <Lazyloading />
                ) : (<>
                    {activeStep === 0 && <BasicForm />}
                    {activeStep === 1 && <Curriculum />}
                    {activeStep === 2 && <ConfigurationPage />}
                    {activeStep === 3 && <Media />}
                    {activeStep === 4 && <Publish />}

                    {/* <CheckoutOrderComplete open={isComplete} /> */}
                </>
                )}

            </Container>
        </Page>
    );
}
const Lazyloading = () => {
    return (
        <Card sx={{ pt: 5, px: 5, pb: 5 }}>
            <CardHeader
                title="Landing Page Configuration"
                sx={{ p: 2, textAlign: "center" }}
                titleTypographyProps={{ variant: "h4" }}
            ></CardHeader>
            {[1, 2, 3, 45, 6, 789, 78].map((a) => (
                <Skeleton
                    key={a + "sec"}
                    variant="rectangular"
                    sx={{ mt: 2 }}
                    fullWidth
                    height={60}
                />
            ))}
        </Card>
    );
};
