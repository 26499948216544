import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { Droppable, Draggable } from 'react-beautiful-dnd';
// @mui
import {
  Paper,
  Stack,
  Button,
  Typography,
  DialogTitle,
  TextField,
  Dialog,
  DialogContentText,
  DialogContent,
  DialogActions,
  OutlinedInput,
  ClickAwayListener
} from '@mui/material';
// redux
import { useDispatch } from '../../../redux/store';
import { deleteColumn, updateColumn, addTask, deleteTask, getCourse } from '../../../redux/slices/courseupdate';
// components
import Iconify from '../../../components/Iconify';
//
import KanbanAddTask from './KanbanTaskAdd';
import ModuleLessonCard from './ModuleLessonCard';
import KanbanColumnToolBar from './KanbanColumnToolBar';
import ModuleLessonDetails from './ModuleLessonDetails';
import { LoadingButton } from '@mui/lab';
import { sentenceCaseTransform } from 'change-case';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';

// ----------------------------------------------------------------------

ModuleColumn.propTypes = {
  column: PropTypes.object,
  index: PropTypes.number
};

export default function ModuleColumn({ column, index }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { board, addcourse } = useSelector((state) => state.courseupdate);
  const [open, setOpen] = useState(false);
  const [openadddetail, setOpenadddetail] = useState(false);

  const { name, cardIds, id } = column;
  // console.log(column, "column");

  const handleOpenAddTask = () => {
    setOpen((prev) => !prev);
  };

  const handleCloseAddTask = () => {
    setOpen(false);
  };

  const handleDeleteTask = async (cardId) => {
    console.log('lesson delete--', cardId);
    await dispatch(deleteTask({ cardId, columnId: id }, cardId));
    dispatch(getCourse(addcourse.firstform.id));
  };

  const handleUpdateColumn = async (newName) => {
    try {
      if (newName !== name) {
        dispatch(updateColumn(id, { ...column, name: newName }));
        enqueueSnackbar('Update success!');
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleupdatesection = async (newName) => {
    try {
      let res = await dispatch(updateColumn(id, { ...column, ...newName }));
      enqueueSnackbar('Update success!');
      setOpenadddetail(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteColumn = async () => {
    try {
      // wait for the column to be deleted before fetching the course
      await dispatch(deleteColumn(id));
      dispatch(getCourse(addcourse.firstform.id));
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddTask = (task) => {
    dispatch(getCourse(addcourse.firstform.id));
    dispatch(addTask({ card: task, columnId: id, module: column.id }));
    handleCloseAddTask();
  };

  return (
    <>
      <Draggable draggableId={id} index={index}>
        {(provided) => (
          <Paper {...provided.draggableProps} ref={provided.innerRef} variant="outlined" sx={{ px: 1, bgcolor: 'grey.5008' }}>
            <Stack spacing={1} {...provided.dragHandleProps}>
              <Stack sx={{ mt: 1, ml: 1 }} direction="row">
                <Typography
                  variant="div"
                  sx={{
                    fontWeight: '200',
                    fontSize: '18px',
                    color: 'primary.main',
                    whiteSpace: 'nowrap'
                  }}
                >
                  Section - {index + 1}
                </Typography>
                {index == 0 && !addcourse.firstform.is_course_free && addcourse.firstform.offer_first_section_free && (
                  <Typography
                    variant="div"
                    sx={{
                      ml: 4,
                      color: 'text.secondary'
                    }}
                  >
                    The first section of this program will be offered as a free trial to the student. Add more than 1 section to this program.
                  </Typography>
                )}
              </Stack>
              <KanbanColumnToolBar
                columnName={name}
                onDelete={handleDeleteColumn}
                onUpdate={handleUpdateColumn}
                adddetail={() => setOpenadddetail(true)}
              />

              <Droppable droppableId={id} type="task">
                {(provided) => (
                  <Stack ref={provided.innerRef} {...provided.droppableProps} spacing={2} width={'calc(100% - 32px)'}>
                    {cardIds.map((cardId, index) => (
                      <ClickAwayListener
                        onClickAway={() => {
                          console.log('any where');
                        }}
                      >
                        <ModuleLessonCard
                          key={cardId}
                          onDeleteTask={handleDeleteTask}
                          card={board?.cards[cardId]}
                          index={index}
                          data={column}
                          section={id}
                        />
                      </ClickAwayListener>
                    ))}
                    {provided.placeholder}
                  </Stack>
                )}
              </Droppable>

              <Stack spacing={2} sx={{ pb: 3 }}>
                {open && (
                  <ModuleLessonDetails
                    card={{}}
                    courseid={addcourse.firstform.id}
                    isOpen={open}
                    onClose={handleCloseAddTask}
                    onDeleteTask={() => handleCloseAddTask()}
                    saveLesson={(p) => handleAddTask(p)}
                    section={column}
                    index={cardIds?.length + 1}
                  />
                )}
                <RoleBasedGuard accessibleRoles={[1, 2, 3]} noPermissionChildren={<></>}>
                  <Button
                    fullWidth
                    size="large"
                    color="inherit"
                    startIcon={<Iconify icon={'eva:plus-fill'} width={20} height={20} />}
                    onClick={handleOpenAddTask}
                    sx={{ fontSize: 14 }}
                  >
                    Add Content
                  </Button>
                </RoleBasedGuard>
              </Stack>
            </Stack>
          </Paper>
        )}
      </Draggable>
      {openadddetail && <AddDetailsView data={column} handleupdate={handleupdatesection} handleclose={() => setOpenadddetail(false)} />}
    </>
  );
}
const AddDetailsView = ({ data, handleclose, handleupdate }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(data);
  const onsubmit = async () => {
    setLoading(true);
    let res = await handleupdate(value);
    setLoading(false);
  };
  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={true} onClose={handleclose}>
        <DialogTitle>
          <DialogContentText>Update Section Details</DialogContentText>
        </DialogTitle>
        <DialogContent>
          <Stack sx={{ mt: 2 }} direction={'column'}>
            <Typography>Expected time to complete (in days)</Typography>
            <OutlinedInput
              fullWidth
              label={'Expection day'}
              sx={{ mb: 2 }}
              value={value.days_to_complete}
              type="number"
              onChange={(e) =>
                setValue({
                  ...value,
                  days_to_complete: parseInt(e.target.value)
                })
              }
            />
            <Typography>Description</Typography>
            <OutlinedInput
              multiline
              rows={2}
              fullWidth
              value={value.description}
              row={2}
              type="text"
              onChange={(e) => setValue({ ...value, description: e.target.value })}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => handleclose()}>
            Cancel
          </Button>
          <LoadingButton loading={loading} variant="contained" onClick={() => onsubmit()}>
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
