// @mui
import {
  Container,
  Stack,
  Button,
  MenuItem,
  Grid,
  Card,
  Typography,
  TextField,
  Select,
  Checkbox,
  CardHeader,
  CardContent,
  CardActions,
} from "@mui/material";
// routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from "../../../../hooks/useSettings";
// components
import Page from "../../../../components/Page";
import HeaderBreadcrumbs from "../../../../components/HeaderBreadcrumbs";
// sections
// import { BlogNewPostForm } from '../../blog';
import { styled } from "@mui/material/styles";

import {
  changesection,
  getCourse,
  getcurrency,
  updatecoverImage,
} from "src/redux/slices/courseupdate";
import { useDispatch, useSelector } from "src/redux/store";
import MediaModal from "src/sections/media/MediaModal";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import useAuth from "src/hooks/useAuth";
import MultipleErrorModal from "src/components/MulipleErrorModal";
import MediaModal2 from "src/sections/media/MediaModal2";
import CustomScore from "./component/CustomScore";
import RoleBasedGuard from "src/guards/RoleBasedGuard";
import MediaView from '../courseView/MediaView';

// ----------------------------------------------------------------------
const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1),
}));

export default function Media({ accessibleRoles = [] }) {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const { instituteinfo } = useAuth();
  const { board, addcourse, module } = useSelector(
    (state) => state.courseupdate
  );
  const {
    poster_image = "",
    intro_video_media = "",
    price = "",
    price_currency = null,
    // is_course_free = false,
    // offer_first_section_free = false,
  } = addcourse.firstform;
  const [media, setMedia] = useState(poster_image);
  const [amount, setAmount] = useState(price);
  const [coverVideo, setCoverVideo] = useState(intro_video_media);
  const [currency, setCurrency] = useState(price_currency);
  // const [free, setFree] = useState(is_course_free);
  // const [trailsection, setTrailSection] = useState(offer_first_section_free);
  const [currencylists, setcurrencyLists] = useState([]);
  const [institutedata, setInstituteData] = useState({});
  const [fetchui, setFetchui] = useState(false);
  const [error, setError] = useState(null);
  const [openError, setOpenError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const setCoverImage = (media) => {
    setMedia(media.id);
  };

  useEffect(() => {
    if (instituteinfo) {
      setcurrencyLists([instituteinfo.default_currency]);
    }
  }, [instituteinfo]);

  useEffect(() => {
    setMedia(poster_image);
    setCoverVideo(intro_video_media);
    setCurrency(price_currency);
    setAmount(price);
  }, [
    poster_image,
    intro_video_media,
    price,
    price_currency,
  ]);

  useEffect(() => {
    dispatch(getCourse(addcourse.firstform.id));
  }, []);

  const setCoverVideos = (media) => {
    setCoverVideo(media.id);
  };
  const handleSubmit = async () => {
    console.log("publish--", media);
    let res = await dispatch(
      updatecoverImage({
        ...addcourse.firstform,
        poster_image: media || null,
        intro_video_media: coverVideo || null,
      })
    );
    console.log("res", res);
    if (res == "success") {
      enqueueSnackbar("Updated successfully", {
        variant: "success",
      });
    } else {
      setError(res.data.data.non_field_errors);
      setOpenError(true);
      console.log(res);

    }
  };
  const handleNext = () => {
    handleSubmit();
    // dispatch(changesection(3));
  };
  const handleBack = () => {
    dispatch(changesection(2));
  };
  return (
    <Page title="Program: Media & Price">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs heading="Media  " links={[{ name: "" }]} />
        <RoleBasedGuard accessibleRoles={accessibleRoles} noPermissionChildren={<>
          <MediaView /></>}>
          <Card sx={{ p: 3 }}>
            <CardHeader
              title="Add Introduction Media"
            // sx={{ textAlign: "center" }}
            />
            <CardContent>
              <Stack spacing={3} sx={{ mr: 5 }}>
                <div>
                  <Typography variant="body2">
                    1. Cover Picture (only public image)
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: 12, color: "text.secondary", mb: 1 }}
                  >
                    Recommended size of the banner to upload [600 x 600 px]
                  </Typography>

                  <MediaModal2
                    media={media || null}
                    addingMedia={(rajeev) => setCoverImage(rajeev)}
                    removingMedia={(data) => setMedia(null)}
                    file_type={"image"}
                    public_private="public"
                  // viewOnly={true}
                  />
                  <Typography
                    variant="body2"
                    sx={{ mt: 1, fontSize: 12, ml: 1 }}
                    color="text.secondary"
                  >
                    Add a cover image for your program.
                  </Typography>
                  {/* </Stack> */}
                </div>
              </Stack>
              <Stack spacing={3} sx={{ mt: 3 }}>
                <div>
                  <Typography variant="body2">
                    2. Introduction Video (only public video)
                  </Typography>

                  <MediaModal2
                    media={coverVideo || null}
                    addingMedia={(rajeev) => setCoverVideos(rajeev)}
                    removingMedia={(data) => setCoverVideo(null)}
                    file_type={"video"}
                    public_private="public"
                  // viewOnly={true}
                  />
                  <Typography
                    variant="body2"
                    sx={{ mt: 1, fontSize: 12, ml: 1 }}
                    color="text.secondary"
                  >
                    Add a video to introduce your program.
                  </Typography>
                  {/* </Stack> */}
                </div>
              </Stack>
            </CardContent>

            <Stack sx={{ mt: 3 }} direction="row" justifyContent="space-between">
              <Stack item xs={12} md={3}>
                <Button
                  variant="contained"
                  onClick={() => handleBack()}
                  size="large"
                >
                  Back
                </Button>
              </Stack>
              <Stack item xs={12} md={3}>
                <Button
                  variant="contained"
                  onClick={() => handleNext()}
                  size="large"
                >
                  Next
                </Button>
              </Stack>
            </Stack>
          </Card>
        </RoleBasedGuard>
      </Container>
      <MultipleErrorModal
        title="Please fix the following to proceed!"
        open={openError}
        error={error}
        onclose={() => {
          setOpenError(false);
          setError(null);
        }}
      />
    </Page>
  );
}
