import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { IconButtonAnimate } from "./animate";
import Iconify from "./Iconify";

const DeleteModal = ({
  action,
  title,
  details = "",
  handledelete,
  btnText,
}) => {
  const [modal, setModal] = useState(false);
  return (
    <>
      {<Box onClick={() => setModal(true)}>{action}</Box> || (
        <Typography variant="body2">Delete</Typography>
      )}
      {modal && (
        <ModalBox
          title={title}
          details={details}
          handleClose={() => setModal(false)}
          handledelete={() => {
            setModal(false);
            handledelete();
          }}
          btnText={btnText}
        />
      )}
    </>
  );
};

export default DeleteModal;

const ModalBox = ({
  title = "Delete",
  details = "",
  handleClose,
  handledelete,
  btnText = "Delete",
}) => {
  return (
    <Dialog fullWidth maxWidth="sm" open={true} onClose={handleClose}>
      <DialogTitle sx={{ textAlign: "center", color: "error.main" }}>
        {title}
      </DialogTitle>
      <DialogContent
        sx={{
          textAlign: "center",
          mt: 2,
          // alignContent: "center",
          // alignItems: "center",
          // justifyContent: "center",
        }}
      >
        <Iconify
          width={140}
          height={140}
          color="error.main"
          icon="material-symbols:delete-outline"
        />
        <Typography sx={{ textAlign: "center", mt: 1 }} variant="h4">
          {details || "Are you sure? you want to delete."}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button size="medium" onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Button
          size="small"
          endIcon={
            <IconButton>
              <Iconify color="#fff" icon="material-symbols:delete-outline" />
            </IconButton>
          }
          color="error"
          onClick={handledelete}
          variant="contained"
        >
          {btnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
