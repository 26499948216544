import React, { useRef } from 'react'
import Editor from 'src/components/editor'
import FormControl from '@mui/material/FormControl';
const TextEditorForDescription = ({ value, setValue, errors }) => {
    const inputRef = useRef(null);
    return (
        <FormControl fullWidth>
            <Editor
                fullWidth
                multiline
                id="compose-mail"
                name="description"
                rows={3}
                error={errors}
                helperText={errors}
                placeholder="Description"
                value={value}
                onChange={(e) => setValue(e)}
                ref={inputRef}
            />

        </FormControl>
    )
}

export default TextEditorForDescription