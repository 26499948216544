import { LocalizationProvider, TimeField } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

function TimeFieldHHMMSS({ helperText = '', handleValue, value = undefined, ...other }) {
    function onChangeTime(newValue) {
        if (newValue && dayjs(newValue).isValid()) {
            // Format the time as HH:mm:ss
            const formattedValue = dayjs(newValue).format("HH:mm:ss");
            handleValue(formattedValue);
        } else {
            // If invalid, you may want to handle it differently or set to a default value
            handleValue('');
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimeField
                ampm={false}
                size="small"
                formatDensity="spacious"
                value={value ? dayjs(value, "HH:mm:ss") : null}
                onChange={onChangeTime}
                format="HH:mm:ss"
                sx={{
                    border: (theme) => `2px solid ${theme.palette.grey.main}`,
                }}
                helperText={helperText}
                {...other}
            />
        </LocalizationProvider>
    );
}

export { TimeFieldHHMMSS };

