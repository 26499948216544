import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import useFeeStructure from './useFeeStructure';

const DiscountForm = () => {
  const { instituteinfo } = useAuth();
  const { discountValue, discountUnit, onChangeDiscountValue, onChangeDiscountUnit, discountData, priceBreakup } = useFeeStructure();
  const [discount, setDiscount] = useState(discountValue || '');
  const [unit, setUnit] = useState(discountUnit || '');
  const [error, setError] = useState('');

  const isDisabled = Number(discountData?.value) !== 0 && !isEmpty(discountData);

  useEffect(() => {
    setDiscount(discountValue);
    return () => {
      setDiscount(null);
    };
  }, [discountValue]);
  useEffect(() => {
    setUnit(discountUnit);
    return () => {
      setUnit(null);
    };
  }, [discountUnit]);

  const handleValueChange = (event) => {
    let value = event.target.value;

    if (value === '') {
      onChangeDiscountValue('');
      setError('');
      return;
    }

    value = parseFloat(value);

    if (isNaN(value)) {
      return;
    }

    onChangeDiscountValue(value);

    if (discountUnit === 'percentage') {
      if (value > 100) {
        setError('Percentage discount cannot exceed 100%.');
      } else if (value <= 0) {
        setError('Percentage discount must be greater than 0%.');
      } else {
        setError('');
      }
    }

    if (discountUnit === 'amount') {
      if (value <= 0) {
        setError('Discount amount must be greater than 0.');
      } else if (value >= totalTuitionFee) {
        setError(
          `Discount amount must be less than the total tuition fee: ${instituteinfo?.default_currency?.prefix || ''} ${totalTuitionFee}${
            instituteinfo?.default_currency?.suffix || ''
          }.`
        );
      } else {
        setError('');
      }
    }
  };

  const totalTuitionFee = priceBreakup.filter((fee) => fee.description === 'Tuition Fee').reduce((total, fee) => total + fee.calculated_amount, 0);

  const handleUnitChange = (event) => {
    setError('');
    onChangeDiscountValue('');
    onChangeDiscountUnit(event.target.value);
  };

  return (
    <Grid container spacing={2} alignItems="start">
      <Grid item xs={8}>
        <TextField
          disabled={isDisabled}
          label="Discount"
          variant="outlined"
          fullWidth
          size="small"
          value={discount}
          onChange={handleValueChange}
          error={error ? true : false}
        />
        {error && <p style={{ color: 'red', fontSize: '11px', margin: '2px 0' }}>{error}</p>}
      </Grid>
      <Grid item xs={4}>
        <FormControl disabled={isDisabled} size="small" variant="outlined" fullWidth>
          <InputLabel id="discount-unit-label">Unit</InputLabel>
          <Select labelId="discount-unit-label" value={unit} onChange={handleUnitChange} defaultValue="percentage" label="Unit">
            <MenuItem value="percentage">%</MenuItem>
            <MenuItem value="amount">{instituteinfo?.default_currency?.prefix || instituteinfo?.default_currency?.suffix}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default DiscountForm;
