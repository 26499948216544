import React from "react";
import {
    Box,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
    TextField,
    Select,
    MenuItem,
    Button,
    DialogActions,
    alpha,
    Dialog,
    Stack,
    Checkbox,
} from "@mui/material";
import Iconify from "./Iconify";

const MultipleErrorModal = ({ open, onclose, error, title = 'Error' }) => {
    // console.log(JSON.parse(error));
    return (
        <Dialog open={open} fullWidth maxWidth='sm' onClose={onclose}>
            <DialogTitle color="error.main" sx={{ textAlign: 'center' }} >{title}</DialogTitle>
            <DialogContent sx={{mt:2}}>
                {
                    error?.map((c, i) => {
                        return <>
                            <Stack direction={'row'} sx={{ mt: 1 }} spacing={2}>
                                <Iconify color='error.main' icon={'material-symbols:arrow-right-alt-rounded'} />
                                <Typography key={i} variant="body2">{c}</Typography>
                            </Stack>
                        </>
                    })

                }
                {/* <DialogContentText>{error}</DialogContentText> */}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onclose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}
export default MultipleErrorModal