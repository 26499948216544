import { useState, useEffect } from 'react';
// @mui
import { Divider } from '@mui/material';
// components
import DocHomework from './assignment/DocHomework';

export default function Homework({ data = {}, handleUpdate, section, index }) {
  const [assignmentType, setassignmentType] = useState({});
  const [da, setda] = useState(data);

  useEffect(() => {
    setda(data);
    // setassignmentType(ast);
  }, [data]);

  console.log('HomeworkType--', assignmentType);
  const handleSubmit = (value) => {
    handleUpdate({ ...data, ...value });
  };

  return (
    <>
      <Divider />
      <DocHomework handleUpdate={handleSubmit} data={da} section={section} index={index} />
      <Divider />
    </>
  );
}
