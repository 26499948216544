import PropTypes from "prop-types";
import isString from "lodash/isString";
import { useDropzone } from "react-dropzone";
import { useState, useCallback, useEffect } from "react";
// @mui
import { alpha, styled } from "@mui/material/styles";
import { Box, IconButton } from "@mui/material";
// components
import Image from "src/components/Image";
import Iconify from "src/components/Iconify";
import LightboxModal from "src/components/LightboxModal";
import { varFade } from "src/components/animate";

// ----------------------------------------------------------------------

const DropZoneStyle = styled("div")(({ theme }) => ({
  width: 64,
  height: 64,
  fontSize: 24,
  display: "flex",
  cursor: "pointer",
  alignItems: "center",
  justifyContent: "center",
  margin: theme.spacing(0.5),
  borderRadius: "50%",
  border: `dashed 3px ${theme.palette.divider}`,
  "&:hover": { opacity: 0.72 },
}));

// ----------------------------------------------------------------------

// MediaAttachment.propTypes = {
//   attachments: PropTypes.array.isRequired,
// };

export default function MediaAttachment({ attachments, setfiles, filetype }) {
  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [newfiles, setNewfiles] = useState("");
  const imagesLightbox = [attachments];
  console.log(attachments);
  const handlefile = (f) => {

    setNewfiles(f);
    setfiles(f);
  };
  const handleOpenLightbox = (url) => {
    // const selectedImage = imagesLightbox.findIndex((index) => index === url);
    setOpenLightbox(true);
    setSelectedImage(0);
  };

  return (
    <>
      {attachments && !newfiles && (
        <Image
          key={attachments}
          src={attachments}
          onClick={() => handleOpenLightbox(attachments)}
          sx={{
            m: 0.5,
            width: 64,
            height: 64,
            borderRadius: 1,
            cursor: "pointer",
          }}
        />
      )}

      <UploadFile
        setfile={(f) => handlefile(f)}
        filetype={filetype}
        file55={attachments === {}}
      />

      <LightboxModal
        images={imagesLightbox}
        mainSrc={imagesLightbox[selectedImage]}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox}
        onCloseRequest={() => setOpenLightbox(false)}
      />
    </>
  );
}

// ----------------------------------------------------------------------

function UploadFile({ setfile, filetype, file55 }) {
  const [files, setFiles] = useState([]);
  console.log(file55);
  const handleRemove = (file) => {
    const filteredItems = files.filter((_file) => _file !== file);
    setFiles(filteredItems);
  };
  useEffect(() => {
    setfile(files[0]);
  }, [files]);

  console.log(files);
  const handleDrop = useCallback(
    (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    [setFiles]
  );
  const acceptfile =
    filetype == "image"
      ? "image/*"
      : filetype == "video"
        ? "video/*"
        : ".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf";
  console.log("filetype", filetype, acceptfile);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
  });

  return (
    <>
      {files.map((file) => {
        const { name, preview } = file;
        const key = isString(file) ? file : name;

        return (
          <Box
            key={key}
            {...varFade().inRight}
            sx={{
              p: 0,
              m: 0.5,
              width: 64,
              height: 64,
              borderRadius: 1,
              overflow: "hidden",
              position: "relative",
            }}
          >
            <Image
              src={isString(file) ? file : preview}
              sx={{
                height: 1,
                position: "absolute",
                border: (theme) => `solid 1px ${theme.palette.divider}`,
              }}
            />
            <Box sx={{ top: 6, right: 6, position: "absolute" }}>
              <IconButton
                size="small"
                onClick={() => handleRemove(file)}
                sx={{
                  p: "2px",
                  color: "common.white",
                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                  "&:hover": {
                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                  },
                }}
              >
                <Iconify icon={"eva:close-fill"} />
              </IconButton>
            </Box>
          </Box>
        );
      })}

      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
        }}
      >
        <input {...getInputProps()} accept={acceptfile} />

        <Iconify
          icon={file55 ? "ic:outline-change-circle" : "eva:plus-fill"}
          sx={{ color: "text.secondary" }}
          width={32}
          height={32}
        />
      </DropZoneStyle>
    </>
  );
}
