import PropTypes from "prop-types";
// @mui
import { useTheme, styled } from "@mui/material/styles";
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  InputAdornment,
  TextField,
  Button,
  Stack,
} from "@mui/material";
// components
import Iconify from "src/components/Iconify";
import MediaListSort from "./MediaListSort";
import MediatypeSort from "./MediatypeSort";
import MediaSortbyPrivate from "./MediaSortbyPrivate";
import RoleBasedGuard from "src/guards/RoleBasedGuard";

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}));

// ----------------------------------------------------------------------

ParticularMediaToolBar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  onDeleteProducts: PropTypes.func,
};

export default function ParticularMediaToolBar({
  numSelected,
  filterName,
  onFilterName,
  // onDeleteProducts,
  addNewMedia,
  sortMedia,
  sortMediatype,
  sortMediabyPrivate,
  public_private = "",
}) {
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: isLight ? "primary.main" : "text.primary",
          bgcolor: isLight ? "primary.lighter" : "primary.dark",
        }),
      }}
    >
      <TextField
        // stretchStart={240}
        sx={{
          width: "40%",
        }}
        fullWidth
        variant="outlined"
        size="small"
        value={filterName}
        onChange={(event) => onFilterName(event.target.value)}
        placeholder="Search Media..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon={"eva:search-fill"}
                sx={{ color: "text.disabled", width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
        }}
      />

      <Stack direction="row">
        {/* <MediaListSort sortMedia={(p) => sortMedia(p)} /> */}
        {/* <MediaSortbyPrivate sortMedia={(p) => sortMediabyPrivate(p)} /> */}
        {!public_private && (
          <MediaSortbyPrivate sortMedia={(p) => sortMediabyPrivate(p)} />
        )}
        <MediatypeSort sortMedia={(p) => sortMediatype(p)} />

        {/* <Tooltip title="Filter list">
          <IconButton>
            <Iconify icon={"ic:round-filter-list"} />
          </IconButton>
        </Tooltip> */}
        <RoleBasedGuard accessibleRoles={[1, 2,]} noPermissionChildren={<></>}>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => addNewMedia()}
          >
            Add Media
          </Button>
        </RoleBasedGuard>
      </Stack>
    </RootStyle>
  );
}
