import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
// @mui
import { alpha, styled } from "@mui/material/styles";
import { Box, IconButton, Typography } from "@mui/material";
//
// import Image from '../Image';
import Video from "../Video";
import Iconify from "../Iconify";
import RejectionFiles from "./RejectionFiles";
import { isString } from "lodash";
import { varFade } from "../animate";
// import BlockContent from './BlockContent';
// import AcceptedFile from './AcceptedFile';

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  width: 144,
  height: 144,
  margin: "auto",
  borderRadius: "50%",
  padding: theme.spacing(1),
  border: `1px dashed ${theme.palette.grey[500_32]}`,
}));

const DropZoneStyle = styled("div")({
  zIndex: 0,
  width: "100%",
  height: "100%",
  outline: "none",
  display: "flex",
  overflow: "hidden",
  borderRadius: "50%",
  position: "relative",
  alignItems: "center",
  justifyContent: "center",
  "& > *": { width: "100%", height: "100%" },
  "&:hover": {
    cursor: "pointer",
    "& .placeholder": {
      zIndex: 9,
    },
  },
});

const PlaceholderStyle = styled("div")(({ theme }) => ({
  display: "flex",
  position: "absolute",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.neutral,
  transition: theme.transitions.create("opacity", {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&:hover": { opacity: 0.72 },
}));

export default function UploadVideo({
  error = false,
  setfile,
  file,
  helperText,
  sx,
  ...other
}) {
  const [files, setFiles] = useState([]);
  // console.log(file55);
  const handleRemove = (file) => {
    const filteredItems = files.filter((_file) => _file !== file);
    setFiles(filteredItems);
  };
  useEffect(() => {
    setfile(files[0]);
  }, [files]);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    [setFiles]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    multiple: false,
    onDrop: handleDrop,
    ...other,
  });

  return (
    <>
      {!file && (
        <RootStyle
          sx={{
            ...((isDragReject || error) && {
              borderColor: "error.light",
            }),
            ...sx,
          }}
        >
          <DropZoneStyle
            {...getRootProps()}
            sx={{
              ...(isDragActive && { opacity: 0.72 }),
            }}
          >
            <input {...getInputProps()} />

            <PlaceholderStyle
              className="placeholder"
              sx={{
                ...(file && {
                  opacity: 0,
                  color: "common.white",
                  bgcolor: "grey.900",
                  "&:hover": { opacity: 0.72 },
                }),
                ...((isDragReject || error) && {
                  bgcolor: "error.lighter",
                }),
              }}
            >
              <Iconify
                icon={"material-symbols:video-camera-back"}
                sx={{ width: 24, height: 24, mb: 1 }}
              />
              <Typography variant="caption">
                {file ? "Update Video" : "Upload video"}
              </Typography>
            </PlaceholderStyle>
          </DropZoneStyle>
        </RootStyle>
      )}
      {file && (
        <Videoplayer
        handleRemove={()=>handleRemove(file)}
          url={isString(file) ? file : file.preview}
          sx={{ zIndex: 8 }}
        />
      )}
      {helperText && helperText}

      {fileRejections.length > 0 && (
        <RejectionFiles fileRejections={fileRejections} />
      )}
    </>

    //   {files.length > 0 && <AcceptedFile fileRejections={files} />}
    //   {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

    //   {helperText && helperText}
  );
}
const Videoplayer = ({url,handleRemove}) => {
  return (
    <Box
      
      {...varFade().inRight}
      sx={{
        p: 0,
        m: 0.5,
        // width: 64,
        // height: 64,
        borderRadius: 1,
        // overflow: "hidden",
        position: "relative",
      }}
    >
      <Video
        url={url}
        sx={{
          zIndex: 8,
          // height: 1,
          position: "absolute",
          border: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      />

      {/* <Image
        src={isString(file) ? file : preview}
        sx={{
          height: 1,
          position: "absolute",
          border: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      /> */}
      <Box sx={{ top: 6, right: 6, position: "absolute" }}>
        <IconButton
          size="small"
          onClick={() => handleRemove(url)}
          sx={{
            p: "2px",
            color: "common.white",
            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            "&:hover": {
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
            },
          }}
        >
          <Iconify icon={"eva:close-fill"} />
        </IconButton>
      </Box>
    </Box>
  );
};
